import React from 'react'

import Grid from '@mui/material/Unstable_Grid2'; 
import { Typography } from "@mui/material";

export const SheetRowSole = ({label, valueA, valueB, format}) => {

    const appendValue = (format === '%') ? '%' : '';

    const labelA = valueA + appendValue;
    const labelB = valueB + appendValue;
    
    return (
        <>
            <Grid xs={6} smOffset={0}>
                <Typography variant="input">{label}</Typography>
            </Grid>
            <Grid xs={3}>
                {valueA != null  ? <Typography variant="value">{labelA}</Typography> : null}
            </Grid>
            <Grid xs={3}>
                {valueB != null ? <Typography variant="value">{labelB}</Typography> : null}                
            </Grid>            
        </>
    )
}