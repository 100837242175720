import { createContext, useContext, useReducer } from "react";
import { Form } from "../helpers/Form";
import initialFieldList from "../helpers/InitialFormData";


Form.setMetaFieldData(initialFieldList);

const formObj = new Form();

const FormDataContext = createContext(null);
const FormDataDispatchContext = createContext(null);


export const FormDataProvider = ({ children }) => {
    const [formData, dispatch] = useReducer(
        formDataReducer,
        formObj,
      );    
      
      return (
        <FormDataContext.Provider value={formData}>
            <FormDataDispatchContext.Provider value={dispatch}>
                {children}
            </FormDataDispatchContext.Provider>  
        </FormDataContext.Provider>
      );
};

export const useFormData = () => useContext(FormDataContext);

export const useFormDataDispatch = () => useContext(FormDataDispatchContext);

const formDataReducer = (draft, action) => {
    switch (action.type) {
        case 'updated': {
            const newForm = draft.clone();

            for (const prop in action.updatedFields) {
                newForm.setValue(prop, action.updatedFields[prop]);
            }
          
            return newForm;
        }
        case 'updatedMeta': {
            const newForm = draft.clone();
            newForm.formSaved(action.caseUUID, action.savedTime);
            return newForm;
        }

        case 'updatedAdjustment': {
            // TODO: this should probably be moved into the form object
            const indexToUpdate = draft.getValue('adjustments').findIndex((x) => x.id === action.updatedID);
            draft.getValue('adjustments')[indexToUpdate][action.updatedName] = action.updatedValue;
            return draft;    
        }
        case 'reset': {       
            const newForm = draft.clone();
            newForm.clearValues(action.page);

            return newForm;
        }
        case 'loaded': {
            const loadedForm = new Form();
            loadedForm.importValues(action.formData);
            return loadedForm;
        }
        default: 
            throw Error('Unknown action: ' + action.type);

    }
};



