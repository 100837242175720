import Dinero from 'dinero.js';

/**
 * Given an integer, returns a Dinero object representation
 * @param {integer} value 
 * @returns Dinero object representing passed-in value
 */
 export const convertValue = (value) => Dinero({amount:Math.round(value*100)});


/**
 * Turn negative values positive, while handling zero
 * @param {Dinero} value - amount to transformed
 * @returns Dinero object
 */
export const formatCurrency = (value) => {

    if (value.isZero()) {
        return value;
    }
    else {
        return value.multiply(-1);
    }        
};

export const kidCountList = [
    {num: 1, word: "One"},
    {num: 2, word: "Two"},
    {num: 3, word: "Three"},
    {num: 4, word: "Four"},
    {num: 5, word: "Five"},
    {num: 6, word: "Six"},
];

export const yesnoOptions = [
    {value: "no", label: "No"},
    {value: "yes", label: "Yes"},
];

/**
 * 
 * @param {*} value 
 * @returns 
 */
export const isCurrency = (value) => {
    const regex = /^\d+(\.\d{1,2})?$/; // Allows digits, and optionally a dot followed by up to 2 digits
    return regex.test(value);                
};

/**
 * Check if the type is "number" or if it's a string containing only numeric characters
 * @param {*} value 
 * @returns 
 */
export const isNumber = (value) => (typeof value === 'number' || (typeof value === 'string' && /^[0-9]+$/.test(value)));