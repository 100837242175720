import { createContext, useContext, useState } from "react";
import { Snackbar, Alert, CircularProgress } from "@mui/material";
import { blue, green } from "@mui/material/colors";

const SnackbarContext = createContext(null);

/**
 * How to use: const { addNotification } = useNotifications();
 * @param {*} param0 
 * @returns 
 */
export const NotificationProvider = ({ children }) => {
  //const [alerts, setAlerts] = useState([]) {alerts.map((alert) => <Snackbar key={alert}>{alert}</Snackbar>)}

  const [openMessage, setOpenMessage] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [message, setMessage] = useState("Hello World");
  const [messageSeverity, setMessageSeverity] = useState('success');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setOpenMessage(false);
  };
  
  /**
   * Displays a small notification in the bottom left side of the screen
   * @param {String} message the message to be displayed
   * @param {String} type error, warning, info, or success - Default is success 
   */
  const addNotification = (message, type) => {
    setMessage(message);
    // error, warning, info, success
    setMessageSeverity(type ? type : 'success');
    setOpenMessage(true);
  }
  
  const setProgress = (progress) => {
    setOpenProgress(progress);
  };


  return (
    <SnackbarContext.Provider value={{ addNotification, setProgress }}>
      {children}
      {openProgress && <CircularProgress  
            sx={{
              color: blue[800],              
              position: 'absolute',
              bottom: 16,
              left: 60,              
            }} />
      }
      <Snackbar open={openMessage} onClose={handleClose} autoHideDuration={5000}>                              
        <Alert onClose={handleClose} severity={messageSeverity} sx={{ width: '100%' }}> 
          {message}
        </Alert>
      </Snackbar>      
    </SnackbarContext.Provider>
  );
};

export const useNotifications = () => useContext(SnackbarContext);


