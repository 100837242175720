import { SmartTextField } from "../../components/SmartTextField";
import { useFormData } from "../../hooks/FormDataProvider";

export const CourtOrderedAmountSection = ({party}) => {

    const formData = useFormData();

    const name = "orderedSupportAmount" + party;

    return(
      <SmartTextField label="Court Ordered Support Amount" field={formData.getField(name)} />
    );
  };