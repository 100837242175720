
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useFormData, useFormDataDispatch } from '../hooks/FormDataProvider';
import { InfoSelector } from '../components/InfoSelector';
import { CourtOrderedSection } from './pageComponents/CourtOrderedSection';


export const OtherChildrenForm = () => {

  const dispatch = useFormDataDispatch();
  const formData = useFormData();

  const partyAHeader = formData.getValue('partyNameA') + " : Other Children";
  const partyBHeader = formData.getValue('partyNameB') + " : Other Children";

  const numKids = [
    {value: 0, label: "None"},
    {value: 1, label: "One"},
    {value: 2, label: "Two"},
    {value: 3, label: "Three"},
    {value: 4, label: "Four"},
  ];


  const handleChange = (e) => {

    const { name, value } = e.target;
    const updatedFields = { [name]: value };
    const party = name.slice(-1);
    const partyField = "courtOrderedSupport" + party;

    // Reset court ordered support to 'no' if number of other children is less than 2
    if (value < 2 && formData.getValue('courtOrderedSupport' + party) === 'both') {            
      updatedFields[partyField] = "no";
      updatedFields['orderedSupportAmount' + party] = 0;
    }
    
    if (value === 0) {
      updatedFields['orderedSupportAmount' + party] = 0;
  }

      dispatch({
        type: "updated",
        updatedFields: updatedFields,
      });
  };
    

    return (
      <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
          <Stack spacing={2} m={0}>
            <Typography variant="header">{partyAHeader}</Typography>
            <InfoSelector name="otherKidsA"
                  value={formData.getValue('otherKidsA')}
                  onChange={handleChange}              
                  label="Number of other Children"
                  options={numKids}
                  />
 
            {Number(formData.getValue('otherKidsA')) > 0 ? <CourtOrderedSection party="A" />  : null}                                   
          </Stack>  
        </Grid>

        <Grid xs={12} sm={6}>
          <Stack spacing={2} m={0}>
            <Typography variant="header">{partyBHeader}</Typography>
            <InfoSelector name="otherKidsB"
                  value={formData.getValue('otherKidsB')}
                  onChange={handleChange}              
                  label="Number of other Children"
                  options={numKids}
                  />
            {Number(formData.getValue('otherKidsB')) > 0 ? <CourtOrderedSection party="B" /> : null}  
          </Stack>
        </Grid>
      </Grid>
    );
  };