import Dinero from 'dinero.js';
import { calculateSupport } from './Schedule.js';
import { convertValue } from './Utilities.js';

/**
 * 
 * @param {string} party - a or b, party income is being calculate for
 * @param {Dinero} enteredIncome - amount of income entered by user
 * @param {string} ssDisability - yes or no, is there a derivative benefit
 * @param {string} disabledParty - a or b, the disabled party
 * @param {Dinero} derivativeBenefit - amount of the derivative benefit entered by user
 * @returns {Dinero} calculated gross monthly income 
 */
export const calculateMonthlyGrossIncome = (party, enteredIncome, ssDisability, disabledParty, derivativeBenefit) => {
    let value = enteredIncome;

    if ((ssDisability === 'yes') && (party === disabledParty)) {
        value = enteredIncome.add(derivativeBenefit);
    } 

    return value;
};

/**
 * 
 * @param {string} spousalSupport does this party pay spousal support (no,yes-a/b,yes-others,yes-a/b+others)
 * @param {Dinero} paymentsToOpposing amount of money paid to opposing party
 * @param {Dinero} paymentsToOthers amount of money paid to parties other than opposing
 * @param {string} ssToThisParty does the opposing party pay spousal support to this party (no,yes-a/b,yes-others,yes-a/b+others)
 * @param {Dinero} paymentsToThisParty amount of money paid to this party by opposing
 */
export const calculateSpousalSupport = (spousalSupport, paymentsToOpposing, paymentsToOthers, ssToThisParty, paymentsToThisParty) => {                                        
    
    if (spousalSupport === 'no') {

        if ((ssToThisParty === 'yes-a') || (ssToThisParty === 'yes-b')) {
            return paymentsToThisParty;
        }
        else {
            return Dinero({amount: 0});
        }        
    }

    if ((spousalSupport === 'yes-a') || (spousalSupport === 'yes-b')) {
        return paymentsToOpposing.multiply(-1);
    }

    if (spousalSupport === 'yes-others') {

        if ((ssToThisParty === 'yes-a') || (ssToThisParty === 'yes-b')) {
            return paymentsToThisParty.subtract(paymentsToOthers);
        }
        else {
            return paymentsToOthers.multiply(-1);
        }        
    }

    if ((spousalSupport === 'yes-a+others') || (spousalSupport === 'yes-b+others')) {
        return paymentsToOpposing
            .add(paymentsToOthers)
            .multiply(-1);
    }    
    
    return Dinero({amount: 0});
};

/**
 * 
 * @param {int} otherKids 
 * @param {string} courtOrderedSupport - yes or no
 * @param {Dinero} orderedSupportAmount 
 * @param {int} kidsNotSubject 
 * @param {Dinero} grossIncome
 * @returns {Dinero} child support amount for other children
 */
export const calculateOtherKidsSupport = (otherKids, courtOrderedSupport, orderedSupportAmount, kidsNotSubject, grossIncome) => {

    if (otherKids > 0) {

        switch(courtOrderedSupport) {
            case 'yes':
                return orderedSupportAmount;
            case 'no':
                return calculateSupport(otherKids, grossIncome.getAmount()/100);
            case 'both':
                const convertedSupport = calculateSupport(kidsNotSubject, grossIncome.getAmount()/100);
                return convertedSupport.add(orderedSupportAmount);
            default:
                return null;                
        }            
    }

    return Dinero({amount: 0});
};

/**
 * 
 * @param {*} businessExpenses 
 */
const calculateDeductionsAllowed = (businessExpenses) => {
    if (businessExpenses.isZero()) {
        return businessExpenses;
    }
    else {
        return businessExpenses.multiply(-1);
    }
};

/**
 * Calculates the basic support, sum obligations, and percent of obligations for a party
 * @param {Dinero} totalSharedSupport - total amount of support between both parties
 * @param {Dinero} workRelated - work related expenses paid by other party
 * @param {Dinero} healthcare - healthcare related expenses paid by other party
 * @param {float} percentOvernights - percentage of overnights for OTHER party expressed as a float, not int
 * @param {float} percentIncome - percentage of gross income for this party expressed as a float, not int
 * @returns {Object} basicSupport, sumObligations, partyObligation in an object
 */
export const calculateSharedSupportObligation = (totalSharedSupport, workRelated, healthcare, percentOvernights, percentIncome) => {
    let obligationObj = {};

    obligationObj.basicSupport = totalSharedSupport.multiply(percentOvernights);
    
    obligationObj.sumObligations = obligationObj.basicSupport
        .add(healthcare)
        .add(workRelated);

    obligationObj.partyObligation = obligationObj.sumObligations.multiply(percentIncome);

    return obligationObj;
};

/**
 * Sum up the amount of healthcare paid by each party and if non-custodial parent paid it,
 * they get to deduct it from support owed
 * 
 * @param {string} custodialParty - a or b
 * @param {Dinero} healthcareByA 
 * @param {Dinero} healthcareByB 
 * @returns an object: healthcare, deductionA, deductionB
 */
export const calculateSoleHealthcare = (custodialParty, healthcareByA, healthcareByB) => {

    let response = {};

    // Either party or both parties can contribute to healthcare
    response.healthcare = healthcareByA.add(healthcareByB);
    response.deductionA = convertValue(0);
    response.deductionB = convertValue(0);

    // If healthcare is paid by non-custodial parent, they get to deduct it from support owed   
    if (custodialParty === 'a') {                    
        if (!healthcareByB.isZero()) {
            response.deductionB = healthcareByB.multiply(-1);                
        }
    }
    else {
        if (!healthcareByA.isZero()) {
            response.deductionA = healthcareByA.multiply(-1);                
        }
    }    
    return response;
};

/**
 * Sum up the amount of childcare paid by each party and if non-custodial parent paid it,
 * they get to deduct it from support owed
 * 
 * @param {string} custodialParty - a or b
 * @param {Dinero} childcareByA 
 * @param {Dinero} childcareByB
 * @returns an object: totalChildcare, deductionA, deductionB
 */
export const calculateSoleChildCareExpenses = (custodialParty, childcareByA, childcareByB) => {

    let response = {};

    // Either party or both parties can contribute to healthcare
    response.totalChildcare = childcareByA.add(childcareByB);
    response.deductionA = convertValue(0);
    response.deductionB = convertValue(0);

    // If childcare is paid by non-custodial parent, they get to deduct it from support owed   
    if (custodialParty === 'a') {                    
        if (!childcareByB.isZero()) {
            response.deductionB = childcareByB.multiply(-1);                
        }
    }
    else {
        if (!childcareByA.isZero()) {
            response.deductionA = childcareByA.multiply(-1);                
        }
    }    
    return response;
};

/**
 * Amount owed is the difference between the two amounts
 * It is owed to the party whose obligation is the least
 * @param {Dinero} owedByA 
 * @param {Dinero} owedByB 
 * @returns an object with payableTo, amountOwed
 */
export const calculateAmountOwed = (owedByA, owedByB) => {
    let response = {};

    if (owedByB.greaterThan(owedByA)) {
        response.amountOwed = owedByB.subtract(owedByA);        
        response.owedTo = 'a';
    }
    else {
        response.amountOwed = owedByA.subtract(owedByB);        
        response.owedTo = 'b';
    }
    return response;
};

/**
 * 
 * @param {array} adjustmentList 
 * @param {string} ssDisability - yes or no, is a party receiving derivative benefits
 * @param {string} disabledParty - a or b
 * @param {string} derivativeBenefitParty - a or b, party actually receiving the benefits
 * @param {Dinero} derivativeBenefit - amount of benefits being received
 * @returns an object with totalAdjustmentsA, totalAdjustmentsB, adjustments
 */
export const calculateAdjustments = (adjustmentList, ssDisability, disabledParty, derivativeBenefitParty, derivativeBenefit) => {

    let response = {
        totalAdjustmentsA: convertValue(0),
        totalAdjustmentsB: convertValue(0),
        adjustments: [],
    };

    // If amounts paid to or for the child who is the subject of the order and derived by the 
    // child from the parent’s entitlement to disability insurance benefits have been included
    // in a parent’s gross income, that amount should be subtracted from that parent’s child support obligation
    let calculatedBenefits = convertValue(0);
    if (ssDisability === 'yes') {
        if (disabledParty !== derivativeBenefitParty) {    
            calculatedBenefits = derivativeBenefit.multiply(-1);
        
            if (derivativeBenefitParty === 'a') {
                response.totalAdjustmentsA = response.totalAdjustmentsA.add(calculatedBenefits);
            }
            else {
                response.totalAdjustmentsB = response.totalAdjustmentsB.add(calculatedBenefits);
            }    
        }    
    }
    response.adjustments.push({
        description: "Credit for benefits received by or for the child derived from the parent’s entitlement to disability insurance benefits to the extent that such derivative benefits are included in a parent’s gross income",
        party: derivativeBenefitParty,
        amount: calculatedBenefits,
        id: 1,
    });            

    // Loop through adjustments, add them to displayable list and sum them by party
    adjustmentList.forEach((element) => {   
        const amount = convertValue(element.amount);
        response.adjustments.push(
            {
                id: element.id,
                party: element.party,
                description: element.description,
                amount: amount,
            },
        );
        if (element.party === 'a') {
            response.totalAdjustmentsA = response.totalAdjustmentsA.add(amount);
        }
        else {
            response.totalAdjustmentsB = response.totalAdjustmentsB.add(amount);
        }
    });
   
    return response;
};


/**
 * 
 * @param {*} formData 
 * @returns 
 */
export const computeValues = (formData) => {

    let computedValues = {};

    // Values that will not be used in their original form but are needed for calculations
    const convertedGrossIncomeA = convertValue(formData.getValue('grossIncomeA'));
    const convertedGrossIncomeB = convertValue(formData.getValue('grossIncomeB'));
    const convertedDerivativeBenefit = convertValue(formData.getValue('derivativeBenefit'));
    const convertedSSPaymentAtoB = convertValue(formData.getValue('spousalSupportPaymentAtoB'));
    const convertedSSPaymentAtoOthers = convertValue(formData.getValue('spousalSupportPaymentAtoOthers'));
    const convertedSSPaymentBtoA = convertValue(formData.getValue('spousalSupportPaymentBtoA'));
    const convertedSSPaymentBtoOthers = convertValue(formData.getValue('spousalSupportPaymentBtoOthers'));
    const convertedBusinessExpensesA = convertValue(formData.getValue('businessExpensesA'));
    const convertedBusinessExpensesB = convertValue(formData.getValue('businessExpensesB'));
    const convertedOrderedSupportAmountA = convertValue(formData.getValue('orderedSupportAmountA'));
    const convertedOrderedSupportAmountB = convertValue(formData.getValue('orderedSupportAmountB'));
    
    // (1) / (1)
    computedValues.grossIncomeA = calculateMonthlyGrossIncome('a', convertedGrossIncomeA, formData.getValue('ssDisability'), 
        formData.getValue('disabledParty'), convertedDerivativeBenefit);

    // (2) / (1)
    computedValues.grossIncomeB = calculateMonthlyGrossIncome('b', convertedGrossIncomeB, formData.getValue('ssDisability'), 
        formData.getValue('disabledParty'), convertedDerivativeBenefit);
    
    // (3) / (2)
    computedValues.spousalSupportAmountA = calculateSpousalSupport(formData.getValue('spousalSupportA'), convertedSSPaymentAtoB, 
        convertedSSPaymentAtoOthers, formData.getValue('spousalSupportB'), convertedSSPaymentBtoA);

    // (4) / (2)    
    computedValues.spousalSupportAmountB = calculateSpousalSupport(formData.getValue('spousalSupportB'), convertedSSPaymentBtoA, 
        convertedSSPaymentBtoOthers, formData.getValue('spousalSupportA'), convertedSSPaymentAtoB);

    // (5) / (3)
    computedValues.totalOrderedSupportAmountA = calculateOtherKidsSupport(formData.getValue('otherKidsA'), formData.getValue('courtOrderedSupportA'), 
    convertedOrderedSupportAmountA, formData.getValue('kidsNotSubjectA'), convertedGrossIncomeA).multiply(-1);
        
    // (6) / (3)
    computedValues.totalOrderedSupportAmountB = calculateOtherKidsSupport(formData.getValue('otherKidsB'), formData.getValue('courtOrderedSupportB'), 
    convertedOrderedSupportAmountB, 
        formData.getValue('kidsNotSubjectB'), convertedGrossIncomeB).multiply(-1);           
    // (7)    
    computedValues.deductionsAllowedA = calculateDeductionsAllowed(convertedBusinessExpensesA);
    // (8)
    computedValues.deductionsAllowedB = calculateDeductionsAllowed(convertedBusinessExpensesB);
     
    // (9) / (5a)
    computedValues.calculatedIncomeA = computedValues.grossIncomeA
                        .add(computedValues.spousalSupportAmountA)
                        .add(computedValues.totalOrderedSupportAmountA)
                        .add(computedValues.deductionsAllowedA);
    // (10) / (5a)
    computedValues.calculatedIncomeB = computedValues.grossIncomeB
                        .add(computedValues.spousalSupportAmountB)
                        .add(computedValues.totalOrderedSupportAmountB)
                        .add(computedValues.deductionsAllowedB);
    // (11) / (5b)
    computedValues.totalIncome = computedValues.calculatedIncomeA.add(computedValues.calculatedIncomeB);    

    // (12) / (9)
    computedValues.percentIncomeA =  (computedValues.calculatedIncomeA.getAmount() / computedValues.totalIncome.getAmount() * 100).toFixed(2);
    // (13) / (9)
    computedValues.percentIncomeB = (100 - computedValues.percentIncomeA).toFixed(2);

    // (15) / (7a)
    computedValues.basicChildSupport = calculateSupport(formData.getValue('numCurrentMatterChildren'), computedValues.totalIncome.getAmount()/100);

    // Displayed in Shared, used in Sole, but never displayed
    // (22)
    computedValues.healthcareByA = convertValue(formData.getValue('healthcareByA'));
    // (27)
    computedValues.healthcareByB = convertValue(formData.getValue('healthcareByB'));
 
    // (23)
    computedValues.workRelatedByA = convertValue(formData.getValue('workRelatedByA'));
    // (28)
    computedValues.workRelatedByB = convertValue(formData.getValue('workRelatedByB'));  

    // Shared specific calculations
    switch (formData.getValue('custodyType')) {

    case 'shared':
        // (16)
        // Only shared multiplies child support amount by 1.4        
        computedValues.totalSharedSupport = computedValues.basicChildSupport.multiply(1.4);               

        // (19) 
        computedValues.percentOvernightsA = ((formData.getValue('numOvernightsA') / 365.0) * 100.0).toFixed(2);
        // (20) 
        computedValues.percentOvernightsB = (100 - computedValues.percentOvernightsA).toFixed(2);

        // Each Party's Obligation to Other
        const obligationOfBtoA = calculateSharedSupportObligation(computedValues.totalSharedSupport, computedValues.workRelatedByA,
            computedValues.healthcareByA, computedValues.percentOvernightsA/100, computedValues.percentIncomeB/100);
        // (21)
        computedValues.basicSupportToA = obligationOfBtoA.basicSupport;
        // (24)
        computedValues.sumObligationsToA = obligationOfBtoA.sumObligations;
        // (25)
        computedValues.partyBObligation = obligationOfBtoA.partyObligation;           

        const obligationOfAtoB = calculateSharedSupportObligation(computedValues.totalSharedSupport, computedValues.workRelatedByB,
            computedValues.healthcareByB, computedValues.percentOvernightsB/100, computedValues.percentIncomeA/100);
        // (26)
        computedValues.basicSupportToB = obligationOfAtoB.basicSupport;
        // (29)
        computedValues.sumObligationsToB = obligationOfAtoB.sumObligations;
        // (30)
        computedValues.partyAObligation = obligationOfAtoB.partyObligation;            
        break;
    // Sole specific calculations
    case 'sole':
        const soleHealthcareObj = calculateSoleHealthcare(formData.getValue('custodialParty'), computedValues.healthcareByA, computedValues.healthcareByB);
        // (7b)
        computedValues.healthcare = soleHealthcareObj.healthcare;
        // (11)
        computedValues.healthcareDeductionA = soleHealthcareObj.deductionA;
        // (11)
        computedValues.healthcareDeductionB = soleHealthcareObj.deductionB;

        // (7c)
        // Deviating from the form here. Sole form doesn't support work related expenses for both parties, but apparently it is allowed                
        const soleChildCareObj = calculateSoleChildCareExpenses(formData.getValue('custodialParty'), computedValues.workRelatedByA, computedValues.workRelatedByB);
        computedValues.workRelated = soleChildCareObj.totalChildcare;
        computedValues.childcareDeductionA = soleChildCareObj.deductionA;
        computedValues.childcareDeductionB = soleChildCareObj.deductionB;                

        // (8)
        computedValues.sumObligations = computedValues.basicChildSupport
                .add(computedValues.healthcare)
                .add(computedValues.workRelated);                

        // (10)
        computedValues.partyAObligation = computedValues.sumObligations.multiply(computedValues.percentIncomeA/100);
        computedValues.partyBObligation = computedValues.sumObligations.multiply(computedValues.percentIncomeB/100); 
        break;
    case 'split':
        computedValues.basicChildSupportA = calculateSupport(formData.getValue('numKidsA'), computedValues.totalIncome.getAmount()/100);
        computedValues.basicChildSupportB = calculateSupport(formData.getValue('numKidsB'), computedValues.totalIncome.getAmount()/100);


        computedValues.sumChildSupportA = computedValues.basicChildSupportA
        .add(computedValues.healthcareByB)
        .add(computedValues.workRelatedByB);

        computedValues.sumChildSupportB = computedValues.basicChildSupportB
        .add(computedValues.healthcareByA)
        .add(computedValues.workRelatedByA);

        computedValues.totalChildSupportA = computedValues.sumChildSupportA.multiply(computedValues.percentIncomeA/100);
        computedValues.totalChildSupportB = computedValues.sumChildSupportB.multiply(computedValues.percentIncomeB/100);
     
        break;
    }
   // Shared only
    computedValues.guidelineAmount = Dinero({amount: 0});    

    // Shared pre-calculates this value and displays it, before recalculating again post-adjustments. Sole does not
    if (formData.getValue('custodyType') === 'shared') {
        const preAdjustmentsGuidelines = calculateAmountOwed(computedValues.partyAObligation, computedValues.partyBObligation);
        // (31)
        computedValues.guidelineAmount = preAdjustmentsGuidelines.amountOwed;        
        // (32)
        computedValues.guidelineAmountPayableTo = preAdjustmentsGuidelines.owedTo;
    }

    const calculatedAdjustments = calculateAdjustments(formData.getValue('adjustments'), formData.getValue('ssDisability'), 
    formData.getValue('disabledParty'), formData.getValue('derivativeBenefitParty'), convertedDerivativeBenefit);
        
    // (35)
    computedValues.totalAdjustmentsA = calculatedAdjustments.totalAdjustmentsA;
    // (36)
    computedValues.totalAdjustmentsB = calculatedAdjustments.totalAdjustmentsB;

    computedValues.adjustments = calculatedAdjustments.adjustments;


    switch(formData.getValue('custodyType')) {
        case 'shared':    
        // (38) If Line (35) is larger than Line (36), check Party A on Line (38).     
        if (computedValues.totalAdjustmentsA.greaterThan(computedValues.totalAdjustmentsB)) {
            // (38)
            computedValues.adjustmentsOwedTo = 'a';
            // (37)
            computedValues.netAdjustments = computedValues.totalAdjustmentsB.subtract(computedValues.totalAdjustmentsA);
        }
        // If Line (35) is smaller than Line (36), check Party B on Line (38)
        else {
            // (38)
            computedValues.adjustmentsOwedTo = 'b';
            // (37)
            computedValues.netAdjustments = computedValues.totalAdjustmentsA.subtract(computedValues.totalAdjustmentsB);
        }

        computedValues.totalAdjustedSupport = Dinero({amount: 0});
        // If Lines (31) and (37) are owed to the same party, put the sum of the amounts in these 
        // lines on Line (39) and, in Line (40), check the party checked on line (32). 
        if (computedValues.adjustmentsOwedTo === computedValues.guidelineAmountPayableTo) {
            computedValues.totalAdjustedSupportPayableTo = computedValues.guidelineAmountPayableTo;
            computedValues.totalAdjustedSupport = computedValues.netAdjustments.add(computedValues.guidelineAmount);            
        }
        // If Lines (31) and (37) are owed to different parties, put the difference between 
        // the amounts in these lines on Line (39) and, in Line (40), check the party to whom 
        // the larger of the amounts in Lines (31) and (37) are owed.     
        else {
            if (computedValues.totalAdjustedSupport.greaterThan(computedValues.guidelineAmount)) {
                computedValues.totalAdjustedSupportPayableTo = computedValues.adjustmentsOwedTo;    
                computedValues.totalAdjustedSupport = computedValues.netAdjustments.subtract(computedValues.guidelineAmount);
            }
            else {
                computedValues.totalAdjustedSupportPayableTo = computedValues.guidelineAmountPayableTo;    
                computedValues.totalAdjustedSupport = computedValues.guidelineAmount.subtract(computedValues.netAdjustments);
            }
        }
        break;
    case 'sole':        
        // Handle healthcare deductions and any adjustments
        // (13)
        computedValues.adjustedShareA = computedValues.partyAObligation
                .add(computedValues.healthcareDeductionA)
                .add(computedValues.childcareDeductionA)
                .add(calculatedAdjustments.totalAdjustmentsA);
        // (13)
        computedValues.adjustedShareB = computedValues.partyBObligation
                .add(computedValues.healthcareDeductionB)
                .add(computedValues.childcareDeductionB)
                .add(calculatedAdjustments.totalAdjustmentsB);

        computedValues.totalAdjustedSupportPayableTo = formData.getValue('custodialParty');
        computedValues.totalAdjustedSupport = (formData.getValue('custodialParty') === 'a') ? computedValues.adjustedShareB : computedValues.adjustedShareA;
        break;
    case 'split':
        computedValues.adjustedShareA = computedValues.totalChildSupportA.add(computedValues.totalAdjustmentsA);
        computedValues.adjustedShareB = computedValues.totalChildSupportB.add(computedValues.totalAdjustmentsB);
        computedValues.netPaymentA = (computedValues.adjustedShareA.greaterThan(computedValues.adjustedShareB)) ? computedValues.adjustedShareA.subtract(computedValues.adjustedShareB) : Dinero({amount: 0});
        computedValues.netPaymentB = (computedValues.adjustedShareB.greaterThan(computedValues.adjustedShareA)) ? computedValues.adjustedShareB.subtract(computedValues.adjustedShareA) : Dinero({amount: 0});        
        computedValues.totalAdjustedSupportPayableTo = (computedValues.netPaymentA.greaterThan(computedValues.netPaymentB)) ? 'b' : 'a';
        computedValues.totalAdjustedSupport = (computedValues.netPaymentA.greaterThan(computedValues.netPaymentB)) ? computedValues.netPaymentA : computedValues.netPaymentB;

        break;
    }

    return computedValues;
  };