import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepButton } from '@mui/material';

export const CSStepper = ({activeStep, setActiveStep, steps}) => { 

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepButton {...labelProps} onClick={handleStep(index)}>{label}</StepButton>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}