import { convertValue } from './Utilities';

/**
 * 
 * @param {int} numChildren 
 * @param {float} totalIncome 
 * @returns Dinero - calculated child support to 2 decimal points for the given number of children and income
 */
const calculateSupport = (numChildren, totalIncome) => {

    const incomeStep = 50;
    const minTableIncome = 350;
    const maxTableIncome = 35000;
    const filteredList = schedule.filter((item) => item.income >= totalIncome-incomeStep);

    let calculatedAmount = 0;

    if (numChildren > 6) {
        throw(new Error("Number of Children, " + numChildren + ", is greater than maximum value of 6"));
    }
    
    // For gross incomes under $350, always return the lowest tier amount. No extrapolation 
    if (totalIncome <= minTableIncome) {
        const minRow = schedule[0];
        calculatedAmount = minRow.amounts[numChildren-1];        
    } else if (totalIncome < maxTableIncome) {
        // Logic to extrapolate the child support amount for income amounts not aligned with bands
        // For example, if income is 60% above a band, add 60% of the difference between that band         
        // and the next to the amount
        const lowerAmount = filteredList[0].amounts[numChildren-1];
        const upperAmount = filteredList[1].amounts[numChildren-1];
        const rowDiff = upperAmount - lowerAmount;
        const incomeDiffPercentage = (totalIncome - filteredList[0].income) / incomeStep;
        const rowAdjustment = rowDiff * incomeDiffPercentage;
        calculatedAmount = lowerAmount + rowAdjustment;
    }
    else {
        // For gross monthly incomes above $35,000, add the amount of child support for $35,000 
        // to the following percentages of gross income above $35,000
        const maxRow = schedule[schedule.length-1];
        calculatedAmount = maxRow.amounts[numChildren-1] + 
                            ((totalIncome-maxTableIncome) * overageAmount[numChildren-1]); 
    } 
        
    return convertValue(calculatedAmount);
};

// Percentages for gross income above $35,000 per number of children
const overageAmount = [0.026,0.034, 0.038, 0.042, 0.046, 0.050];

const schedule = [    
    {income: 350, amounts: [68, 104, 126, 141, 155, 169]},
    {income: 400, amounts: [78, 119, 144, 161, 177, 192]},
    {income: 450, amounts: [88, 133, 162, 181, 199, 216]},
    {income: 500, amounts: [97, 148, 179, 200, 220, 239]},
    {income: 550, amounts: [107, 162, 197, 220, 242, 263]},
    {income: 600, amounts: [116, 177, 215, 240, 264, 287]},
    {income: 650, amounts: [126, 191, 232, 259, 285, 310]},
    {income: 700, amounts: [135, 206, 250, 279, 307, 333]},
    {income: 750, amounts: [145, 220, 267, 298, 328, 357]},
    {income: 800, amounts: [154, 234, 284, 317, 349, 379]},
    {income: 850, amounts: [163, 248, 300, 336, 369, 401]},
    {income: 900, amounts: [171, 260, 316, 353, 388, 422]},
    {income: 950, amounts: [179, 273, 331, 369, 406, 442]},
    {income: 1000, amounts: [187, 285, 346, 386, 425, 462]},
    {income: 1050, amounts: [196, 298, 361, 403, 443, 482]},
    {income: 1100, amounts: [204, 310, 375, 419, 461, 501]},
    {income: 1150, amounts: [212, 323, 390, 436, 480, 521]},
    {income: 1200, amounts: [220, 335, 405, 453, 498, 541]},
    {income: 1250, amounts: [228, 347, 420, 469, 516, 561]},
    {income: 1300, amounts: [237, 360, 435, 486, 535, 581]},
    {income: 1350, amounts: [245, 372, 450, 503, 553, 601]},
    {income: 1400, amounts: [253, 385, 465, 519, 571, 621]},
    {income: 1450, amounts: [261, 397, 480, 536, 589, 641]},
    {income: 1500, amounts: [269, 410, 495, 552, 608, 661]},
    {income: 1550, amounts: [278, 422, 509, 569, 626, 680]},
    {income: 1600, amounts: [286, 434, 524, 585, 644, 700]},
    {income: 1650, amounts: [293, 446, 538, 601, 661, 718]},
    {income: 1700, amounts: [301, 457, 552, 616, 678, 737]},
    {income: 1750, amounts: [309, 469, 566, 632, 695, 756]},
    {income: 1800, amounts: [316, 481, 579, 647, 712, 774]},
    {income: 1850, amounts: [324, 492, 593, 663, 729, 792]},
    {income: 1900, amounts: [331, 504, 607, 678, 746, 811]},
    {income: 1950, amounts: [339, 515, 621, 693, 763, 829]},
    {income: 2000, amounts: [347, 527, 635, 709, 780, 848]},
    {income: 2050, amounts: [354, 538, 648, 724, 797, 866]},
    {income: 2100, amounts: [362, 550, 662, 740, 814, 884]},
    {income: 2150, amounts: [369, 561, 676, 755, 830, 903]},
    {income: 2200, amounts: [377, 573, 690, 770, 847, 921]},
    {income: 2250, amounts: [385, 584, 703, 786, 864, 940]},
    {income: 2300, amounts: [392, 596, 717, 801, 881, 958]},
    {income: 2350, amounts: [400, 607, 731, 817, 898, 976]},
    {income: 2400, amounts: [407, 619, 745, 832, 915, 995]},
    {income: 2450, amounts: [415, 630, 759, 847, 932, 1013]},
    {income: 2500, amounts: [423, 642, 772, 863, 949, 1032]},
    {income: 2550, amounts: [430, 653, 786, 878, 966, 1050]},
    {income: 2600, amounts: [438, 665, 800, 894, 983, 1068]},
    {income: 2650, amounts: [445, 676, 814, 909, 1000, 1087]},
    {income: 2700, amounts: [453, 688, 828, 924, 1017, 1105]},
    {income: 2750, amounts: [460, 699, 841, 940, 1034, 1124]},
    {income: 2800, amounts: [468, 711, 855, 955, 1051, 1142]},
    {income: 2850, amounts: [476, 722, 869, 971, 1068, 1160]},
    {income: 2900, amounts: [483, 734, 883, 986, 1084, 1179]},
    {income: 2950, amounts: [491, 745, 896, 1001, 1101, 1197]},
    {income: 3000, amounts: [498, 757, 910, 1017, 1118, 1216]},
    {income: 3050, amounts: [506, 768, 924, 1032, 1135, 1234]},
    {income: 3100, amounts: [514, 780, 938, 1047, 1152, 1252]},
    {income: 3150, amounts: [521, 791, 952, 1063, 1169, 1271]},
    {income: 3200, amounts: [529, 803, 965, 1078, 1186, 1289]},
    {income: 3250, amounts: [536, 814, 979, 1094, 1203, 1308]},
    {income: 3300, amounts: [544, 826, 993, 1109, 1220, 1326]},
    {income: 3350, amounts: [551, 837, 1006, 1123, 1236, 1343]},
    {income: 3400, amounts: [559, 848, 1019, 1138, 1252, 1361]},
    {income: 3450, amounts: [566, 859, 1032, 1152, 1268, 1378]},
    {income: 3500, amounts: [574, 870, 1045, 1167, 1283, 1395]},
    {income: 3550, amounts: [581, 881, 1057, 1181, 1299, 1412]},
    {income: 3600, amounts: [588, 892, 1070, 1196, 1315, 1430]},
    {income: 3650, amounts: [596, 903, 1083, 1210, 1331, 1447]},
    {income: 3700, amounts: [603, 914, 1096, 1224, 1347, 1464]},
    {income: 3750, amounts: [611, 925, 1109, 1239, 1363, 1481]},
    {income: 3800, amounts: [618, 936, 1122, 1253, 1379, 1499]},
    {income: 3850, amounts: [626, 947, 1135, 1268, 1395, 1516]},
    {income: 3900, amounts: [632, 956, 1146, 1280, 1408, 1531]},
    {income: 3950, amounts: [638, 966, 1157, 1293, 1422, 1546]},
    {income: 4000, amounts: [645, 975, 1168, 1305, 1436, 1561]},
    {income: 4050, amounts: [651, 985, 1180, 1318, 1449, 1575]},
    {income: 4100, amounts: [658, 994, 1191, 1330, 1463, 1590]},
    {income: 4150, amounts: [664, 1004, 1202, 1342, 1477, 1605]},
    {income: 4200, amounts: [670, 1013, 1213, 1355, 1490, 1620]},
    {income: 4250, amounts: [677, 1023, 1224, 1367, 1504, 1635]},
    {income: 4300, amounts: [682, 1030, 1233, 1377, 1515, 1647]},
    {income: 4350, amounts: [687, 1038, 1242, 1387, 1526, 1658]},
    {income: 4400, amounts: [693, 1046, 1251, 1397, 1537, 1670]},
    {income: 4450, amounts: [698, 1054, 1260, 1407, 1548, 1682]},
    {income: 4500, amounts: [704, 1062, 1268, 1417, 1559, 1694]},
    {income: 4550, amounts: [709, 1069, 1277, 1427, 1569, 1706]},
    {income: 4600, amounts: [714, 1077, 1286, 1437, 1580, 1718]},
    {income: 4650, amounts: [720, 1085, 1295, 1447, 1591, 1730]},
    {income: 4700, amounts: [725, 1093, 1304, 1457, 1602, 1742]},
    {income: 4750, amounts: [731, 1100, 1313, 1466, 1613, 1753]},
    {income: 4800, amounts: [736, 1108, 1322, 1476, 1624, 1765]},
    {income: 4850, amounts: [741, 1116, 1331, 1486, 1635, 1777]},
    {income: 4900, amounts: [747, 1124, 1339, 1496, 1646, 1789]},
    {income: 4950, amounts: [752, 1131, 1348, 1506, 1656, 1800]},
    {income: 5000, amounts: [755, 1136, 1353, 1511, 1662, 1807]},
    {income: 5050, amounts: [759, 1141, 1358, 1516, 1668, 1813]},
    {income: 5100, amounts: [762, 1145, 1362, 1522, 1674, 1820]},
    {income: 5150, amounts: [766, 1150, 1367, 1527, 1680, 1826]},
    {income: 5200, amounts: [769, 1155, 1372, 1533, 1686, 1833]},
    {income: 5250, amounts: [773, 1159, 1377, 1538, 1692, 1839]},
    {income: 5300, amounts: [776, 1164, 1382, 1544, 1698, 1846]},
    {income: 5350, amounts: [780, 1169, 1387, 1549, 1704, 1852]},
    {income: 5400, amounts: [783, 1173, 1392, 1554, 1710, 1859]},
    {income: 5450, amounts: [787, 1178, 1397, 1560, 1716, 1865]},
    {income: 5500, amounts: [790, 1183, 1401, 1565, 1722, 1872]},
    {income: 5550, amounts: [794, 1187, 1406, 1571, 1728, 1878]},
    {income: 5600, amounts: [797, 1192, 1411, 1576, 1734, 1885]},
    {income: 5650, amounts: [800, 1196, 1416, 1582, 1740, 1891]},
    {income: 5700, amounts: [803, 1201, 1421, 1587, 1746, 1897]},
    {income: 5750, amounts: [806, 1205, 1425, 1592, 1751, 1904]},
    {income: 5800, amounts: [809, 1209, 1430, 1598, 1757, 1910]},
    {income: 5850, amounts: [812, 1213, 1435, 1603, 1763, 1917]},
    {income: 5900, amounts: [815, 1217, 1440, 1608, 1769, 1923]},
    {income: 5950, amounts: [818, 1221, 1444, 1613, 1775, 1929]},
    {income: 6000, amounts: [821, 1226, 1449, 1619, 1781, 1936]},
    {income: 6050, amounts: [823, 1230, 1454, 1624, 1787, 1942]},
    {income: 6100, amounts: [826, 1234, 1459, 1629, 1792, 1948]},
    {income: 6150, amounts: [829, 1238, 1464, 1635, 1798, 1955]},
    {income: 6200, amounts: [832, 1242, 1468, 1640, 1804, 1961]},
    {income: 6250, amounts: [835, 1246, 1473, 1645, 1810, 1967]},
    {income: 6300, amounts: [838, 1251, 1478, 1651, 1816, 1974]},
    {income: 6350, amounts: [841, 1255, 1483, 1656, 1822, 1980]},
    {income: 6400, amounts: [844, 1259, 1487, 1661, 1827, 1986]},
    {income: 6450, amounts: [847, 1263, 1492, 1667, 1833, 1993]},
    {income: 6500, amounts: [849, 1267, 1497, 1672, 1839, 1999]},
    {income: 6550, amounts: [852, 1271, 1502, 1677, 1845, 2005]},
    {income: 6600, amounts: [855, 1276, 1506, 1683, 1851, 2012]},
    {income: 6650, amounts: [858, 1280, 1511, 1688, 1857, 2018]},
    {income: 6700, amounts: [861, 1285, 1517, 1694, 1864, 2026]},
    {income: 6750, amounts: [865, 1291, 1524, 1703, 1873, 2036]},
    {income: 6800, amounts: [869, 1297, 1532, 1711, 1882, 2046]},
    {income: 6850, amounts: [873, 1303, 1539, 1719, 1891, 2056]},
    {income: 6900, amounts: [877, 1309, 1547, 1728, 1900, 2066]},
    {income: 6950, amounts: [881, 1315, 1554, 1736, 1909, 2076]},
    {income: 7000, amounts: [885, 1321, 1561, 1744, 1919, 2085]},
    {income: 7050, amounts: [889, 1328, 1569, 1752, 1928, 2095]},
    {income: 7100, amounts: [893, 1334, 1576, 1761, 1937, 2105]},
    {income: 7150, amounts: [897, 1340, 1584, 1769, 1946, 2115]},
    {income: 7200, amounts: [901, 1346, 1591, 1777, 1955, 2125]},
    {income: 7250, amounts: [905, 1352, 1599, 1786, 1964, 2135]},
    {income: 7300, amounts: [909, 1358, 1606, 1794, 1973, 2145]},
    {income: 7350, amounts: [913, 1364, 1613, 1802, 1982, 2155]},
    {income: 7400, amounts: [917, 1370, 1621, 1810, 1991, 2165]},
    {income: 7450, amounts: [921, 1376, 1628, 1819, 2001, 2175]},
    {income: 7500, amounts: [925, 1382, 1636, 1827, 2010, 2185]},
    {income: 7550, amounts: [929, 1389, 1643, 1835, 2019, 2194]},
    {income: 7600, amounts: [933, 1395, 1650, 1844, 2028, 2204]},
    {income: 7650, amounts: [937, 1401, 1658, 1852, 2037, 2214]},
    {income: 7700, amounts: [941, 1407, 1665, 1860, 2046, 2224]},
    {income: 7750, amounts: [944, 1411, 1670, 1865, 2051, 2230]},
    {income: 7800, amounts: [946, 1413, 1672, 1867, 2054, 2233]},
    {income: 7850, amounts: [948, 1416, 1674, 1870, 2057, 2236]},
    {income: 7900, amounts: [950, 1419, 1676, 1873, 2060, 2239]},
    {income: 7950, amounts: [953, 1421, 1679, 1875, 2063, 2242]},
    {income: 8000, amounts: [955, 1424, 1681, 1878, 2065, 2245]},
    {income: 8050, amounts: [957, 1426, 1683, 1880, 2068, 2248]},
    {income: 8100, amounts: [959, 1429, 1685, 1883, 2071, 2251]},
    {income: 8150, amounts: [961, 1432, 1688, 1885, 2074, 2254]},
    {income: 8200, amounts: [963, 1434, 1690, 1888, 2076, 2257]},
    {income: 8250, amounts: [965, 1436, 1692, 1890, 2079, 2260]},
    {income: 8300, amounts: [967, 1439, 1694, 1892, 2082, 2263]},
    {income: 8350, amounts: [969, 1441, 1696, 1895, 2084, 2266]},
    {income: 8400, amounts: [971, 1444, 1699, 1897, 2087, 2269]},
    {income: 8450, amounts: [973, 1446, 1701, 1899, 2089, 2271]},
    {income: 8500, amounts: [974, 1447, 1702, 1901, 2091, 2273]},
    {income: 8550, amounts: [975, 1449, 1704, 1903, 2093, 2276]},
    {income: 8600, amounts: [976, 1450, 1705, 1905, 2096, 2278]},
    {income: 8650, amounts: [977, 1452, 1707, 1907, 2098, 2280]},
    {income: 8700, amounts: [978, 1453, 1709, 1909, 2100, 2282]},
    {income: 8750, amounts: [979, 1455, 1710, 1911, 2102, 2284]},
    {income: 8800, amounts: [980, 1456, 1712, 1912, 2104, 2287]},
    {income: 8850, amounts: [981, 1457, 1714, 1914, 2106, 2289]},
    {income: 8900, amounts: [982, 1459, 1715, 1916, 2108, 2291]},
    {income: 8950, amounts: [983, 1460, 1717, 1918, 2110, 2293]},
    {income: 9000, amounts: [984, 1462, 1719, 1920, 2112, 2295]},
    {income: 9050, amounts: [985, 1463, 1720, 1922, 2114, 2298]},
    {income: 9100, amounts: [986, 1465, 1722, 1923, 2116, 2300]},
    {income: 9150, amounts: [987, 1466, 1724, 1925, 2118, 2302]},
    {income: 9200, amounts: [991, 1471, 1730, 1932, 2125, 2310]},
    {income: 9250, amounts: [994, 1477, 1737, 1940, 2134, 2319]},
    {income: 9300, amounts: [998, 1483, 1743, 1947, 2142, 2328]},
    {income: 9350, amounts: [1002, 1488, 1750, 1955, 2150, 2337]},
    {income: 9400, amounts: [1005, 1494, 1757, 1962, 2159, 2346]},
    {income: 9450, amounts: [1009, 1499, 1764, 1970, 2167, 2355]},
    {income: 9500, amounts: [1013, 1505, 1771, 1978, 2176, 2365]},
    {income: 9550, amounts: [1017, 1511, 1778, 1986, 2185, 2375]},
    {income: 9600, amounts: [1021, 1518, 1786, 1995, 2194, 2385]},
    {income: 9650, amounts: [1025, 1524, 1793, 2003, 2203, 2395]},
    {income: 9700, amounts: [1029, 1530, 1801, 2011, 2212, 2405]},
    {income: 9750, amounts: [1033, 1536, 1808, 2020, 2222, 2415]},
    {income: 9800, amounts: [1037, 1543, 1816, 2028, 2231, 2425]},
    {income: 9850, amounts: [1041, 1549, 1823, 2036, 2240, 2435]},
    {income: 9900, amounts: [1046, 1555, 1831, 2045, 2249, 2445]},
    {income: 9950, amounts: [1050, 1561, 1838, 2053, 2258, 2455]},
    {income: 10000, amounts: [1054, 1567, 1845, 2061, 2268, 2465]},
    {income: 10050, amounts: [1058, 1574, 1853, 2070, 2277, 2475]},
    {income: 10100, amounts: [1062, 1580, 1860, 2078, 2286, 2485]},
    {income: 10150, amounts: [1066, 1586, 1868, 2086, 2295, 2495]},
    {income: 10200, amounts: [1070, 1592, 1875, 2095, 2304, 2505]},
    {income: 10250, amounts: [1074, 1599, 1883, 2103, 2314, 2515]},
    {income: 10300, amounts: [1079, 1605, 1891, 2112, 2323, 2525]},
    {income: 10350, amounts: [1083, 1611, 1898, 2121, 2333, 2536]},
    {income: 10400, amounts: [1087, 1618, 1906, 2129, 2342, 2546]},
    {income: 10450, amounts: [1091, 1624, 1914, 2138, 2351, 2556]},
    {income: 10500, amounts: [1095, 1631, 1921, 2146, 2361, 2566]},
    {income: 10550, amounts: [1100, 1637, 1929, 2155, 2370, 2576]},
    {income: 10600, amounts: [1104, 1643, 1937, 2163, 2380, 2587]},
    {income: 10650, amounts: [1108, 1650, 1944, 2172, 2389, 2597]},
    {income: 10700, amounts: [1112, 1656, 1952, 2180, 2398, 2607]},
    {income: 10750, amounts: [1117, 1662, 1960, 2189, 2408, 2617]},
    {income: 10800, amounts: [1121, 1669, 1967, 2197, 2417, 2627]},
    {income: 10850, amounts: [1125, 1675, 1975, 2206, 2427, 2638]},
    {income: 10900, amounts: [1129, 1682, 1983, 2214, 2436, 2648]},
    {income: 10950, amounts: [1134, 1688, 1990, 2223, 2445, 2658]},
    {income: 11000, amounts: [1138, 1694, 1998, 2232, 2455, 2668]},
    {income: 11050, amounts: [1142, 1701, 2005, 2240, 2464, 2678]},
    {income: 11100, amounts: [1146, 1707, 2013, 2249, 2474, 2689]},
    {income: 11150, amounts: [1150, 1714, 2021, 2257, 2483, 2699]},
    {income: 11200, amounts: [1154, 1718, 2026, 2263, 2489, 2706]},
    {income: 11250, amounts: [1157, 1722, 2030, 2267, 2494, 2711]},
    {income: 11300, amounts: [1159, 1726, 2034, 2272, 2499, 2717]},
    {income: 11350, amounts: [1162, 1730, 2038, 2276, 2504, 2722]},
    {income: 11400, amounts: [1165, 1733, 2042, 2281, 2509, 2727]},
    {income: 11450, amounts: [1168, 1737, 2046, 2285, 2514, 2733]},
    {income: 11500, amounts: [1171, 1741, 2050, 2290, 2519, 2738]},
    {income: 11550, amounts: [1173, 1745, 2054, 2294, 2524, 2743]},
    {income: 11600, amounts: [1176, 1749, 2058, 2299, 2529, 2749]},
    {income: 11650, amounts: [1179, 1752, 2062, 2303, 2534, 2754]},
    {income: 11700, amounts: [1182, 1756, 2066, 2308, 2538, 2759]},
    {income: 11750, amounts: [1185, 1760, 2070, 2312, 2543, 2765]},
    {income: 11800, amounts: [1187, 1764, 2074, 2317, 2548, 2770]},
    {income: 11850, amounts: [1190, 1768, 2078, 2321, 2553, 2775]},
    {income: 11900, amounts: [1193, 1771, 2082, 2326, 2558, 2781]},
    {income: 11950, amounts: [1196, 1775, 2086, 2330, 2563, 2786]},
    {income: 12000, amounts: [1199, 1779, 2090, 2335, 2568, 2791]},
    {income: 12050, amounts: [1201, 1783, 2094, 2339, 2573, 2797]},
    {income: 12100, amounts: [1204, 1787, 2098, 2344, 2578, 2802]},
    {income: 12150, amounts: [1207, 1790, 2102, 2348, 2583, 2808]},
    {income: 12200, amounts: [1210, 1795, 2107, 2354, 2589, 2815]},
    {income: 12250, amounts: [1213, 1800, 2113, 2360, 2596, 2822]},
    {income: 12300, amounts: [1216, 1804, 2118, 2366, 2603, 2829]},
    {income: 12350, amounts: [1220, 1809, 2124, 2372, 2610, 2837]},
    {income: 12400, amounts: [1223, 1814, 2129, 2378, 2616, 2844]},
    {income: 12450, amounts: [1226, 1818, 2135, 2384, 2623, 2851]},
    {income: 12500, amounts: [1229, 1823, 2140, 2391, 2630, 2858]},
    {income: 12550, amounts: [1232, 1828, 2146, 2397, 2636, 2866]},
    {income: 12600, amounts: [1235, 1832, 2151, 2403, 2643, 2873]},
    {income: 12650, amounts: [1239, 1837, 2157, 2409, 2650, 2880]},
    {income: 12700, amounts: [1242, 1842, 2162, 2415, 2657, 2888]},
    {income: 12750, amounts: [1245, 1846, 2168, 2421, 2663, 2895]},
    {income: 12800, amounts: [1248, 1851, 2173, 2427, 2670, 2902]},
    {income: 12850, amounts: [1251, 1856, 2178, 2433, 2677, 2910]},
    {income: 12900, amounts: [1254, 1860, 2184, 2439, 2683, 2917]},
    {income: 12950, amounts: [1257, 1865, 2189, 2446, 2690, 2924]},
    {income: 13000, amounts: [1261, 1870, 2195, 2452, 2697, 2931]},
    {income: 13050, amounts: [1264, 1874, 2200, 2458, 2704, 2939]},
    {income: 13100, amounts: [1267, 1879, 2206, 2464, 2710, 2946]},
    {income: 13150, amounts: [1270, 1884, 2211, 2470, 2717, 2953]},
    {income: 13200, amounts: [1273, 1888, 2217, 2476, 2724, 2961]},
    {income: 13250, amounts: [1276, 1893, 2222, 2482, 2730, 2968]},
    {income: 13300, amounts: [1279, 1898, 2228, 2488, 2737, 2975]},
    {income: 13350, amounts: [1283, 1902, 2233, 2494, 2744, 2983]},
    {income: 13400, amounts: [1286, 1907, 2239, 2501, 2751, 2990]},
    {income: 13450, amounts: [1289, 1912, 2244, 2507, 2757, 2997]},
    {income: 13500, amounts: [1292, 1916, 2250, 2513, 2764, 3005]},
    {income: 13550, amounts: [1295, 1921, 2256, 2520, 2772, 3013]},
    {income: 13600, amounts: [1297, 1925, 2262, 2526, 2779, 3021]},
    {income: 13650, amounts: [1300, 1930, 2268, 2533, 2786, 3029]},
    {income: 13700, amounts: [1303, 1935, 2274, 2540, 2794, 3037]},
    {income: 13750, amounts: [1306, 1939, 2280, 2546, 2801, 3045]},
    {income: 13800, amounts: [1308, 1944, 2286, 2553, 2808, 3053]},
    {income: 13850, amounts: [1311, 1948, 2292, 2560, 2816, 3061]},
    {income: 13900, amounts: [1314, 1953, 2298, 2566, 2823, 3069]},
    {income: 13950, amounts: [1317, 1957, 2304, 2573, 2830, 3077]},
    {income: 14000, amounts: [1320, 1962, 2310, 2580, 2838, 3085]},
    {income: 14050, amounts: [1322, 1967, 2316, 2586, 2845, 3093]},
    {income: 14100, amounts: [1325, 1971, 2322, 2593, 2852, 3101]},
    {income: 14150, amounts: [1328, 1976, 2328, 2600, 2860, 3109]},
    {income: 14200, amounts: [1331, 1980, 2333, 2607, 2867, 3117]},
    {income: 14250, amounts: [1334, 1985, 2339, 2613, 2875, 3125]},
    {income: 14300, amounts: [1336, 1990, 2345, 2620, 2882, 3133]},
    {income: 14350, amounts: [1339, 1994, 2351, 2627, 2889, 3141]},
    {income: 14400, amounts: [1342, 1999, 2357, 2633, 2897, 3149]},
    {income: 14450, amounts: [1345, 2003, 2363, 2640, 2904, 3157]},
    {income: 14500, amounts: [1347, 2008, 2369, 2647, 2911, 3164]},
    {income: 14550, amounts: [1350, 2013, 2375, 2653, 2919, 3172]},
    {income: 14600, amounts: [1353, 2017, 2381, 2660, 2926, 3180]},
    {income: 14650, amounts: [1356, 2022, 2387, 2667, 2933, 3188]},
    {income: 14700, amounts: [1359, 2026, 2393, 2673, 2941, 3196]},
    {income: 14750, amounts: [1361, 2031, 2399, 2680, 2948, 3204]},
    {income: 14800, amounts: [1364, 2036, 2405, 2686, 2955, 3212]},
    {income: 14850, amounts: [1368, 2040, 2410, 2692, 2961, 3219]},
    {income: 14900, amounts: [1371, 2045, 2415, 2698, 2967, 3226]},
    {income: 14950, amounts: [1375, 2050, 2420, 2703, 2974, 3232]},
    {income: 15000, amounts: [1378, 2055, 2425, 2709, 2980, 3239]},
    {income: 15050, amounts: [1382, 2059, 2430, 2714, 2986, 3246]},
    {income: 15100, amounts: [1385, 2064, 2435, 2720, 2992, 3252]},
    {income: 15150, amounts: [1389, 2069, 2440, 2726, 2998, 3259]},
    {income: 15200, amounts: [1392, 2074, 2445, 2731, 3004, 3266]},
    {income: 15250, amounts: [1396, 2078, 2450, 2737, 3010, 3272]},
    {income: 15300, amounts: [1400, 2083, 2455, 2742, 3017, 3279]},
    {income: 15350, amounts: [1403, 2088, 2460, 2748, 3023, 3286]},
    {income: 15400, amounts: [1407, 2093, 2465, 2754, 3029, 3292]},
    {income: 15450, amounts: [1410, 2098, 2470, 2759, 3035, 3299]},
    {income: 15500, amounts: [1414, 2102, 2475, 2765, 3041, 3306]},
    {income: 15550, amounts: [1417, 2107, 2480, 2770, 3047, 3312]},
    {income: 15600, amounts: [1421, 2112, 2485, 2776, 3053, 3319]},
    {income: 15650, amounts: [1424, 2117, 2490, 2781, 3060, 3326]},
    {income: 15700, amounts: [1428, 2121, 2495, 2787, 3066, 3333]},
    {income: 15750, amounts: [1431, 2126, 2500, 2793, 3072, 3339]},
    {income: 15800, amounts: [1435, 2131, 2505, 2798, 3078, 3346]},
    {income: 15850, amounts: [1438, 2136, 2510, 2804, 3084, 3353]},
    {income: 15900, amounts: [1442, 2140, 2515, 2809, 3090, 3359]},
    {income: 15950, amounts: [1445, 2145, 2520, 2815, 3097, 3366]},
    {income: 16000, amounts: [1449, 2150, 2525, 2821, 3103, 3373]},
    {income: 16050, amounts: [1453, 2155, 2530, 2826, 3109, 3379]},
    {income: 16100, amounts: [1456, 2159, 2535, 2832, 3115, 3386]},
    {income: 16150, amounts: [1458, 2162, 2538, 2835, 3119, 3390]},
    {income: 16200, amounts: [1459, 2164, 2541, 2838, 3122, 3394]},
    {income: 16250, amounts: [1461, 2167, 2544, 2841, 3125, 3397]},
    {income: 16300, amounts: [1462, 2169, 2546, 2844, 3128, 3401]},
    {income: 16350, amounts: [1464, 2171, 2549, 2847, 3132, 3404]},
    {income: 16400, amounts: [1465, 2173, 2551, 2850, 3135, 3408]},
    {income: 16450, amounts: [1466, 2175, 2554, 2853, 3138, 3411]},
    {income: 16500, amounts: [1468, 2177, 2557, 2856, 3141, 3415]},
    {income: 16550, amounts: [1469, 2179, 2559, 2859, 3144, 3418]},
    {income: 16600, amounts: [1471, 2182, 2562, 2862, 3148, 3422]},
    {income: 16650, amounts: [1472, 2184, 2564, 2864, 3151, 3425]},
    {income: 16700, amounts: [1473, 2186, 2567, 2867, 3154, 3428]},
    {income: 16750, amounts: [1475, 2188, 2570, 2870, 3157, 3432]},
    {income: 16800, amounts: [1476, 2190, 2572, 2873, 3160, 3435]},
    {income: 16850, amounts: [1477, 2192, 2575, 2876, 3164, 3439]},
    {income: 16900, amounts: [1479, 2194, 2577, 2879, 3167, 3442]},
    {income: 16950, amounts: [1480, 2196, 2580, 2882, 3170, 3446]},
    {income: 17000, amounts: [1481, 2198, 2582, 2885, 3173, 3449]},
    {income: 17050, amounts: [1483, 2200, 2585, 2887, 3176, 3452]},
    {income: 17100, amounts: [1484, 2203, 2588, 2890, 3179, 3456]},
    {income: 17150, amounts: [1486, 2205, 2590, 2893, 3182, 3459]},
    {income: 17200, amounts: [1487, 2207, 2593, 2896, 3186, 3463]},
    {income: 17250, amounts: [1488, 2209, 2595, 2899, 3189, 3466]},
    {income: 17300, amounts: [1490, 2211, 2598, 2902, 3192, 3470]},
    {income: 17350, amounts: [1491, 2213, 2600, 2905, 3195, 3473]},
    {income: 17400, amounts: [1492, 2215, 2603, 2907, 3198, 3476]},
    {income: 17450, amounts: [1494, 2217, 2605, 2910, 3201, 3480]},
    {income: 17500, amounts: [1495, 2219, 2608, 2913, 3204, 3483]},
    {income: 17550, amounts: [1497, 2222, 2611, 2916, 3208, 3487]},
    {income: 17600, amounts: [1498, 2224, 2613, 2919, 3211, 3490]},
    {income: 17650, amounts: [1499, 2226, 2616, 2922, 3214, 3494]},
    {income: 17700, amounts: [1501, 2228, 2618, 2925, 3217, 3497]},
    {income: 17750, amounts: [1502, 2230, 2621, 2928, 3220, 3500]},
    {income: 17800, amounts: [1503, 2232, 2623, 2930, 3223, 3504]},
    {income: 17850, amounts: [1505, 2234, 2626, 2933, 3227, 3507]},
    {income: 17900, amounts: [1506, 2236, 2629, 2936, 3230, 3511]},
    {income: 17950, amounts: [1507, 2238, 2631, 2939, 3233, 3514]},
    {income: 18000, amounts: [1509, 2240, 2634, 2942, 3236, 3518]},
    {income: 18050, amounts: [1510, 2243, 2636, 2945, 3239, 3521]},
    {income: 18100, amounts: [1512, 2245, 2639, 2948, 3242, 3524]},
    {income: 18150, amounts: [1513, 2247, 2641, 2950, 3245, 3528]},
    {income: 18200, amounts: [1514, 2249, 2644, 2953, 3249, 3531]},
    {income: 18250, amounts: [1516, 2251, 2647, 2956, 3252, 3535]},
    {income: 18300, amounts: [1517, 2253, 2649, 2959, 3255, 3538]},
    {income: 18350, amounts: [1520, 2256, 2652, 2963, 3259, 3542]},
    {income: 18400, amounts: [1522, 2259, 2655, 2966, 3263, 3547]},
    {income: 18450, amounts: [1524, 2262, 2658, 2970, 3266, 3551]},
    {income: 18500, amounts: [1526, 2265, 2662, 2973, 3270, 3555]},
    {income: 18550, amounts: [1528, 2268, 2665, 2976, 3274, 3559]},
    {income: 18600, amounts: [1530, 2271, 2668, 2980, 3278, 3563]},
    {income: 18650, amounts: [1532, 2274, 2671, 2983, 3282, 3567]},
    {income: 18700, amounts: [1535, 2277, 2674, 2987, 3285, 3571]},
    {income: 18750, amounts: [1537, 2280, 2677, 2990, 3289, 3575]},
    {income: 18800, amounts: [1539, 2283, 2680, 2994, 3293, 3579]},
    {income: 18850, amounts: [1541, 2285, 2683, 2997, 3297, 3584]},
    {income: 18900, amounts: [1543, 2288, 2686, 3000, 3301, 3588]},
    {income: 18950, amounts: [1545, 2291, 2689, 3004, 3304, 3592]},
    {income: 19000, amounts: [1547, 2294, 2692, 3007, 3308, 3596]},
    {income: 19050, amounts: [1550, 2297, 2695, 3011, 3312, 3600]},
    {income: 19100, amounts: [1552, 2300, 2698, 3014, 3316, 3604]},
    {income: 19150, amounts: [1554, 2303, 2702, 3018, 3319, 3608]},
    {income: 19200, amounts: [1556, 2306, 2705, 3021, 3323, 3612]},
    {income: 19250, amounts: [1558, 2309, 2708, 3025, 3327, 3616]},
    {income: 19300, amounts: [1560, 2312, 2711, 3028, 3331, 3621]},
    {income: 19350, amounts: [1563, 2315, 2714, 3031, 3335, 3625]},
    {income: 19400, amounts: [1565, 2318, 2717, 3035, 3338, 3629]},
    {income: 19450, amounts: [1567, 2320, 2720, 3038, 3342, 3633]},
    {income: 19500, amounts: [1569, 2323, 2723, 3042, 3346, 3637]},
    {income: 19550, amounts: [1571, 2326, 2726, 3045, 3350, 3641]},
    {income: 19600, amounts: [1573, 2329, 2729, 3049, 3353, 3645]},
    {income: 19650, amounts: [1575, 2332, 2732, 3052, 3357, 3649]},
    {income: 19700, amounts: [1578, 2335, 2735, 3055, 3361, 3653]},
    {income: 19750, amounts: [1580, 2338, 2738, 3059, 3365, 3658]},
    {income: 19800, amounts: [1582, 2341, 2742, 3062, 3369, 3662]},
    {income: 19850, amounts: [1584, 2344, 2745, 3066, 3372, 3666]},
    {income: 19900, amounts: [1586, 2347, 2748, 3069, 3376, 3670]},
    {income: 19950, amounts: [1588, 2350, 2751, 3073, 3380, 3674]},
    {income: 20000, amounts: [1591, 2353, 2754, 3076, 3384, 3678]},
    {income: 20050, amounts: [1593, 2355, 2757, 3080, 3387, 3682]},
    {income: 20100, amounts: [1595, 2358, 2760, 3083, 3391, 3686]},
    {income: 20150, amounts: [1597, 2361, 2763, 3086, 3395, 3690]},
    {income: 20200, amounts: [1599, 2364, 2766, 3090, 3399, 3695]},
    {income: 20250, amounts: [1601, 2367, 2769, 3093, 3403, 3699]},
    {income: 20300, amounts: [1603, 2370, 2772, 3097, 3406, 3703]},
    {income: 20350, amounts: [1606, 2373, 2775, 3100, 3410, 3707]},
    {income: 20400, amounts: [1608, 2376, 2778, 3104, 3414, 3711]},
    {income: 20450, amounts: [1610, 2379, 2782, 3107, 3418, 3715]},
    {income: 20500, amounts: [1612, 2382, 2785, 3110, 3421, 3719]},
    {income: 20550, amounts: [1614, 2385, 2788, 3114, 3425, 3723]},
    {income: 20600, amounts: [1616, 2388, 2791, 3117, 3429, 3727]},
    {income: 20650, amounts: [1619, 2390, 2794, 3121, 3433, 3731]},
    {income: 20700, amounts: [1621, 2393, 2797, 3124, 3437, 3736]},
    {income: 20750, amounts: [1623, 2396, 2800, 3128, 3440, 3740]},
    {income: 20800, amounts: [1625, 2399, 2803, 3131, 3444, 3744]},
    {income: 20850, amounts: [1627, 2402, 2806, 3135, 3448, 3748]},
    {income: 20900, amounts: [1629, 2405, 2809, 3138, 3452, 3752]},
    {income: 20950, amounts: [1631, 2408, 2812, 3141, 3456, 3756]},
    {income: 21000, amounts: [1634, 2411, 2815, 3145, 3459, 3760]},
    {income: 21050, amounts: [1636, 2414, 2818, 3148, 3463, 3764]},
    {income: 21100, amounts: [1638, 2417, 2822, 3152, 3467, 3768]},
    {income: 21150, amounts: [1640, 2420, 2825, 3155, 3471, 3773]},
    {income: 21200, amounts: [1642, 2423, 2828, 3159, 3474, 3777]},
    {income: 21250, amounts: [1644, 2425, 2831, 3162, 3478, 3781]},
    {income: 21300, amounts: [1647, 2428, 2834, 3165, 3482, 3785]},
    {income: 21350, amounts: [1649, 2431, 2837, 3169, 3486, 3789]},
    {income: 21400, amounts: [1651, 2434, 2840, 3172, 3490, 3793]},
    {income: 21450, amounts: [1653, 2437, 2843, 3176, 3493, 3797]},
    {income: 21500, amounts: [1655, 2440, 2846, 3179, 3497, 3801]},
    {income: 21550, amounts: [1657, 2443, 2849, 3183, 3501, 3805]},
    {income: 21600, amounts: [1659, 2446, 2853, 3187, 3506, 3811]},
    {income: 21650, amounts: [1661, 2449, 2857, 3191, 3510, 3816]},
    {income: 21700, amounts: [1663, 2452, 2861, 3195, 3515, 3821]},
    {income: 21750, amounts: [1665, 2455, 2865, 3200, 3520, 3826]},
    {income: 21800, amounts: [1667, 2458, 2868, 3204, 3524, 3831]},
    {income: 21850, amounts: [1668, 2461, 2872, 3208, 3529, 3836]},
    {income: 21900, amounts: [1670, 2464, 2876, 3213, 3534, 3841]},
    {income: 21950, amounts: [1672, 2467, 2880, 3217, 3539, 3846]},
    {income: 22000, amounts: [1674, 2470, 2884, 3221, 3543, 3852]},
    {income: 22050, amounts: [1676, 2473, 2888, 3225, 3548, 3857]},
    {income: 22100, amounts: [1678, 2476, 2891, 3230, 3553, 3862]},
    {income: 22150, amounts: [1680, 2479, 2895, 3234, 3557, 3867]},
    {income: 22200, amounts: [1681, 2482, 2899, 3238, 3562, 3872]},
    {income: 22250, amounts: [1683, 2485, 2903, 3243, 3567, 3877]},
    {income: 22300, amounts: [1685, 2488, 2907, 3247, 3571, 3882]},
    {income: 22350, amounts: [1687, 2491, 2911, 3251, 3576, 3887]},
    {income: 22400, amounts: [1689, 2494, 2914, 3255, 3581, 3892]},
    {income: 22450, amounts: [1691, 2497, 2918, 3260, 3586, 3898]},
    {income: 22500, amounts: [1692, 2500, 2922, 3264, 3590, 3903]},
    {income: 22550, amounts: [1694, 2503, 2926, 3268, 3595, 3908]},
    {income: 22600, amounts: [1696, 2506, 2930, 3272, 3600, 3913]},
    {income: 22650, amounts: [1698, 2509, 2934, 3277, 3604, 3918]},
    {income: 22700, amounts: [1700, 2512, 2937, 3281, 3609, 3923]},
    {income: 22750, amounts: [1702, 2515, 2941, 3285, 3614, 3928]},
    {income: 22800, amounts: [1704, 2518, 2945, 3290, 3619, 3933]},
    {income: 22850, amounts: [1705, 2521, 2949, 3294, 3623, 3938]},
    {income: 22900, amounts: [1707, 2524, 2953, 3298, 3628, 3944]},
    {income: 22950, amounts: [1709, 2527, 2957, 3302, 3633, 3949]},
    {income: 23000, amounts: [1711, 2530, 2960, 3307, 3637, 3954]},
    {income: 23050, amounts: [1713, 2533, 2964, 3311, 3642, 3959]},
    {income: 23100, amounts: [1715, 2536, 2968, 3315, 3647, 3964]},
    {income: 23150, amounts: [1717, 2539, 2972, 3320, 3651, 3969]},
    {income: 23200, amounts: [1718, 2542, 2976, 3324, 3656, 3974]},
    {income: 23250, amounts: [1720, 2545, 2979, 3328, 3661, 3979]},
    {income: 23300, amounts: [1722, 2548, 2983, 3332, 3666, 3984]},
    {income: 23350, amounts: [1724, 2551, 2987, 3337, 3670, 3990]},
    {income: 23400, amounts: [1726, 2554, 2991, 3341, 3675, 3995]},
    {income: 23450, amounts: [1728, 2557, 2995, 3345, 3680, 4000]},
    {income: 23500, amounts: [1730, 2560, 2999, 3349, 3684, 4005]},
    {income: 23550, amounts: [1731, 2563, 3002, 3354, 3689, 4010]},
    {income: 23600, amounts: [1733, 2566, 3006, 3358, 3694, 4015]},
    {income: 23650, amounts: [1735, 2569, 3010, 3362, 3699, 4020]},
    {income: 23700, amounts: [1737, 2572, 3014, 3367, 3703, 4025]},
    {income: 23750, amounts: [1739, 2575, 3018, 3371, 3708, 4031]},
    {income: 23800, amounts: [1741, 2578, 3022, 3375, 3713, 4036]},
    {income: 23850, amounts: [1742, 2581, 3025, 3379, 3717, 4041]},
    {income: 23900, amounts: [1744, 2584, 3029, 3384, 3722, 4046]},
    {income: 23950, amounts: [1746, 2587, 3033, 3388, 3727, 4051]},
    {income: 24000, amounts: [1748, 2590, 3037, 3392, 3731, 4056]},
    {income: 24050, amounts: [1750, 2593, 3041, 3397, 3736, 4061]},
    {income: 24100, amounts: [1752, 2596, 3045, 3401, 3741, 4066]},
    {income: 24150, amounts: [1754, 2599, 3048, 3405, 3746, 4071]},
    {income: 24200, amounts: [1755, 2602, 3052, 3409, 3750, 4077]},
    {income: 24250, amounts: [1757, 2605, 3056, 3414, 3755, 4082]},
    {income: 24300, amounts: [1759, 2608, 3060, 3418, 3760, 4087]},
    {income: 24350, amounts: [1761, 2611, 3064, 3422, 3764, 4092]},
    {income: 24400, amounts: [1763, 2614, 3068, 3426, 3769, 4097]},
    {income: 24450, amounts: [1765, 2617, 3071, 3431, 3774, 4102]},
    {income: 24500, amounts: [1767, 2620, 3075, 3435, 3779, 4107]},
    {income: 24550, amounts: [1768, 2623, 3079, 3439, 3783, 4112]},
    {income: 24600, amounts: [1770, 2626, 3083, 3444, 3788, 4117]},
    {income: 24650, amounts: [1772, 2629, 3087, 3448, 3793, 4123]},
    {income: 24700, amounts: [1774, 2632, 3091, 3452, 3797, 4128]},
    {income: 24750, amounts: [1776, 2635, 3094, 3456, 3802, 4133]},
    {income: 24800, amounts: [1778, 2638, 3098, 3461, 3807, 4138]},
    {income: 24850, amounts: [1780, 2641, 3102, 3465, 3811, 4143]},
    {income: 24900, amounts: [1781, 2644, 3106, 3469, 3816, 4148]},
    {income: 24950, amounts: [1783, 2647, 3110, 3474, 3821, 4153]},
    {income: 25000, amounts: [1785, 2650, 3114, 3478, 3826, 4158]},
    {income: 25050, amounts: [1787, 2653, 3117, 3482, 3830, 4163]},
    {income: 25100, amounts: [1789, 2656, 3121, 3486, 3835, 4169]},
    {income: 25150, amounts: [1791, 2659, 3125, 3491, 3840, 4174]},
    {income: 25200, amounts: [1792, 2662, 3129, 3495, 3844, 4179]},
    {income: 25250, amounts: [1794, 2665, 3133, 3499, 3849, 4184]},
    {income: 25300, amounts: [1796, 2668, 3136, 3503, 3854, 4189]},
    {income: 25350, amounts: [1798, 2671, 3140, 3508, 3858, 4194]},
    {income: 25400, amounts: [1800, 2674, 3144, 3512, 3863, 4199]},
    {income: 25450, amounts: [1802, 2677, 3148, 3516, 3868, 4204]},
    {income: 25500, amounts: [1804, 2680, 3152, 3521, 3873, 4210]},
    {income: 25550, amounts: [1805, 2682, 3156, 3525, 3877, 4215]},
    {income: 25600, amounts: [1807, 2685, 3159, 3529, 3882, 4220]},
    {income: 25650, amounts: [1809, 2688, 3163, 3533, 3887, 4225]},
    {income: 25700, amounts: [1811, 2691, 3167, 3538, 3891, 4230]},
    {income: 25750, amounts: [1813, 2694, 3171, 3542, 3896, 4235]},
    {income: 25800, amounts: [1815, 2697, 3175, 3546, 3901, 4240]},
    {income: 25850, amounts: [1817, 2700, 3179, 3550, 3906, 4245]},
    {income: 25900, amounts: [1818, 2703, 3182, 3555, 3910, 4250]},
    {income: 25950, amounts: [1820, 2706, 3186, 3559, 3915, 4256]},
    {income: 26000, amounts: [1822, 2709, 3190, 3563, 3920, 4261]},
    {income: 26050, amounts: [1824, 2712, 3194, 3568, 3924, 4266]},
    {income: 26100, amounts: [1826, 2715, 3198, 3572, 3929, 4271]},
    {income: 26150, amounts: [1828, 2718, 3202, 3576, 3934, 4276]},
    {income: 26200, amounts: [1830, 2721, 3205, 3580, 3938, 4281]},
    {income: 26250, amounts: [1831, 2724, 3209, 3585, 3943, 4286]},
    {income: 26300, amounts: [1833, 2727, 3213, 3589, 3948, 4291]},
    {income: 26350, amounts: [1835, 2730, 3217, 3593, 3953, 4296]},
    {income: 26400, amounts: [1837, 2733, 3221, 3598, 3957, 4302]},
    {income: 26450, amounts: [1839, 2736, 3225, 3602, 3962, 4307]},
    {income: 26500, amounts: [1841, 2739, 3228, 3606, 3967, 4312]},
    {income: 26550, amounts: [1842, 2742, 3232, 3610, 3971, 4317]},
    {income: 26600, amounts: [1844, 2745, 3236, 3615, 3976, 4322]},
    {income: 26650, amounts: [1846, 2748, 3240, 3619, 3981, 4327]},
    {income: 26700, amounts: [1848, 2751, 3244, 3623, 3986, 4332]},
    {income: 26750, amounts: [1850, 2754, 3248, 3627, 3990, 4337]},
    {income: 26800, amounts: [1852, 2757, 3251, 3632, 3995, 4342]},
    {income: 26850, amounts: [1854, 2760, 3255, 3636, 4000, 4348]},
    {income: 26900, amounts: [1855, 2763, 3259, 3640, 4004, 4353]},
    {income: 26950, amounts: [1857, 2766, 3263, 3645, 4009, 4358]},
    {income: 27000, amounts: [1859, 2769, 3267, 3649, 4014, 4363]},
    {income: 27050, amounts: [1861, 2772, 3270, 3653, 4018, 4368]},
    {income: 27100, amounts: [1863, 2775, 3274, 3657, 4023, 4373]},
    {income: 27150, amounts: [1865, 2778, 3278, 3662, 4028, 4378]},
    {income: 27200, amounts: [1867, 2781, 3282, 3666, 4033, 4383]},
    {income: 27250, amounts: [1868, 2784, 3286, 3670, 4037, 4389]},
    {income: 27300, amounts: [1870, 2787, 3290, 3675, 4042, 4394]},
    {income: 27350, amounts: [1872, 2790, 3293, 3679, 4047, 4399]},
    {income: 27400, amounts: [1874, 2793, 3297, 3683, 4051, 4404]},
    {income: 27450, amounts: [1876, 2796, 3301, 3687, 4056, 4409]},
    {income: 27500, amounts: [1878, 2799, 3305, 3692, 4061, 4414]},
    {income: 27550, amounts: [1880, 2802, 3309, 3696, 4066, 4419]},
    {income: 27600, amounts: [1881, 2805, 3313, 3700, 4070, 4424]},
    {income: 27650, amounts: [1883, 2808, 3316, 3704, 4075, 4429]},
    {income: 27700, amounts: [1885, 2811, 3320, 3709, 4080, 4435]},
    {income: 27750, amounts: [1887, 2814, 3324, 3713, 4084, 4440]},
    {income: 27800, amounts: [1889, 2817, 3328, 3717, 4089, 4445]},
    {income: 27850, amounts: [1891, 2820, 3332, 3722, 4094, 4450]},
    {income: 27900, amounts: [1892, 2823, 3336, 3726, 4098, 4455]},
    {income: 27950, amounts: [1894, 2826, 3339, 3730, 4103, 4460]},
    {income: 28000, amounts: [1896, 2829, 3343, 3734, 4108, 4465]},
    {income: 28050, amounts: [1898, 2832, 3347, 3739, 4113, 4470]},
    {income: 28100, amounts: [1899, 2833, 3348, 3740, 4114, 4472]},
    {income: 28150, amounts: [1900, 2834, 3349, 3741, 4115, 4473]},
    {income: 28200, amounts: [1900, 2835, 3349, 3741, 4115, 4473]},
    {income: 28250, amounts: [1901, 2836, 3350, 3742, 4116, 4474]},
    {income: 28300, amounts: [1902, 2836, 3350, 3742, 4116, 4474]},
    {income: 28350, amounts: [1902, 2837, 3351, 3743, 4117, 4475]},
    {income: 28400, amounts: [1903, 2838, 3351, 3743, 4117, 4476]},
    {income: 28450, amounts: [1904, 2838, 3351, 3744, 4118, 4476]},
    {income: 28500, amounts: [1904, 2839, 3352, 3744, 4118, 4477]},
    {income: 28550, amounts: [1905, 2840, 3352, 3745, 4119, 4477]},
    {income: 28600, amounts: [1906, 2840, 3353, 3745, 4120, 4478]},
    {income: 28650, amounts: [1906, 2841, 3353, 3745, 4120, 4478]},
    {income: 28700, amounts: [1907, 2842, 3354, 3746, 4121, 4479]},
    {income: 28750, amounts: [1908, 2842, 3354, 3746, 4121, 4480]},
    {income: 28800, amounts: [1908, 2843, 3354, 3747, 4122, 4480]},
    {income: 28850, amounts: [1909, 2844, 3355, 3747, 4122, 4481]},
    {income: 28900, amounts: [1909, 2844, 3355, 3748, 4123, 4481]},
    {income: 28950, amounts: [1910, 2845, 3356, 3748, 4123, 4482]},
    {income: 29000, amounts: [1911, 2846, 3356, 3749, 4124, 4483]},
    {income: 29050, amounts: [1911, 2846, 3357, 3749, 4124, 4483]},
    {income: 29100, amounts: [1912, 2847, 3357, 3750, 4125, 4484]},
    {income: 29150, amounts: [1913, 2848, 3358, 3750, 4125, 4484]},
    {income: 29200, amounts: [1913, 2848, 3358, 3751, 4126, 4485]},
    {income: 29250, amounts: [1914, 2849, 3358, 3751, 4126, 4485]},
    {income: 29300, amounts: [1915, 2850, 3359, 3752, 4127, 4486]},
    {income: 29350, amounts: [1915, 2850, 3359, 3752, 4128, 4487]},
    {income: 29400, amounts: [1916, 2851, 3360, 3753, 4128, 4487]},
    {income: 29450, amounts: [1917, 2852, 3360, 3753, 4129, 4488]},
    {income: 29500, amounts: [1917, 2852, 3361, 3754, 4129, 4488]},
    {income: 29550, amounts: [1918, 2853, 3361, 3754, 4130, 4489]},
    {income: 29600, amounts: [1919, 2854, 3361, 3755, 4130, 4490]},
    {income: 29650, amounts: [1919, 2855, 3362, 3755, 4131, 4490]},
    {income: 29700, amounts: [1920, 2855, 3362, 3756, 4131, 4491]},
    {income: 29750, amounts: [1921, 2856, 3363, 3756, 4132, 4491]},
    {income: 29800, amounts: [1921, 2857, 3363, 3757, 4132, 4492]},
    {income: 29850, amounts: [1922, 2857, 3364, 3757, 4133, 4492]},
    {income: 29900, amounts: [1923, 2858, 3364, 3758, 4133, 4493]},
    {income: 29950, amounts: [1923, 2859, 3365, 3758, 4134, 4494]},
    {income: 30000, amounts: [1924, 2859, 3365, 3759, 4135, 4494]},
    {income: 30050, amounts: [1925, 2860, 3365, 3759, 4135, 4495]},
    {income: 30100, amounts: [1925, 2861, 3366, 3760, 4136, 4495]},
    {income: 30150, amounts: [1926, 2861, 3366, 3760, 4136, 4496]},
    {income: 30200, amounts: [1926, 2862, 3367, 3761, 4137, 4497]},
    {income: 30250, amounts: [1927, 2863, 3367, 3761, 4137, 4497]},
    {income: 30300, amounts: [1928, 2863, 3368, 3762, 4138, 4498]},
    {income: 30350, amounts: [1928, 2864, 3368, 3762, 4138, 4498]},
    {income: 30400, amounts: [1929, 2865, 3368, 3763, 4139, 4499]},
    {income: 30450, amounts: [1930, 2865, 3369, 3763, 4139, 4499]},
    {income: 30500, amounts: [1930, 2866, 3369, 3764, 4140, 4500]},
    {income: 30550, amounts: [1931, 2867, 3370, 3764, 4140, 4501]},
    {income: 30600, amounts: [1932, 2867, 3370, 3765, 4141, 4501]},
    {income: 30650, amounts: [1932, 2868, 3371, 3765, 4141, 4502]},
    {income: 30700, amounts: [1933, 2869, 3371, 3765, 4142, 4502]},
    {income: 30750, amounts: [1934, 2869, 3371, 3766, 4143, 4503]},
    {income: 30800, amounts: [1934, 2870, 3372, 3766, 4143, 4504]},
    {income: 30850, amounts: [1935, 2871, 3372, 3767, 4144, 4504]},
    {income: 30900, amounts: [1936, 2871, 3373, 3767, 4144, 4505]},
    {income: 30950, amounts: [1936, 2872, 3373, 3768, 4145, 4505]},
    {income: 31000, amounts: [1937, 2873, 3374, 3768, 4145, 4506]},
    {income: 31050, amounts: [1938, 2874, 3374, 3769, 4146, 4506]},
    {income: 31100, amounts: [1938, 2874, 3375, 3769, 4146, 4507]},
    {income: 31150, amounts: [1939, 2875, 3375, 3770, 4147, 4508]},
    {income: 31200, amounts: [1940, 2876, 3375, 3770, 4147, 4508]},
    {income: 31250, amounts: [1940, 2876, 3376, 3771, 4148, 4509]},
    {income: 31300, amounts: [1941, 2877, 3376, 3771, 4148, 4509]},
    {income: 31350, amounts: [1942, 2878, 3377, 3772, 4149, 4510]},
    {income: 31400, amounts: [1942, 2878, 3377, 3772, 4150, 4511]},
    {income: 31450, amounts: [1943, 2879, 3378, 3773, 4150, 4511]},
    {income: 31500, amounts: [1943, 2880, 3378, 3773, 4151, 4512]},
    {income: 31550, amounts: [1944, 2880, 3378, 3774, 4151, 4512]},
    {income: 31600, amounts: [1945, 2881, 3379, 3774, 4152, 4513]},
    {income: 31650, amounts: [1945, 2882, 3379, 3775, 4152, 4513]},
    {income: 31700, amounts: [1946, 2882, 3380, 3775, 4153, 4514]},
    {income: 31750, amounts: [1947, 2883, 3380, 3776, 4153, 4515]},
    {income: 31800, amounts: [1947, 2884, 3381, 3776, 4154, 4515]},
    {income: 31850, amounts: [1948, 2884, 3381, 3777, 4154, 4516]},
    {income: 31900, amounts: [1949, 2885, 3382, 3777, 4155, 4516]},
    {income: 31950, amounts: [1949, 2886, 3382, 3778, 4155, 4517]},
    {income: 32000, amounts: [1950, 2886, 3382, 3778, 4156, 4518]},
    {income: 32050, amounts: [1951, 2887, 3383, 3779, 4156, 4518]},
    {income: 32100, amounts: [1951, 2888, 3383, 3779, 4157, 4519]},
    {income: 32150, amounts: [1952, 2888, 3384, 3780, 4158, 4519]},
    {income: 32200, amounts: [1953, 2889, 3384, 3780, 4158, 4520]},
    {income: 32250, amounts: [1953, 2890, 3385, 3781, 4159, 4520]},
    {income: 32300, amounts: [1954, 2890, 3385, 3781, 4159, 4521]},
    {income: 32350, amounts: [1955, 2891, 3385, 3782, 4160, 4522]},
    {income: 32400, amounts: [1955, 2892, 3386, 3782, 4160, 4522]},
    {income: 32450, amounts: [1956, 2893, 3386, 3783, 4161, 4523]},
    {income: 32500, amounts: [1957, 2893, 3387, 3783, 4161, 4523]},
    {income: 32550, amounts: [1957, 2894, 3387, 3784, 4162, 4524]},
    {income: 32600, amounts: [1958, 2895, 3388, 3784, 4162, 4525]},
    {income: 32650, amounts: [1959, 2895, 3388, 3784, 4163, 4525]},
    {income: 32700, amounts: [1959, 2896, 3389, 3785, 4163, 4526]},
    {income: 32750, amounts: [1960, 2897, 3389, 3785, 4164, 4526]},
    {income: 32800, amounts: [1960, 2897, 3389, 3786, 4165, 4527]},
    {income: 32850, amounts: [1961, 2898, 3390, 3786, 4165, 4527]},
    {income: 32900, amounts: [1962, 2899, 3390, 3787, 4166, 4528]},
    {income: 32950, amounts: [1962, 2899, 3391, 3787, 4166, 4529]},
    {income: 33000, amounts: [1963, 2900, 3391, 3788, 4167, 4529]},
    {income: 33050, amounts: [1964, 2901, 3392, 3788, 4167, 4530]},
    {income: 33100, amounts: [1964, 2901, 3392, 3789, 4168, 4530]},
    {income: 33150, amounts: [1965, 2902, 3392, 3789, 4168, 4531]},
    {income: 33200, amounts: [1966, 2903, 3393, 3790, 4169, 4532]},
    {income: 33250, amounts: [1966, 2903, 3393, 3790, 4169, 4532]},
    {income: 33300, amounts: [1967, 2904, 3394, 3791, 4170, 4533]},
    {income: 33350, amounts: [1968, 2905, 3394, 3791, 4170, 4533]},
    {income: 33400, amounts: [1968, 2905, 3395, 3792, 4171, 4534]},
    {income: 33450, amounts: [1969, 2906, 3395, 3792, 4172, 4534]},
    {income: 33500, amounts: [1970, 2907, 3395, 3793, 4172, 4535]},
    {income: 33550, amounts: [1970, 2907, 3396, 3793, 4173, 4536]},
    {income: 33600, amounts: [1971, 2908, 3396, 3794, 4173, 4536]},
    {income: 33650, amounts: [1972, 2909, 3397, 3794, 4174, 4537]},
    {income: 33700, amounts: [1972, 2909, 3397, 3795, 4174, 4537]},
    {income: 33750, amounts: [1973, 2910, 3398, 3795, 4175, 4538]},
    {income: 33800, amounts: [1974, 2911, 3398, 3796, 4175, 4539]},
    {income: 33850, amounts: [1974, 2912, 3399, 3796, 4176, 4539]},
    {income: 33900, amounts: [1975, 2912, 3399, 3797, 4176, 4540]},
    {income: 33950, amounts: [1976, 2913, 3399, 3797, 4177, 4540]},
    {income: 34000, amounts: [1976, 2914, 3400, 3798, 4177, 4541]},
    {income: 34050, amounts: [1977, 2914, 3400, 3798, 4178, 4541]},
    {income: 34100, amounts: [1977, 2915, 3401, 3799, 4178, 4542]},
    {income: 34150, amounts: [1978, 2916, 3401, 3799, 4179, 4543]},
    {income: 34200, amounts: [1979, 2916, 3402, 3800, 4179, 4543]},
    {income: 34250, amounts: [1979, 2917, 3402, 3800, 4180, 4544]},
    {income: 34300, amounts: [1980, 2917, 3402, 3800, 4181, 4544]},
    {income: 34350, amounts: [1981, 2918, 3403, 3801, 4181, 4545]},
    {income: 34400, amounts: [1981, 2919, 3403, 3801, 4182, 4545]},
    {income: 34450, amounts: [1982, 2919, 3404, 3802, 4182, 4546]},
    {income: 34500, amounts: [1983, 2920, 3404, 3802, 4183, 4546]},
    {income: 34550, amounts: [1983, 2921, 3405, 3803, 4183, 4547]},
    {income: 34600, amounts: [1984, 2921, 3405, 3803, 4184, 4548]},
    {income: 34650, amounts: [1984, 2922, 3405, 3804, 4184, 4548]},
    {income: 34700, amounts: [1985, 2923, 3406, 3804, 4185, 4549]},
    {income: 34750, amounts: [1986, 2923, 3406, 3805, 4185, 4549]},
    {income: 34800, amounts: [1986, 2924, 3407, 3805, 4186, 4550]},
    {income: 34850, amounts: [1987, 2925, 3407, 3806, 4186, 4550]},
    {income: 34900, amounts: [1988, 2925, 3407, 3806, 4187, 4551]},
    {income: 34950, amounts: [1988, 2926, 3408, 3807, 4187, 4552]},
    {income: 35000, amounts: [1989, 2927, 3408, 3807, 4188, 4552]},
    ];

export {calculateSupport};