import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormData, useFormDataDispatch } from '../hooks/FormDataProvider';
import { SmartTextField } from '../components/SmartTextField';


export const SpousalSupportForm = () => {

  const dispatch = useFormDataDispatch();
  const formData = useFormData();

  const partyAHeader = formData.getValue('partyNameA') + " : Spousal Support";
  const partyBHeader = formData.getValue('partyNameB') + " : Spousal Support";
  const spousalSupportAPaymentLabel = "Spousal Support Payment to " + formData.getValue('partyNameA');
  const spousalSupportBPaymentLabel = "Spousal Support Payment to " + formData.getValue('partyNameB');

  /**
   * 
   * @param {*} e 
   */
  const handleChange = (e) => {
      
      dispatch({
        type: 'updated',
        updatedFields: {
          [e.target.name] : e.target.value
        }
      });
    }
    
    /**
     * Clear any no longer visible fields so we don't run into validation issues on unused fields
     * Assuming this is only called for spousalSupportA and spousalSupportB
     * @param {*} e 
     */
    const handlePayingChange = (e) => {
      
      const { name, value } = e.target;

      let updatedFields = {};
  
      // If 'spousalSupportA' is no longer 'yes-b' or 'yes-b+others', set 'spousalSupportPaymentAtoB' to 0
      if (name === 'spousalSupportA' && value !== 'yes-b' && value !== 'yes-b+others') {
        updatedFields.spousalSupportPaymentAtoB = '0';
      }
  
      // If 'spousalSupportA' is no longer 'yes-others' or 'yes-b+others', set 'spousalSupportPaymentAtoOthers' to 0
      if (name === 'spousalSupportA' && value !== 'yes-others' && value !== 'yes-b+others') {
        updatedFields.spousalSupportPaymentAtoOthers = '0';
      }
  
      // If 'spousalSupportB' is no longer 'yes-a' or 'yes-a+others', set 'spousalSupportPaymentBtoA' to 0
      if (name === 'spousalSupportB' && value !== 'yes-a' && value !== 'yes-a+others') {
        updatedFields.spousalSupportPaymentBtoA = '0';
      }
  
      // If 'spousalSupportB' is no longer 'yes-others' or 'yes-a+others', set 'spousalSupportPaymentBtoOthers' to 0
      if (name === 'spousalSupportB' && value !== 'yes-others' && value !== 'yes-a+others') {
        updatedFields.spousalSupportPaymentBtoOthers = '0';
      }
  
      dispatch({
        type: 'updated',
        updatedFields: {
          ...updatedFields,
          [name]: value
        }
      });

    }

    return (
      <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
          <Stack spacing={2} m={0}>
            <Typography variant="header">{partyAHeader}</Typography>
            <FormControl>
                  <InputLabel >Paying Spousal Support</InputLabel>
                  <Select                      
                      name="spousalSupportA"
                      label="Paying Spousal Support"
                      value={formData.getValue('spousalSupportA')}
                      onChange={handlePayingChange}
                      sx={{ 
                        width: '100%',
                        maxWidth: 350
                        }}                     
                  >
                    <MenuItem value="no">No</MenuItem>
                    {(formData.getValue('spousalSupportB') === 'no' || formData.getValue('spousalSupportB') === 'yes-others') 
                    && 
                    <MenuItem value="yes-b">Yes, to {formData.getValue('partyNameB')}</MenuItem>}
                    <MenuItem value="yes-others">Yes, to Other Parties</MenuItem>
                    {(formData.getValue('spousalSupportB') === 'no' || formData.getValue('spousalSupportB') === 'yes-others') 
                    && 
                    <MenuItem value="yes-b+others">Yes, to {formData.getValue('partyNameB')} and Other Parties</MenuItem>}
                  </Select>
              </FormControl>  
              {(formData.getValue('spousalSupportA') === 'yes-b' || formData.getValue('spousalSupportA') === 'yes-b+others')              
               && 
               <SmartTextField label={spousalSupportBPaymentLabel} field={formData.getField('spousalSupportPaymentAtoB')} /> 
               }
              {(formData.getValue('spousalSupportA') === 'yes-others' || formData.getValue('spousalSupportA') === 'yes-b+others')
               && 
               <SmartTextField label="Spousal Support Payment to Other Parties" field={formData.getField('spousalSupportPaymentAtoOthers')} /> 
              }                         
          </Stack>
        </Grid>
        <Grid xs={12} sm={6}>
        <Stack spacing={2} m={0}>
            <Typography variant="header">{partyBHeader}</Typography>
            <FormControl>
                  <InputLabel>Paying Spousal Support</InputLabel>
                  <Select                              
                      name="spousalSupportB"
                      label="Paying Spousal Support"
                      value={formData.getValue('spousalSupportB')}
                      onChange={handlePayingChange}
                      sx={{ 
                        width: '100%',
                        maxWidth: 350
                        }}  
                  >
                    <MenuItem value="no">No</MenuItem>
                    {(formData.getValue('spousalSupportA') === 'no' || formData.getValue('spousalSupportA') === 'yes-others') 
                    && <MenuItem value="yes-a">Yes, to {formData.getValue('partyNameA')}</MenuItem>}
                    <MenuItem value="yes-others">Yes, to Other Parties</MenuItem>
                    {(formData.getValue('spousalSupportA') === 'no' || formData.getValue('spousalSupportA') === 'yes-others') 
                    && <MenuItem value="yes-a+others">Yes, to {formData.getValue('partyNameA')} and Other Parties</MenuItem>}
                  </Select>
              </FormControl>  
              {(formData.getValue('spousalSupportB') === 'yes-a' || formData.getValue('spousalSupportB') === 'yes-a+others')
               && <SmartTextField label={spousalSupportAPaymentLabel} field={formData.getField('spousalSupportPaymentBtoA')} />
               }
              {(formData.getValue('spousalSupportB') === 'yes-others' || formData.getValue('spousalSupportB') === 'yes-a+others')
               && <SmartTextField label="Spousal Support Payment to Other Parties" field={formData.getField('spousalSupportPaymentBtoOthers')} />
              }              
          </Stack>
        </Grid>      

      </Grid>
    )}