import Grid from '@mui/material/Unstable_Grid2'; 
import { Divider, Typography } from '@mui/material';
import { SheetRowSole } from '../components/csg/SheetRowSole';
import { useFormData } from '../hooks/FormDataProvider.js';
import { computeValues } from '../helpers/FormComputations.js';
import { convertValue } from '../helpers/Utilities';

export const FinalFormSplit = () => {

    const formData = useFormData();
    const now = new Date();
    const date = now.getMonth()+1 + '/' + now.getDate() + '/' +  now.getFullYear();


    const computedValues = computeValues(formData);


    const partyALabel = "Party A - " + formData.getValue('partyNameA');
    const partyBLabel = "Party B - " + formData.getValue('partyNameB');

    let totalAdjustedSupportPayableLabel = (computedValues.totalAdjustedSupportPayableTo === 'a') ? partyALabel : partyBLabel;

    return (
    <Grid container spacing={2}>
        <Grid xs={8}>            
            <Typography variant="h4">Child Support Guidelines Worksheet - Split Custody</Typography>
            <Typography variant="subtitle1">Commonwealth of Virginia Va. Code § 20-108.2</Typography>            
        </Grid>
        <Grid xs={4} sx={{ textAlign: 'right' }}>
            <Typography variant="h6">Case No. {formData.getValue('caseNumber')}</Typography>            
        </Grid>
        <Grid xs={9} smOffset={0}>
            <Typography variant="h6" align="left">{formData.getValue('partyNameA')} v. {formData.getValue('partyNameB')}</Typography>
        </Grid>
        <Grid xs={3} sx={{ textAlign: 'right' }}>
            <Typography variant="h6">Date: {date}</Typography>
        </Grid>

        <Grid xs={3} smOffset={6}>
            <Typography variant="header">{formData.getValue('partyNameA')}</Typography>
        </Grid>
        <Grid xs={3}>
            <Typography variant="header">{formData.getValue('partyNameB')}</Typography>
        </Grid>
        <Grid xs={12} smOffset={0}>
            <Divider />            
        </Grid>

        <SheetRowSole label="1.  Monthly Gross Income"
                format="$"
                valueA={computedValues.grossIncomeA.toFormat()} 
                valueB={computedValues.grossIncomeB.toFormat()}     
                />
        <SheetRowSole label="2.  Adjustments for spousal support payments"
                format="$"
                valueA={computedValues.spousalSupportAmountA.toFormat()} 
                valueB={computedValues.spousalSupportAmountB.toFormat()}    
                />

        <SheetRowSole label="3. Adjustments for support of child(ren)"
                format="$"
                valueA={computedValues.totalOrderedSupportAmountA.toFormat()} 
                valueB={computedValues.totalOrderedSupportAmountB.toFormat()}    
                />       
 
        <SheetRowSole label="4. Deductions from Monthly Gross Income Allowable by law"
                format="$"
                valueA={computedValues.deductionsAllowedA.toFormat()} 
                valueB={computedValues.deductionsAllowedB.toFormat()}     
                />
        
        <SheetRowSole label="5. a. Available Monthly Income"
                format="$"
                valueA={computedValues.calculatedIncomeA.toFormat()} 
                valueB={computedValues.calculatedIncomeB.toFormat()}  
        />
        <Grid xs={6} smOffset={0}>
            <Typography variant="input">&nbsp;&nbsp;&nbsp;&nbsp;b. Combined Monthly Available Income</Typography>
        </Grid>
        <Grid xs={2} smOffset={1.5}>
            <Typography variant="value">{computedValues.totalIncome.toFormat()}</Typography>
        </Grid>
        <SheetRowSole label="6. Percentage of Combined Gross Income"
                numA={12} numB={13} format="%"
                valueA={computedValues.percentIncomeA}
                valueB={computedValues.percentIncomeB}
                valueC={100}
                />
      <SheetRowSole label="7. Number of children for which that parent is the noncustodial parent"                
                valueA={formData.getValue('numKidsA')} 
                valueB={formData.getValue('numKidsB')}  
        />

        <Grid xs={12} smOffset={0}>
            <Divider />            
        </Grid>

        <Grid xs={3} xsOffset={6}>
            <Typography><u>Party A</u></Typography>
        </Grid>
        <Grid xs={3}>
            <Typography><u>Party B</u></Typography>
        </Grid>
        <SheetRowSole label="8. a. Monthly basic child support obligation for number of children listed above"      
                format="$"          
                valueA={computedValues.basicChildSupportA.toFormat()} 
                valueB={computedValues.basicChildSupportB.toFormat()}  
        />

        <SheetRowSole label="&nbsp;&nbsp;&nbsp;&nbsp;b. Monthly amount allowable for health care coverage paid by other parent"      
                format="$"          
                valueA={computedValues.healthcareByB.toFormat()} 
                valueB={computedValues.healthcareByA.toFormat()}  
        />

        <SheetRowSole label="&nbsp;&nbsp;&nbsp;&nbsp;c. Monthly amount allowable for employment-related child care expenses paid by other parent"      
                format="$"          
                valueA={computedValues.workRelatedByB.toFormat()} 
                valueB={computedValues.workRelatedByA.toFormat()}  
        />

        <SheetRowSole label="9. Total monthly child support obligation of each parent"      
                format="$"          
                valueA={computedValues.sumChildSupportA.toFormat()} 
                valueB={computedValues.sumChildSupportB.toFormat()}  
        />
        <SheetRowSole label="10. Total monthly child support obligation of each party"      
                format="$"          
                valueA={computedValues.totalChildSupportA.toFormat()} 
                valueB={computedValues.totalChildSupportB.toFormat()}  
        />

        <Grid xs={12} smOffset={0}>
            <Divider />            
        </Grid>

        <Grid xs={3} xsOffset={6}>
            <Typography><u>Party A</u></Typography>
        </Grid>
        <Grid xs={3}>
            <Typography><u>Party B</u></Typography>
        </Grid>
        <Grid xs={12} smOffset={0}>
            <Typography variant="input">11. Adjustments (if any) to Child Support Guidelines Calculation</Typography>
        </Grid>
 
        {computedValues.adjustments.map((adjustment, index) => 
            <SheetRowSole label={String.fromCharCode(index + 97) + ". " + adjustment.description} format="$"
                valueA={adjustment.party==='a' ? adjustment.amount.toFormat(): "$0.00"}
                valueB={adjustment.party==='b' ? adjustment.amount.toFormat(): "$0.00"}
            />
        )}  
        <SheetRowSole label="12. Each party's adjusted obligation to the other party"
                format="$"
                valueA={computedValues.adjustedShareA.toFormat()}
                valueB={computedValues.adjustedShareB.toFormat()}    
                />

        <SheetRowSole label="13. Net Payment"
                format="$"
                valueA={computedValues.netPaymentA.toFormat()}
                valueB={computedValues.netPaymentB.toFormat()}    
                />                
    

        {/* Begin Final match Section */}


        <Grid xs={12} smOffset={0}>
            <Divider />            
        </Grid>

        <Grid xs={6} smOffset={0}>
            <Typography variant="header">Total Adjusted Support payable to {totalAdjustedSupportPayableLabel}</Typography>
        </Grid>
        <Grid xs={2} smOffset={1.5}>
            <Typography variant="value">{computedValues.totalAdjustedSupport.toFormat()} </Typography>
        </Grid>
        
        

    </Grid>
    );
};