/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useState, useEffect, useContext, createContext } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';


import SideDrawer from './SideDrawer';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'drawerOpen',
})(({ theme, drawerOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(drawerOpen && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const InfoCardContext = createContext(null);

export default function RootContainer({topPageTitle}) {


  const [ infoText, setInfoText ] = useState();
  const [ infoTitle, setInfoTitle ] = useState();  

  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.path});
  }, [location]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };  

  return (
    <Box sx={{ display: 'flex'}}>
      <div className='app-bar'>
        <AppBar 
          drawerOpen={drawerOpen} 
          open={drawerOpen} 
          position="fixed"         
          >               
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(drawerOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {topPageTitle}
            </Typography>
          </Toolbar>
          </AppBar>   
        </div>                       
              
      <SideDrawer drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} /> 
      
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>

        <DrawerHeader 
            css={css`
            @media print {
                display: none;
            }
            `}           
        /> 
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <InfoCardContext.Provider value={{ setInfoTitle, setInfoText }}>
            <Outlet />
          </InfoCardContext.Provider>
        </LocalizationProvider>

      </Box>    
    </Box>    
  );
}

export const useInfoCards = () => useContext(InfoCardContext);