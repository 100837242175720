import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useInfoCards } from "../containers/RootContainer";


export const InfoRadioButton = ({name, label, value, options, helpText, onChange, onBlur}) => {
  
    const { setInfoTitle, setInfoText } = useInfoCards();
    const labelID = name + '-label';

    const handleFocus = () => {
      if(helpText && helpText.text)
        setInfoText(helpText.text);
      if (helpText && helpText.title)
        setInfoTitle(helpText.title);  
    };
  
    const handleBlur = (target) => {        
      setInfoText("");
      setInfoTitle("");
      if (onBlur)
        onBlur(target);
    };

    return (
      <FormControl>
        <FormLabel id={labelID}>{label}</FormLabel>
        <RadioGroup
            row            
            name={name}
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur} 
        >
        {
          options.map((option) => <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />)
        }                          
        </RadioGroup>
      </FormControl>

    );
}