import React, { useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2'; 
import { Typography } from "@mui/material"

export const SheetRow = ({label, numA, numB, numC, valueA, valueB, valueC, format}) => {

    const appendValue = (format === '%') ? '%' : '';

    const labelA = (numA ? "(" + numA + ") " : "") + valueA + appendValue;
    const labelB = (numB ? "(" + numB + ") " : "") + valueB + appendValue;
    const labelC = (numC ? "(" + numC + ") " : "") + valueC + appendValue;
    
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
  
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  
    const handleClick = () => {
      // Call your function here
      setIsClicked(true);
    };

    return (
        <>
            <Grid xs={6} smOffset={0}>             
                <Typography variant="input">{label}</Typography>
            </Grid>

            <Grid 
                xs={2}
                sx={{        
                        backgroundColor: isHovered ? "#f5f5f5" : "white",
                        transition: "background-color 0.3s",                        
                        cursor: "pointer",
                        borderRadius: 2
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                >
                {valueA != null  ? <Typography variant="value">{labelA}</Typography> : null}
            </Grid>

            <Grid xs={2}>
                {valueB != null ? <Typography variant="value">{labelB}</Typography> : null}                
            </Grid>

            <Grid xs={2}>                
                {valueC != null ? <Typography variant="value">{labelC}</Typography> : null}                                
            </Grid>      

        </>
    )
}