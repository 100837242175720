import Stack from '@mui/material/Stack';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useFormData, useFormDataDispatch } from '../hooks/FormDataProvider';
import { FormHelpText } from '../helpers/FormHelpText';
import { InfoRadioButton } from '../components/InfoRadioButton';
import { SmartTextField } from '../components/SmartTextField';

export const IncomeForm = () => {

    const dispatch = useFormDataDispatch();
    const formData = useFormData();

    const incomeLabelA = formData.getValue('partyNameA') + " Gross Monthly Income";
    const incomeLabelB = formData.getValue('partyNameB') + " Gross Monthly Income"; 
    const businessExpenseLabelA = formData.getValue('partyNameA') + " Reasonable Business Expenses";
    const businessExpenseLabelB = formData.getValue('partyNameB') + " Reasonable Business Expenses";

    const partyOptions = [
        {value: "a", label:formData.getValue('partyNameA')},
        {value: "b", label:formData.getValue('partyNameB')},
    ];
    const ssOptions = [
        {value: "no", label: "No"},
        {value: "yes", label: "Yes"},
    ];

    /**
     * 
     * @param {*} e 
     */
    const handleChange = (e) => {
        
        const { name, value } = e.target;
        let updatedFields = {};

        if (name === 'ssDisability' && value === 'no') {
            updatedFields.derivativeBenefit = 0;
        } 

        dispatch({
            type: 'updated',
            updatedFields: {
              ...updatedFields,
              [name]: value,
            },
          });
      };    

    return (
    <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
            <Stack spacing={2} m={0}>
                <SmartTextField label={incomeLabelA} field={formData.getField('grossIncomeA')} />
                <SmartTextField label={businessExpenseLabelA} field={formData.getField('businessExpensesA')} />
            </Stack>
        </Grid>
        <Grid xs={12} sm={6}>
            <Stack spacing={2} m={0}>
                <SmartTextField label={incomeLabelB} field={formData.getField('grossIncomeB')} />
                <SmartTextField label={businessExpenseLabelB} field={formData.getField('businessExpensesB')} />
            </Stack>
        </Grid>
        <Grid xs={12}>        
            <Stack spacing={2} m={0}>
            <Divider />
            <InfoRadioButton 
                    label="Do children receive derivative benefit from SS disability" 
                    name="ssDisability"
                    value={formData.getValue('ssDisability')}
                    onChange={handleChange}
                    options={ssOptions}                    
            />

            {formData.getValue('ssDisability') === 'yes' ?
            <>
            <InfoRadioButton 
                    label="Party with Disability" 
                    name="disabledParty"
                    value={formData.getValue('disabledParty')}
                    onChange={handleChange}
                    options={partyOptions}
                    helpText={FormHelpText.derivativeBenefitsHelpText}                                  
            />
            <InfoRadioButton 
                    label="Party Receiving Derivative Benefit Payments"
                    name="derivativeBenefitParty"
                    value={formData.getValue('derivativeBenefitParty')}
                    onChange={handleChange}
                    options={partyOptions}
                    helpText={FormHelpText.derivativeBenefitsHelpText}
            />
            <SmartTextField label="Amount of derivative benefit" field={formData.getField('derivativeBenefit')} />
            </> 
            : null}

            </Stack>
        </Grid>
    </Grid>
    )
}