import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useInfoCards } from "../containers/RootContainer";
import FormItem from "../helpers/FormItem";
import { useFormDataDispatch } from "../hooks/FormDataProvider";


export const SmartTextField = ({field, label, onBlur}) => {
    const dispatch = useFormDataDispatch();

    const adornments = (field.type === 'currency') ? {startAdornment: <InputAdornment position="start">$</InputAdornment>} : {};    
  
    const { setInfoTitle, setInfoText } = useInfoCards();
    const [ fieldStatus, setFieldStatus ] = useState({valid:field.valid, message:field.errorMessage});

    /**
     * 
     * @param {*} e 
     */
    const handleChange = (e) => {      
        dispatch({
          type: 'updated',
          updatedFields: {
            [e.target.name] : e.target.value,
          },
        });

        if (!fieldStatus.valid) {
            // Must call the static method here, because the field is not yet updated with the latest value
            const [valid, message] = FormItem.validateValue(field.type, e.target.value);
            setFieldStatus({valid:valid, message:message});      
        }
      };   

    /**
     * 
     */
    const handleFocus = () => {
      if(field.helpText)
        setInfoText(field.helpText);

    };
  
    /**
     * 
     * @param {*} target 
     */
    const handleBlur = (e) => {        
      setInfoText("");
      setInfoTitle("");

      if (e.target.value === "" && (field.type === 'currency') || (field.type === 'int')) {
        field.value = "0";
        dispatch({
          type: 'updated',
          updatedFields: {
            [e.target.name] : field.value,
          },
        });        
      }
        field.validate();
        setFieldStatus({valid:field.valid, message:field.errorMessage});
      

      if (onBlur) {
        onBlur(e);
      }
    };



    return (
      <TextField name={field.name}
          sx={{
            maxWidth: 350, // Set the maximum width for the text input
            width: "100%", // Ensure the text input occupies the available width
          }}                             
          label={label} variant="outlined"                
          value={field.value}
          onChange={handleChange}      
          onFocus={(event) => {
            event.target.select();
            handleFocus();
          }}                 
          onBlur={handleBlur}      
          InputProps={adornments}
          error={!fieldStatus.valid}
          helperText={fieldStatus.message}                
      />
    );
};