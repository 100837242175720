/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { PaidChip } from "./PaidChip";
import { useTheme } from "@emotion/react";
import Checkbox from '@mui/material/Checkbox';
import { formatCurrency } from "../../helpers/Utilities";

export const MonthRow= ({data, index, onMonthSelect, onPayClick}) =>
{
    const theme = useTheme();


    return (
        <Grid container sx={{            
            backgroundColor: index % 2 === 1 ? theme.palette.secondary.light : '#fff', // Alternate the background color            
            py: 0.5, 
            pl: 1,      
        }}>
            
            <Grid xs={0.5} 
                   css={css`
                    @media print {
                        display: none;
                    }
                    `}             
            >
                <Checkbox checked={data.selected} onChange={() => onMonthSelect(data.key)} sx={{ padding: 0, margin: 0 }} />
            </Grid>
            
            <Grid xs={2}>
                <Typography sx={{fontWeight: 500}}>{data.date.format('MMM YYYY')}</Typography>
            </Grid>
            <Grid xs={1.5}
                sx={{
                    '@media print': {
                        width: '17%',
                    }, 
                
                }}
            >
                <Typography sx={{color: data.startAmount.isPositive() ? "#90a955" : theme.palette.payment.none}}>{formatCurrency(data.startAmount).toFormat()}</Typography>
            </Grid>
            <Grid xs={1.5}
                sx={{
                        '@media print': {
                            width: '14%',
                        },                     
                    }}            
            >
                <Typography>{formatCurrency(data.interestDue).toFormat()}</Typography>
            </Grid>
            <Grid xs={1.5}
              sx={{
                    '@media print': {
                        width: '16%',
                    },                 
                }}            
            >                
                <Typography> {formatCurrency(data.due).toFormat()}</Typography>
            </Grid>
            <Grid xs={1.5}
              sx={{
                    '@media print': {
                        width: '16%',
                    },                 
                }}            
            >                            
                <PaidChip data={data} onPayClick={onPayClick} />
            </Grid>
            <Grid xs={2}
              sx={{
                    '@media print': {
                        width: '17%',
                    },                 
                }}            
            >
                <Typography sx={{color: data.endAmount.isPositive() ? "#90a955" : theme.palette.payment.none}}>{formatCurrency(data.endAmount).toFormat()}</Typography>
            </Grid>        
            
        </Grid>        
    )
}