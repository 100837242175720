import { useTheme } from "@emotion/react";
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { convertValue } from "../../helpers/Utilities";

export const PaidChip = ({data, onPayClick}) => {

    const theme = useTheme();
    const zero = convertValue(0);

    const [chipColor, setChipColor] = useState(null);

    /**
     * 
     */
    useEffect(() => {        
        colorChips();             
    }, [data]);
    
    /**
     * If more than the amount is paid, color blue
     * If exact amount is paid, color green
     * If nothing is due, color grey
     * If nothing is paid and something is due, color red
     * If less than the amount is paid, color yellow
     * 
     */
    const colorChips = () => {
        let color = theme.palette.payment.partial;

        const owed = data.startAmount.add(data.interestDue).add(data.due);
        const owedDiff = owed.add(data.paid);
        const dueDiff = data.paid.add(data.due);

        // They paid more than the amount owed OR if they paid and the ending amount is positive        
        if ((dueDiff.greaterThan(zero) || (data.paid.greaterThan(zero) && data.endAmount.isPositive()))){
            color = theme.palette.payment.over;
        }
        // They paid the exact amount due
        else if (dueDiff.isZero() && !data.paid.isZero()) {
            color = theme.palette.payment.full;
        }
        // They paid nothing and something is due
        else if (data.paid.isZero() && owed.isNegative()) {
            color = theme.palette.payment.none;
        }
        // They paid less than the amount due
        else if (dueDiff.isNegative() && owed.isNegative()) {
            color = theme.palette.payment.partial;
        }
        else {
            color = theme.palette.grey[500];
        }


        setChipColor(color);
    }

    return(
        <Chip   
            label={data.paid.toFormat()}                    
            size="small"
            onClick={() => {onPayClick(data)}}
            sx={{marginLeft: 0.5, backgroundColor: chipColor, color: '#fff' }}                    
        />    
    );

}