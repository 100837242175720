/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';

import { Box, Stack } from "@mui/system";
import { Button, InputBase, MenuItem, Select } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import { CSStepper } from "../components/csg/CSStepper";
import { CSFlow } from "../components/csg/CSFlow";
import { StartForm } from "../csg-pages/StartForm";
import { IncomeForm } from "../csg-pages/IncomeForm";
import { OtherChildrenForm } from '../csg-pages/OtherChildrenForm';
import { ObligationForm } from '../csg-pages/ObligationForm';
import { SpousalSupportForm } from '../csg-pages/SpousalSupportForm';
import { AdjustmentsForm } from '../csg-pages/AdjustmentsForm';
import { FinalForm } from '../csg-pages/FinalForm';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';


export const CSContainer = () => {

    const steps = ['Start', 'Income', 'Spousal Support', 'Other Children', 'Obligations', 'Adjustments', 'Final'];
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClick = (e) => {
        const newStep = e.target.value;
        setActiveStep(newStep);
    };



    const [collapsed, setCollapsed] = useState(true);

    const theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.up('md'));

    const MobileInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: '#ddd',
            border: '1px solid #ced4da',
            color: '#111',
            fontSize: 20,
            padding: '14px 26px 10px 12px',
        },        
    }));

    const handleToggleCollapse = () => {
        setCollapsed(!collapsed);
      };   
  

    return(
        <Grid xs={12} sm={12}>
            <Stack spacing={2} m={2}>
                {matchesMobile &&
                <Box      
                    css={css`
                    @media print {
                        display: none;
                    }
                    `}    
                >
                    <CSStepper activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} />
                </Box>
                }
                {!matchesMobile &&
                    <Select
                        css={css`
                        @media print {
                            display: none;
                        }
                        `}                          
                        input={<MobileInput/>}
                        id="page-select"
                        value={activeStep}
                        onChange={handleClick}
                    >
                    {
                        steps.map((label, index) => <MenuItem value={index} key={index}>{label}</MenuItem>)
                    }
                    </Select>
                }

                    <CSFlow currentIndex={activeStep}>                    
                        <StartForm />                                                                
                        <IncomeForm />
                        <SpousalSupportForm />
                        <OtherChildrenForm />
                        <ObligationForm  />
                        <AdjustmentsForm />
                        <FinalForm />                                                                   
                    </CSFlow>                                                          
                                       
                                
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
                    css={css`
                    @media print {
                        display: none;
                    }
                    `}  
                >
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        >
                        <KeyboardArrowLeft />
                        Back                  
                    </Button>
                    <Box sx={{ flex: '1 1 auto', pt:1, display: 'flex', justifyContent: 'center' }}
                        css={css`
                        @media print {
                        display: none;
                        }
                        `}  
                    >
                    {!matchesMobile && `${activeStep + 1} / ${steps.length}`}
                    </Box>
                    <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>
                        Next
                        <KeyboardArrowRight />
                    </Button>                
                </Box>
                
            </Stack>
        </Grid>
    );   
};

