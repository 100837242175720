import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import InvalidDisplay from "../components/InvalidDisplay";
import { useFormData } from "../hooks/FormDataProvider";
import { FinalFormMobile } from "./FinalForm-Mobile";
import { FinalFormShared } from "./FinalForm-Shared";
import { FinalFormSole } from "./FinalForm-Sole";
import { FinalFormSplit } from "./FinalForm-Split";

export const FinalForm = () => {

    const formData = useFormData();
    const invalidFields = formData.validate();
    const theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.up('md'));
    const matchesPrint = useMediaQuery("print");

    const chooseForm = () => {
        switch(formData.getValue('custodyType')) {
            case "shared":
                return (<FinalFormShared />);                
            case "sole":
                return (<FinalFormSole />);                
            case "split":
                return (<FinalFormSplit />);
            default:
                return "Should never see this";                
        }
    }

    return(
        <>
            {(invalidFields.length === 0 && !matchesMobile && !matchesPrint) ? 
            <FinalFormMobile />
            : invalidFields.length === 0 ? 
            chooseForm()
            : (
                <InvalidDisplay fields={invalidFields} />
            )}
        </>
    );
};