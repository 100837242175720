import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Button, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, Radio, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useFormData, useFormDataDispatch } from '../hooks/FormDataProvider';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useState } from 'react';
import FormItem from '../helpers/FormItem';


export const AdjustmentsForm = () => {

  const dispatch = useFormDataDispatch();
  const formData = useFormData();


    const AdjustmentBlock = ({adjustment, removeAdjustment, updateAdjustment}) => {
        
        const [adj, setAdj] = useState(adjustment);
        const [ amountStatus, setAmountStatus ] = useState({valid:true, message:""});

        /**
         * 
         * @param {*} e 
         */
        const handleChange = (e) => {
            const { name, value } = e.target;
            setAdj((prevAdj) => ({
                ...prevAdj,
                [name]: value,
            }));            

            updateAdjustment(adjustment.id, name, value);

            if ((name === 'amount') && (!amountStatus.valid)) {
                // Must call the static method here, because the field is not yet updated with the latest value
                const [valid, message] = FormItem.validateValue('currency', value);
                setAmountStatus({valid:valid, message:message});       
            }            
        }

        /**
         * 
         * @param {*} e 
         */
        const handleBlur = (e) => {
            const [valid, message] = FormItem.validateValue('currency', e.target.value);
            setAmountStatus({valid:valid, message:message});           
        }

        /**
         * 
         */
        const handleRemoveAdjustmentClick = () => {
            removeAdjustment(adjustment.id);
        }

        return(
            <Grid container spacing={2} border="solid #ddd 1px" borderRadius="5px" p="2" mb={2} maxWidth="80ch" >        
                <Grid xs={10}>
                    <FormControl>
                        <FormLabel>Party with Adjustment</FormLabel>
                        <RadioGroup
                            row            
                            name="party"
                            value={adj.party}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="a" control={<Radio />} label={formData.getValue('partyNameA')} />
                            <FormControlLabel value="b" control={<Radio />} label={formData.getValue('partyNameB')} />            
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={2} sx={{ textAlign: 'right' }}>
                    <IconButton aria-label="delete" onClick={handleRemoveAdjustmentClick}>
                        <HighlightOffOutlinedIcon />
                    </IconButton>
                </Grid>
                <Grid xs={12} sm={6}>            
                    <TextField name="description" 
                        label="Description" variant="outlined" 
                        sx={{
                        maxWidth: 350, // Set the maximum width for the text input
                        width: "100%", // Ensure the text input occupies the available width
                        }} 
                        value={adj.description}
                        onChange={handleChange}                    
                    />
                </Grid>
                <Grid xs={12} sm={6}>
  
                    <TextField name="amount" 
                        label="Amount" variant="outlined" 
                        sx={{
                            maxWidth: 350, // Set the maximum width for the text input
                            width: "100%", // Ensure the text input occupies the available width
                        }} 
                        value={adj.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!amountStatus.valid}
                        helperText={amountStatus.message}
                        InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }} 
                    />
                </Grid>                
            </Grid>
        );
    }

    const handleAddAdjustmentClick = () => {
        let clonedAdjustments = [...formData.getValue('adjustments')];        
        clonedAdjustments.push(
            {
                id: crypto.randomUUID(),
                party: 'a',
                description: "",
                amount: 0
            }            
        );

        dispatch({
            type: 'updated',
            updatedFields: {
              'adjustments' : clonedAdjustments
            }
          });               
    }

    const removeAdjustment = (id) => {
        const indexToDelete = formData.getValue('adjustments').findIndex((x)=> x.id === id);
        formData.getValue('adjustments').splice(indexToDelete, 1);
        dispatch({
            type: 'updated',
            updatedFields: {
              'adjustments' : formData.getValue('adjustments')
            },
          });          
    };

    const updateAdjustment = (id, name, value) => {

        dispatch({
            type: 'updatedAdjustment',
            updatedID: id,
            updatedName: name,
            updatedValue: value,
          });         
/*
          if (!fieldStatus.valid) {
            // Must call the static method here, because the field is not yet updated with the latest value
            const [valid, message] = FormItem.validateValue(field.type, e.target.value);
            setFieldStatus({valid:valid, message:message});      
        }          
        */
    };

    return (
        <>
            <Button variant="outlined" 
                startIcon={<AddOutlinedIcon/>}
                sx={{ width: '25ch' }}
                onClick={handleAddAdjustmentClick}
                >Add Adjustment</Button>
            <Stack spacing={0} m={0}>
            {
                formData.getValue('adjustments').map((element) =>                     
                    <AdjustmentBlock key={element.id} adjustment={element}
                        removeAdjustment={removeAdjustment} updateAdjustment={updateAdjustment}/>
                )
            }            
            </Stack>
        </>
 
    )
}