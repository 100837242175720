import { FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import { useInfoCards } from "../containers/RootContainer";

export const InfoSelector = ({name, label, value, options, helpText, onChange, onBlur}) => {
  
    const { setInfoTitle, setInfoText } = useInfoCards();
    const labelID = name + '-label';

    const handleFocus = () => {
      if(helpText && helpText.text)
        setInfoText(helpText.text);
      if (helpText && helpText.title)
        setInfoTitle(helpText.title);  
    };
  
    const handleBlur = (target) => {        
      setInfoText("");
      setInfoTitle("");
      if (onBlur)
        onBlur(target);
    };

    return (
        <FormControl>
        <InputLabel id={labelID}>{label}</InputLabel>
          <Select
            labelId={labelID}
            name={name}
            value={value}
            onChange={onChange}              
            label={label}
            onFocus={handleFocus}
            onBlur={handleBlur} 
            sx={{ width: '25ch' }}
          >
            {
              options.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
            }
        </Select>
      </FormControl>
    );
}