import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormData, useFormDataDispatch } from '../hooks/FormDataProvider';
import { SmartTextField } from '../components/SmartTextField';
import FormItem from '../helpers/FormItem';
import { kidCountList } from '../helpers/Utilities';

export const StartForm = () => {

  const dispatch = useFormDataDispatch();
  const formData = useFormData();

  /**
   * 
   * @param {*} e 
   */
  const handleChange = (e) => {
      dispatch({
        type: 'updated',
        updatedFields: {
          [e.target.name] : e.target.value
        }
      });
    }

    /**
     * When custody type changes, clear un-needed fields and set newly needed fields
     * @param {*} e 
     */
    const handleCustodyTypeChange = (e) => {

      const newCustodyType = e.target.value;

      switch(newCustodyType) {
        case 'sole':
          dispatch({
            type: 'updated',
            updatedFields: {
              custodyType: 'sole',
              custodialParty: 'a',
              numOvernightsA: 120,
              numOvernightsB: 245,
            },
          });        
          break;
        case 'shared':
          
          dispatch({
            type: 'updated',
            updatedFields: {
              custodyType: 'shared',
              custodialParty: null,
            },
          });
          break;
        case 'split':
          const localKids = (formData.getValue('numCurrentMatterChildren') <= 1) ? 2 : formData.getValue('numCurrentMatterChildren');
          const kidsA = Math.floor(localKids / 2);
          const kidsB = localKids - kidsA;          
          dispatch({
            type: 'updated',
            updatedFields: {
              custodyType: 'split',
              custodialParty: null,
              numOvernightsA: 120,
              numOvernightsB: 245,
              numCurrentMatterChildren: localKids,
              numKidsA: kidsA,
              numKidsB: kidsB,              
            },
          });
          break;                  
      }
    }

    /**
     * When custody type is shared, update the number of overnights to ensure the total is 365
     * @param {*} e 
     */
    const handleOvernightBlur = (e) => {
      let numOvernightsA = 0;
      let numOvernightsB = 0;

      const [valid] = FormItem.validateValue('days', e.target.value);
      if (valid) {
        if (e.target.name === 'numOvernightsA') {
          numOvernightsA = e.target.value;
          numOvernightsB = 365 - numOvernightsA;
        }
        else {
          numOvernightsB = e.target.value;
          numOvernightsA = 365 - numOvernightsB;
        }
      
      dispatch({
        type: 'updated',
        updatedFields: {
          numOvernightsA: numOvernightsA,
          numOvernightsB: numOvernightsB,
        },
      });   
      }      
    };    

    /**
     * Render the fields required for the selected custody type
     * @param {*} params 
     * @returns 
     */
    const renderCustodialDetails = (params) => {

      switch(formData.getValue('custodyType')) {       
        case "sole":      
          return(
            <Grid xs={12}>                
              <FormControl>
                  <FormLabel id="custodialParty-label">Custodial Party</FormLabel>
                  <RadioGroup
                      row            
                      name="custodialParty"
                      value={formData.getValue('custodialParty')}
                      onChange={handleChange}
                  >
                      <FormControlLabel value="a" control={<Radio />} label={formData.getValue('partyNameA')} />
                      <FormControlLabel value="b" control={<Radio />} label={formData.getValue('partyNameB')} />            
                  </RadioGroup>
              </FormControl>        
            </Grid>
          );
          break;
          case "shared":
            return(
              <>
                  <Grid xs={12} sm={6}>
                    <SmartTextField label={overnightLabelA} field={formData.getField('numOvernightsA')} 
                      onBlur={handleOvernightBlur}
                    />                       
                  </Grid>  
                  <Grid xs={12} sm={6}>
                  <SmartTextField label={overnightLabelB} field={formData.getField('numOvernightsB')} 
                      onBlur={handleOvernightBlur}
                    />                      
                  </Grid>
                </>
            );
            break;
          case "split":
            return(
              <>
                <Grid xs={12} sm={6}>
                  <FormControl>
                    <InputLabel id="numKidsA-label">Party A Children Count</InputLabel>
                    <Select
                      labelId="numKidsA-label"
                      name="numKidsA"
                      value={formData.getValue('numKidsA')}
                      onChange={handleChange}              
                      label="Party A Children Count"
                      sx={{ width: '25ch' }}
                    >
                {kidCountList
                .filter((item) => item.num <= formData.getValue('numCurrentMatterChildren')-1)
                .map((item, index) => (
                  <MenuItem key={item.word} value={index+1}>{item.word}</MenuItem>
                ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={6}>
                  <FormControl>
                    <InputLabel id="numKidsB-label">Party B Children Count</InputLabel>
                    <Select
                      labelId="numKidsB-label"
                      name="numKidsB"
                      value={formData.getValue('numKidsB')}
                      onChange={handleChange}              
                      label="Party B Children Count"
                      sx={{ width: '25ch' }}
                    >
                      {kidCountList
                      .filter((item) => item.num <= formData.getValue('numCurrentMatterChildren')-1)
                      .map((item, index) => (
                        <MenuItem key={item.word} value={index+1}>{item.word}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>                
              </>
            );
            break;
      }      
    }

  const overnightLabelA = "Number of Overnights for " + formData.getValue('partyNameA');
  const overnightLabelB = "Number of Overnights for " + formData.getValue('partyNameB');


    return (
      <Grid container spacing={2} >        
        <Grid xs={12} sm={6}>
            <SmartTextField label="Client Name" field={formData.getField('partyNameA')} />                           
        </Grid>
        <Grid xs={12} sm={6}>       
          <SmartTextField label="Opposing Party Name" field={formData.getField('partyNameB')} />          
        </Grid>

        <Grid xs={12}>
          <SmartTextField label="Case Number" field={formData.getField('caseNumber')} />          
        </Grid>
        <Grid xs={12}>
            <FormControl>
              <InputLabel id="numCurrentMatterChildren-label">Num Children in Current Matter</InputLabel>
              <Select
                labelId="children-select-label"
                name="numCurrentMatterChildren"
                value={formData.getValue('numCurrentMatterChildren')}
                onChange={handleChange}              
                label="Num Children in Current Matter"
                sx={{ width: '25ch' }}
              >
                {kidCountList.map((item, index) => (
                  <MenuItem key={item.word} value={index+1}>{item.word}</MenuItem>
                ))}
              </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel id="custodyType-label">Custody Type</FormLabel>
            <RadioGroup
              row            
              defaultValue="sole"
              value={formData.getValue('custodyType')}
              name="custodyType"
              onChange={handleCustodyTypeChange}
            >
              <FormControlLabel value="sole" control={<Radio />} label="Sole" />
              <FormControlLabel value="shared" control={<Radio />} label="Shared" />              
              <FormControlLabel value="split" control={<Radio />} label="Split" />              
            </RadioGroup>
          </FormControl>
        </Grid>
        {renderCustodialDetails()}
      </Grid>
    );
};