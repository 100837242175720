import { Typography } from "@mui/material";
import React from "react";

export const FormHelpText = {

     sharedIncomeHelpText : 
     {
     title: "Gross Income",
     text:
     <Typography>
        Gross income is defined by <i>Virginia Code § 20-108.2(C)</i>.<br/>
        <b>a.</b> Gross income “shall mean all income from all sources, and shall include, 
        but not be limited to:
        income from salaries, wages, commissions,royalties, bonuses, dividends, severance pay, 
        pensions, interest, trust income, annuities, capital gains, social security benefits, 
        worker’s compensation benefits, disability insurance benefits, veterans’ benefits, 
        spousal support, rental income, gifts, prizes or awards.
        If a parent’s gross income includes disability insurance benefits, it shall also 
        include any amounts paid to or for the child who is the subject of the order and 
        derived by the child from the parent’s entitlement to disability insurance benefits.<br/>
        <b>b.</b> Gross income “shall not include benefits from public assistance programs as defined 
        in <i>Virginia Code § 63.2-100</i> [Temporary Assistance to Needy Families, auxiliary grants 
        to the aged, blind and disabled, medical assistance, energy assistance, food stamps, 
        employment services, child care, general relief] federal Supplemental Security benefits, 
        child support received, or income received by the payor from secondary employment 
        income not previously included in “gross income,” where the payor obtained the income 
        to discharge a child support arrearage established by a court or administrative 
        order and the payor is paying the arrearage pursuant to the order.”
        
      </Typography>
     },
     
     reasonableBusinessExpensesHelpText :
     {
      title: "Reasonable Business Expenses",
      text: 
      <Typography>
      <i>(Virginia Code § 20-108.2(C))</i> – If either parent has income from self-employment,
      a partnership or a closely-held business, include that here. Include one-half of 
      self-employment tax paid, if applicable. If none, insert '0'.
     </Typography>
     },

     derivativeBenefitsHelpText :
     {
      title: "Derivative Benefits",
      text:
      <Typography>
         If amounts paid to or for the child who is the subject of the order and derived
         by the child from the parent’s entitlement to disability insurance benefits have
          been included in a parent’s gross income, include that here.
      </Typography>
     }

  
     
}