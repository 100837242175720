import { useTheme } from "@emotion/react";
import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import { Box} from "@mui/system";
import { useNavigate, useRouteError } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import {ReactComponent as Logo} from './../assets/senita-logo-right-c.svg';

export default function ErrorPage() {  
  const navigate = useNavigate();
  const theme = useTheme();
  const error = useRouteError();
  
  return (
    <Box       
      > 
    <Grid container spacing={2}>
         
      <Grid sm={12} minHeight='25vh'>
        <SvgIcon component={Logo} inheritViewBox sx={{width: 310, height: 160, pl: 3, pt: 3}} />
      </Grid>
      <Grid sm={3} 
            sx={{
              backgroundColor: theme.palette.primary.light,
              display: { xs: 'none', sm: 'block' }
            }}
          />
      <Grid xs={12} sm={6}
        sx={{
          backgroundColor: theme.palette.primary.light,
          py: 4,          
         }}
      >        
        <Stack spacing={0} m={0} color="black"
          sx={{
            pl: 2
          }}
        >
          <Typography 
              sx={{
                fontSize: {xs: 24, sm: 34, md: 40}              
              }}>
            {error.status}
          </Typography>
          <Typography 
            sx={{
              fontSize: {xs: 28, sm: 40, md: 50}
            }}>
            Oops! This Page Could Not Be Found
          </Typography>
          <Button variant="contained"
            sx={{
              maxWidth: 200
            }}
            onClick={()=>{navigate('/')}}              
            >Go to Homepage</Button>            
        </Stack>
      </Grid>
        <Grid sm={3} 
              sx={{
                backgroundColor: theme.palette.primary.light,
                display: { xs: 'none', sm: 'block' }
              }}

        />        
        <Grid xs={12}/>          
    </Grid>
    </Box>
  );
}