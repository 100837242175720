/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Tooltip } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import ReactGA from 'react-ga4';

import { Delete, EscalatorWarningOutlined, Print } from "@mui/icons-material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupIcon from '@mui/icons-material/Group';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import {ReactComponent as Logo} from './../assets/senita-logo-right-c.svg';

import { useNavigate, useLocation } from 'react-router-dom';
import { useFormData, useFormDataDispatch } from '../hooks/FormDataProvider';
import { useNotifications } from '../hooks/NotificationProvider';

import AlertDialog from '../components/AlertDialog';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
    ({ theme, drawerOpen }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(drawerOpen && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!drawerOpen && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

const SideDrawer = ({drawerOpen, handleDrawerClose}) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPage = location.pathname;
    const formData = useFormData();
    const dispatch = useFormDataDispatch();
    const { addNotification } = useNotifications();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);

    const pages = [
        {label: "Child Support", title: "VA Child Support Guidelines", icon: <EscalatorWarningOutlined />, link: '/csg'},
        {label: "Spousal Support", title: "Spousal Support", icon: <GroupIcon />, link: '/spousal'},
        {label: "Arrearage Calculation", title: "Arrearage Calculation", icon: <CalendarMonthIcon />, link: '/arrearage'},
        
    ];

    //{label: "Asset Debt Charts", title: "Asset Debt Charts", icon: <TableChartIcon />, link: '/assetdebt'},      

    const mapURLToPage = (url) => {
        let page = '';
        switch (url) {
            case '/csg':
                page = "ChildSupportGuidelines";
                break;
            case '/spousal':
                page = "SpousalSupport";
                break;
            case '/arrearage':
                page = "ArrearageCalculations";
                break;
            default:
                page = "ChildSupportGuidelines";
        }
        return page;
    };

    /**
     * Clears form
     */
    const handleNewCase = () => {      
        // Check if form has changed
        // If so, display dialog asking user if they are sure they want to discard changes       
        if (formData.isUpdated(mapURLToPage(currentPage))) {
            handleOpenAlert();
        }  
        else {
            resetForm();
        }
        ReactGA.event({
            category: 'Actions',
            action: 'Reset Form',
            page: currentPage,
        });           
    };

    /**
     * 
     */
    const handleSaveCase = () => {    
        // Need to validate data before this operation occurs to ensure we're not saving invalid data
        const invalidFields = formData.validate();
        
        // Then inform user if there are invalid values
        //handleOpenAlert();
        if (invalidFields.length === 0) {
            // Are we updating or creating new?
            const method = (!formData.savedTime) ? 'POST' : 'PUT';
            const body = JSON.stringify(formData.exportValues());
        }
        else {
            const errorString = invalidFields.map((field) => `${field.displayName}`).join(', ');
            addNotification('Unable to save case because of invalid values: ' + errorString, 'error');
        }  
    };   

    const handlePrint = () => {
        // If we're in CSG app, make sure we're on the final page
        //if (currentPage === '/csg') {
        ReactGA.event({
            category: 'Actions',
            action: 'Print Form',
            page: currentPage,
        });        
        window.print();
    };

    const actions = [
        {label: "Print Form", icon: <Print />, link: handlePrint},
        {label: "Clear Data", icon: <Delete />, link: handleNewCase},
    ];    

    const changePage = (page) => {
        navigate(page.link);       
    };

    const handleActionClick = (action) => {
        action.link();
    };

    /**
     * 
     */
    const resetForm = () => {
        // TODO: Take user back to start page

        dispatch({
            type: 'reset',
            page: mapURLToPage(currentPage),      
        }); 
    };

    /**
     * Opens the case selector dialog
     */
    const handleOpenAlert = () => {
      setOpenAlert(true);      
    };

    /**
     * Called on close of alert dialog
     * @param {*} action 
     */
    const handleAlertClose = (action) => {
       setOpenAlert(false);

        if (action === 'discard') {
            resetForm();
        }
        else {
            handleSaveCase();
        }
    };



    /**
     * Called on close of load dialog
     * If value was returned, user selected a case, so load that data
     * @param {case selected by user, or nothing if they didn't select one} value 
     * 
     */
    const handleCloseDialog = (value) => {
        setOpenDialog(false);
        if (value) {  
            dispatch({
            type: 'loaded',
            formData: value,
            });
        }
    }; 


    return(
        <div className='side-drawer'>
        <Drawer variant="permanent" drawerOpen={drawerOpen}>
            <DrawerHeader>
            <SvgIcon component={Logo} inheritViewBox sx={{width: 130, height: 60, cursor: 'pointer'}} 
                onClick={() => {navigate('/')}} />
          
            <Box sx={{ flexGrow: 1 }}></Box>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {pages.map((page) => (
                <ListItem key={page.label} disablePadding sx={{ display: 'block' }}>
                    <Tooltip title={page.label} placement="right">
                    <ListItemButton
                        sx={{
                        minHeight: 48,
                        justifyContent: drawerOpen ? 'initial' : 'center',
                        px: 2.5,
                        }}
                        onClick={() => {changePage(page)}}
                    >
                        <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: drawerOpen ? 3 : 'auto',
                            justifyContent: 'center',
                            color: currentPage === page.link ? theme.palette.primary.main : null,
                        }}
                        >
                        {page.icon}
                        </ListItemIcon>
                        <ListItemText primary={page.label} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                    </Tooltip>
                </ListItem>          
                ))}
            </List>
            <Divider />
            <List>
                {actions.map((action) => (
                <ListItem key={action.label} disablePadding sx={{ display: 'block' }}                
                >
                    <Tooltip title={action.label} placement="right" 
                        PopperProps={{
                            sx: {
                            '@media print': {
                                display: 'none',
                            },
                            },
                        }}                    
                    >
                    <ListItemButton                     
                        
                        sx={{
                            minHeight: 48,
                            justifyContent: drawerOpen ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        onClick={() => {handleActionClick(action)}}
                    >
                    <ListItemIcon
                        sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 3 : 'auto',
                        justifyContent: 'center',
                        }}
                    >
                        {action.icon}
                    </ListItemIcon>
                    <ListItemText primary={action.label} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                    </ListItemButton>
                    </Tooltip>
                </ListItem>
                ))}
            </List>
            <Box sx={{ flexGrow: 1 }}></Box>

            <AlertDialog
                open={openAlert}
                onClose={handleAlertClose}
            />
        </Drawer>
        </div>
    );
};
export default SideDrawer;