import Grid from '@mui/material/Unstable_Grid2'; 
import { Divider, Typography } from '@mui/material';
import { SheetRowSole } from '../components/csg/SheetRowSole';
import { useFormData } from '../hooks/FormDataProvider.js';
import { computeValues } from '../helpers/FormComputations.js';

export const FinalFormSole = () => {

    const formData = useFormData();
    const now = new Date();
    const date = now.getMonth()+1 + '/' + now.getDate() + '/' +  now.getFullYear();

    const computedValues = computeValues(formData);

    const partyALabel = "Party A - " + formData.getValue('partyNameA');
    const partyBLabel = "Party B - " + formData.getValue('partyNameB');

    let totalAdjustedSupportPayableLabel = "Payable to ";
    totalAdjustedSupportPayableLabel += (computedValues.totalAdjustedSupportPayableTo === 'a') ? partyALabel : partyBLabel;

    return (
    <Grid container spacing={2}>
        <Grid xs={8}>            
            <Typography variant="h4">Child Support Guidelines Worksheet</Typography>
            <Typography variant="subtitle1">Commonwealth of Virginia Va. Code § 20-108.2</Typography>            
        </Grid>
        <Grid xs={4} sx={{ textAlign: 'right' }}>
            <Typography variant="h6">Case No. {formData.getValue('caseNumber')}</Typography>            
        </Grid>
        <Grid xs={9} smOffset={0}>
            <Typography variant="h6" align="left">{formData.getValue('partyNameA')} v. {formData.getValue('partyNameB')}</Typography>
        </Grid>
        <Grid xs={3} sx={{ textAlign: 'right' }}>
            <Typography variant="h6">Date: {date}</Typography>
        </Grid>

        <Grid xs={3} smOffset={6}>
            <Typography variant="header">{formData.getValue('partyNameA')}</Typography>
        </Grid>
        <Grid xs={3}>
            <Typography variant="header">{formData.getValue('partyNameB')}</Typography>
        </Grid>
        <Grid xs={12} smOffset={0}>
            <Divider />            
        </Grid>

        <SheetRowSole label="1.  Monthly Gross Income"
                format="$"
                valueA={computedValues.grossIncomeA.toFormat()} 
                valueB={computedValues.grossIncomeB.toFormat()}     
                />
        <SheetRowSole label="2.  Adjustments for spousal support payments"
                format="$"
                valueA={computedValues.spousalSupportAmountA.toFormat()} 
                valueB={computedValues.spousalSupportAmountB.toFormat()}    
                />

        <SheetRowSole label="3. Adjustments for support of child(ren)"
                format="$"
                valueA={computedValues.totalOrderedSupportAmountA.toFormat()} 
                valueB={computedValues.totalOrderedSupportAmountB.toFormat()}    
                />       
 
        <SheetRowSole label="4. Deductions from Monthly Gross Income Allowable by law"
                format="$"
                valueA={computedValues.deductionsAllowedA.toFormat()} 
                valueB={computedValues.deductionsAllowedB.toFormat()}     
                />
        
        <SheetRowSole label="5. a. Available Monthly Income"
                format="$"
                valueA={computedValues.calculatedIncomeA.toFormat()} 
                valueB={computedValues.calculatedIncomeB.toFormat()}  
        />
        <Grid xs={6} smOffset={0}>
            <Typography variant="input">&nbsp;&nbsp;&nbsp;&nbsp;b. Combined Monthly Available Income</Typography>
        </Grid>
        <Grid xs={2} smOffset={1.5}>
            <Typography variant="value">{computedValues.totalIncome.toFormat()}</Typography>
        </Grid>
       
        <Grid xs={12} smOffset={0}>
            <Divider />            
        </Grid>

        <Grid xs={6} smOffset={0}>
            <Typography variant="input">6. Number of children for whom support is sought</Typography>
        </Grid>
        <Grid xs={2} smOffset={1.5}>
            <Typography variant="value">{formData.getValue('numCurrentMatterChildren')} </Typography>
        </Grid>

        <Grid xs={6} smOffset={0}>
            <Typography variant="input">7. a. Monthly basic child support obligation</Typography>
        </Grid>
        <Grid xs={2} smOffset={1.5}>
            <Typography variant="value">{computedValues.basicChildSupport.toFormat()}</Typography>
        </Grid>

        <Grid xs={6} smOffset={0}>
            <Typography variant="input">7. b. Monthly amount allowable for health care coverage</Typography>
        </Grid>
        <Grid xs={2} smOffset={1.5}>
            <Typography variant="value">{computedValues.healthcare.toFormat()}</Typography>
        </Grid>

        <Grid xs={6} smOffset={0}>
            <Typography variant="input">7. c. Monthly amount allowable for employment-related child care expenses</Typography>
        </Grid>
        <Grid xs={2} smOffset={1.5}>
            <Typography variant="value">{computedValues.workRelated.toFormat()}</Typography>
        </Grid>        

        <Grid xs={6} smOffset={0}>
            <Typography variant="input">8. Total monthly child support obligation</Typography>
        </Grid>
        <Grid xs={2} smOffset={1.5}>
            <Typography variant="value">{computedValues.sumObligations.toFormat()}</Typography>
        </Grid>        

        <Grid xs={12} smOffset={0}>
            <Divider />            
        </Grid>

        <SheetRowSole label="9. Percent obligation of each party"
                format="%"
                valueA={computedValues.percentIncomeA} 
                valueB={computedValues.percentIncomeB}    
                />

        <SheetRowSole label="10. Monthly child support obligation of each party "
                format="$"
                valueA={computedValues.partyAObligation.toFormat()} 
                valueB={computedValues.partyBObligation.toFormat()}    
                />

        <SheetRowSole label="11. Deduction by non-custodial parent for health care coverage when paid directly by
non-custodial parent or non-custodial parent’s spouse"
                format="$"
                valueA={computedValues.healthcareDeductionA.toFormat()} 
                valueB={computedValues.healthcareDeductionB.toFormat()}    
                />
        <SheetRowSole label="11. Deduction by non-custodial parent for child care expenses when paid directly by
non-custodial parent or non-custodial parent’s spouse"
                format="$"
                valueA={computedValues.childcareDeductionA.toFormat()} 
                valueB={computedValues.childcareDeductionB.toFormat()}    
                />                
        <Grid xs={12} smOffset={0}>
            <Divider />            
        </Grid>
        <Grid xs={12} smOffset={0}>
            <Typography variant="input">12. Adjustments (if any) to Child Support Calculation</Typography>
        </Grid>
 
        {computedValues.adjustments.map((adjustment, index) => 
            <SheetRowSole label={String.fromCharCode(index + 97) + ". " + adjustment.description} format="$"
                valueA={adjustment.party==='a' ? adjustment.amount.toFormat(): "$0.00"}
                valueB={adjustment.party==='b' ? adjustment.amount.toFormat(): "$0.00"}
            />
        )}  
        <SheetRowSole label="13. Each party's adjusted shared"
                format="$"
                valueA={computedValues.adjustedShareA.toFormat()}
                valueB={computedValues.adjustedShareB.toFormat()}    
                />
        <Grid xs={6} smOffset={0}>
            <Typography variant="header">Total Adjusted Support</Typography>
        </Grid>
        <Grid xs={2} smOffset={1.5}>
            <Typography variant="value">{computedValues.totalAdjustedSupport.toFormat()} </Typography>
        </Grid>
        <SheetRowSole label={totalAdjustedSupportPayableLabel} />

    </Grid>
    )
}