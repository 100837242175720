import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { MonthRow } from "./MonthRow";
import { Box, Checkbox, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { convertValue, formatCurrency } from "../../helpers/Utilities";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const YearRow = ({year, months, onPayClick, updateSelectedMonths, collapsed, onExpansionStateToggle}) => {

    const matchesPrint = useMediaQuery("print");
    const [summary, setSummary] = useState({startAmount: convertValue(0), endAmount: convertValue(0), amountOwed: convertValue(0), amountPaid: convertValue(0), interest: convertValue(0)});    
    const [checkState, setCheckState] = useState('unchecked'); //unchecked, checked, indeterminate

    useEffect(() => {
        calculateSummary();
    }, [months]);    

    /**
     * 
     */
    const calculateSummary = () => {
        const startAmount = months[0].startAmount;
        const endAmount = months[months.length - 1].endAmount;
        let amountOwed = convertValue(0);
        let amountPaid = convertValue(0);
        let interest = convertValue(0);
        let monthsChecked = 0;

        months.forEach((month) => {            
            amountOwed = amountOwed.add(month.due);
            amountPaid = amountPaid.add(month.paid);
            interest = interest.add(month.interestDue);
            monthsChecked = month.selected ? monthsChecked + 1 : monthsChecked;
        });

        setSummary({
            startAmount: startAmount,
            endAmount: endAmount,
            amountOwed: amountOwed,
            amountPaid: amountPaid,
            interest: interest
        });

        if (monthsChecked === 0) {
            setCheckState('unchecked');
        }
        else if (monthsChecked === months.length) {
            setCheckState('checked');
        }
        else {
            setCheckState('indeterminate');
        }
    };

    /**
     * If nothing is selected, select all
     * If all are selected, deselect all
     * If some are selected, select all
     */
    const onYearSelect = () => {
        let newCheckState = 'checked';
        if (checkState === 'checked') {
            newCheckState = 'unchecked';
        }
        else if (checkState === 'indeterminate') {
            newCheckState = 'checked';
        }

        let selectedMonths = [];
        // If new state is checked, then select all months
        // If new state is unchecked, then deselect all months
        if (newCheckState === 'checked') {            
            months.forEach((month) => {
                selectedMonths.push(month.key);
            });            
        }
        else {
            selectedMonths = [];
        }
        updateSelectedMonths(year, selectedMonths);
        setCheckState(newCheckState);
    };

    /**
     * 
     * @param {*} key 
     */
    const onMonthSelect = (key) => {
        
        let selectedMonths = [];
        // Loop over the complete list of months
        months.forEach((month) => {
            // If looking at the month matching they key
            if (month.key === key) {
                //  Then toggle the selected state
                if (!month.selected)
                    selectedMonths.push(month.key);                
            }
            // For any other month, just pass it through if it's selected
            else if (month.selected) {
                selectedMonths.push(month.key);
            }
        });

        updateSelectedMonths(year, selectedMonths);
    };

    return (
        <>
            <Grid container sx={{                
                backgroundColor: "#bbb",
                py: 0.5,
                pl: 1,               
                '@media print': {
                   borderBottom: '1px solid black',
                },                    
                }}                   
                alignItems="center"                
            >
                
                <Grid xs={0.5} 
                    sx={{
                    '@media print': {
                        display: 'none',
                    }, 
                    }}                        
                >
                    <Checkbox checked={checkState === 'checked'} 
                        indeterminate={checkState === 'indeterminate'} 
                        onChange={() => onYearSelect()} 
                        sx={{ padding: 0, margin: 0 }} />
                </Grid>
                
                <Grid xs={2}>
                    <Typography sx={{fontWeight: 500}}>{year}</Typography>
                </Grid>
                <Grid xs={1.5}
                   sx={{
                    '@media print': {
                        width: '17%',
                    }, 
                
                }}              
                >
                    <Typography>{formatCurrency(summary.startAmount).toFormat()}</Typography>
                </Grid>
                <Grid xs={1.5}
                    sx={{
                            '@media print': {
                                width: '14%',
                            },                     
                        }}               
                >
                    <Typography>{formatCurrency(summary.interest).toFormat()}</Typography>
                </Grid>
                <Grid xs={1.5}
              sx={{
                    '@media print': {
                        width: '16%',
                    },                 
                }}                  
                >                
                    <Typography>{formatCurrency(summary.amountOwed).toFormat()}</Typography>
                </Grid>
                <Grid xs={1.5}
              sx={{
                    '@media print': {
                        width: '16%',
                    },                 
                }}                 
                >                            
                    <Typography>{summary.amountPaid.toFormat()}</Typography>
                </Grid>
                <Grid xs={2}
              sx={{
                    '@media print': {
                        width: '17%',
                    },                 
                }}                 
                >
                    <Typography>{formatCurrency(summary.endAmount).toFormat()}</Typography>
                </Grid>                
                <Grid xs={1.5}
                    sx={{
                    '@media print': {
                        display: 'none',
                    }, 
                    }}                           
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>                
                        <IconButton onClick={() => onExpansionStateToggle(year)}>
                            {!collapsed ? 
                                <Tooltip title="Collapse Year" placement="left"><ExpandLessIcon /></Tooltip> 
                                : 
                                <Tooltip title="Expand Year" placement="left"><ExpandMoreIcon/></Tooltip>
                            }
                        </IconButton>                
                    </Box>
                </Grid>        
                
            </Grid> 
        {
            !collapsed && 
            months.map((month, index) => <MonthRow key={month.date.format('MMMYYYY')} data={month} index={index} onPayClick={onPayClick} onMonthSelect={onMonthSelect} />)            
        }
        </> 
    )
}