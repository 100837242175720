import { isCurrency, isNumber } from "./Utilities";

class FormItem {
    constructor(name, defaultValue, type, displayName, page, section, helpText="") {
        this.name = name;
        this.type = type;
        this.defaultValue = defaultValue;
        this.value = defaultValue;
        this.#displayName = displayName;
        this.#page = page;
        this.#section = section;
        this.#helpText = helpText;
   
    }

    name = "";
    #val = "";
    defaultValue = ""
    type = null;
    #displayName = null;
    #page = null;
    #section = null;
    #helpText = null;
    #valid = true;
    #errorMessage = "";


    set value(data) {
        this.#val = data;
    }

    get value() {
        return this.#val;
    }

    get valid() {
        return this.#valid;
    }

    get errorMessage() {
        return this.#errorMessage;
    }

    get displayName() {
        return this.#displayName;
    }

    get section() {
        return this.#section;
    }

    get page() {
        return this.#page;
    }

    get updated() {
        return this.#val !== this.defaultValue;
    }

    /**
     * 
     */
    validate() {
        const [valid, message] = FormItem.validateValue(this.type, this.#val);
        this.#valid = valid;
        this.#errorMessage = message;
    }

    /**
     * 
     * @returns 
     */
    static validateValue(type, value) {
        let valid = true;
        let message = "";

        switch(type) {
            case "int":
                valid = isNumber(value);
                message = (!valid) ? "Please enter a valid number" : "";
                break;
            case "days":
                valid = this.isValidDaysInYear(value);
                message = (!valid) ? "Please enter a valid number of days (0-365)" : "";
                break;
            case "currency":
                valid =  isCurrency(value);
                message = (!valid) ? "Please enter a valid dollar amount" : "";
                break;
            case "list":
                // loop through the lists and make sure description is not empty and amount is a currency                
                for( const item of value) {
                    //const descValid = item.description !== "";      
                    const amountValid = typeof item.amount === 'number' || (typeof item.amount === 'string' && isCurrency(item.amount));              
                    valid = valid && amountValid;
                    //message += !descValid ? "Please enter a description for adjustment\n" : "";  
                    message += !amountValid ? "Please enter a valid dollar amount" : "";
                }
                break;
        }

        return [ valid, message ];
    }



    /**
     * 
     * @param {*} value 
     * @returns 
     */
    static isValidDaysInYear(value) {
        // Use a regular expression to check if the value matches the expected format
        const regex = /^(?:\d{1,2}(\.\d)?|1\d{2}(\.\d)?|2[0-9]{2}(\.\d)?|3[0-5]\d(\.\d)?|36[0-5](\.\d)?)$/; // Allows 0-365 with up to one decimal place
        return regex.test(value);
    }

    /**
     * Assume imported value is of the correct type
     * @param {} value 
     */
    importValue(data) {

        let castValue = data;
        if ((this.type === "currency") || (this.type === "days")) {
            castValue = data / 100;
        }
        
        this.#val = castValue;
    }

    /**
     * 
     * @returns 
     */
    exportValue() {
        let castValue = "";
        this.validate();
        if (this.#valid) {
            switch (this.type) {
                case "int":
                    castValue = Number(this.#val);
                    break;
                case "days":
                    castValue = Number(this.#val)*100;
                    break;
                case "currency":
                    castValue = Number(this.#val)*100;
                    break;
                default:
                    castValue = this.#val;
            }   
        }
        else {
            throw new Error("Attempting to export invalid values: " + this.name + " : " + this.#val + " : " + this.#errorMessage);
        }
 
        return castValue;
    }

    reset() {
        this.value = this.defaultValue;
    }

    clone() {
        const clonedItem = new FormItem(this.name, this.defaultValue, this.type, this.#displayName, this.#page, this.#section);
        clonedItem.value = this.value;
        clonedItem.validate();
        return clonedItem;
    }


}

export default FormItem;