import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
    const { onClose, open } = props;
  

  const handleClose = (e) => {
    const action = (e.target.id === 'save-button') ? 'save' : 'discard';
    onClose(action);
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Save before loading a new Case?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You've made changes to the case. If you proceed you will lose those changes. 
            Are you sure you want to delete the data you've entered?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id='discard-button' onClick={handleClose}>Discard Changes</Button>
          <Button variant='contained' id='save-button' onClick={handleClose} autoFocus>
            Keep Changes
          </Button>
        </DialogActions>
      </Dialog>
  );
}
