/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, IconButton, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { YearRow } from "./YearRow";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export const MonthRowView = ({list, onPayClick, setSelectedMonths}) =>
{
    const matchesPrint = useMediaQuery("print");
    const [yearList, setYearList] = useState({});
    const [expandedState, setExpandedState] = useState('expanded'); // expanded, collapsed, inbetween
    const [collapsedYears, setCollapsedYears] = useState([]);
    
    useEffect(() => {
        buildYearList();
    }, [list]);

    // Loop over the months
    // Attempt to insert the month into the year row
    // If the year row doesn't exist, create it
    const buildYearList = () => {
        let years = {};
        list.forEach((month) => {
            let year = month.date.year();
            if(!years[year])
            {
                years[year] = [];
            }
            years[year].push(month);
        });
        setYearList(years);
    }

    /**
     * 
     * @param {*} year 
     * @param {*} months 
     */
    const updateSelectedMonths = (year, months) => {
        let selectedMonths = [];
        // Loop over all the months
        list.forEach((month) => {
            const thisYear = month.date.year();
            // If month is not in the year, just pass it's selected status through            
            if ((thisYear !== parseInt(year)) && (month.selected)) {
                selectedMonths.push(month.key);                
            }
        });

        // Merge the incoming months with the list just built
        setSelectedMonths([...selectedMonths, ...months]);
    }

    /**
     * Called when user clicks the expand/collapse all button
     * 
     */
    const handleExpandAllClick = () => {
        // If the state was collapses, set it to expanded
        // If the state was expanded or inbetween, set it to collapsed
        const newExpandedState = expandedState === 'expanded' ? 'collapsed' : 'expanded';
        setExpandedState(newExpandedState);

        // Now update the list of collapsed years
        let newCollapsedYears = [];
        if (newExpandedState === 'collapsed') {
            newCollapsedYears = Object.keys(yearList);
        }
        else {
            newCollapsedYears = [];            
        }
        setCollapsedYears(newCollapsedYears);

    }

    /**
     * Called when a user clicks the expand/collapse button for an individual year
     * @param {*} year - the year to collapse or expand
     */
    const handleYearExpandClick = (year) => {
        let newCollapsedYears = [...collapsedYears];
        if (collapsedYears.includes(year)) {
            newCollapsedYears = newCollapsedYears.filter((item) => item !== year);
        }
        else {
            newCollapsedYears.push(year);
        }
        // If all the years are collapsed, set the state to collapsed
        if (newCollapsedYears.length === Object.keys(yearList).length) {
            setExpandedState('collapsed');
        }
        // If no years are collapsed, set the state to expanded
        else if (newCollapsedYears.length === 0) {
            setExpandedState('expanded');
        }
        // Otherwise, set the state to inbetween
        else {
            setExpandedState('inbetween');
        }

        setCollapsedYears(newCollapsedYears);
    }

    return(
        <Stack width="100%">
            <Grid container 
                sx={{
                    height: 50,
                    backgroundColor: "#ccc",
                    py: 0.5,
                    pl: 1, 
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,      
                }}
                alignItems="center"
                >
                
                
                <Grid xs={0.5}
                    sx={{
                    '@media print': {
                        display: 'none',
                    },                 
                    }}                  
                ></Grid>
                
                <Grid xs={2}
                 sx={{
                    '@media print': {
                        width: '15%',
                    },                 
                    }}                
                >
                    <Typography sx={{fontWeight: 500}}>Date</Typography>
                </Grid>
                <Grid xs={1.5}
                   sx={{
                    '@media print': {
                        width: '20%',
                    },                 
                    }}
                >
                    <Typography>Starting Amount</Typography>
                </Grid>
                <Grid xs={1.5}
                    sx={{
                        '@media print': {
                            width: '14%',
                        },                     
                    }}                
                >
                    <Typography>Interest Due</Typography>
                </Grid>
                <Grid xs={1.5}
                 sx={{
                    '@media print': {
                        width: '16%',
                    },                 
                }}                  
                >                
                    <Typography>Amount Owed</Typography>
                </Grid>
                <Grid xs={1.5}
              sx={{
                    '@media print': {
                        width: '16%',
                    },                 
                }}                 
                >                            
                    <Typography>Amount Paid</Typography>
                </Grid>
                <Grid xs={2}
              sx={{
                    '@media print': {
                        width: '17%',
                    },                 
                }}                 
                >
                    <Typography>Ending Amount</Typography>
                </Grid>                
                <Grid xs={1.5}
                    css={css`
                            @media print {
                                display: none;
                            }
                            `}                     
                    >
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>                
                        <IconButton onClick={() => handleExpandAllClick()}>
                            {expandedState === 'expanded' ? 
                                <Tooltip title="Collapse All Years" placement="left"><UnfoldLessIcon /></Tooltip> 
                                : 
                                <Tooltip title="Expand All Year" placement="left"><UnfoldMoreIcon/></Tooltip>
                            }
                        </IconButton>                
                    </Box>
                </Grid>                
                
            </Grid>          
        {Object.entries(yearList).map(([year, months]) =>             
            <YearRow key={year} months={months} year={year} onPayClick={onPayClick} 
                updateSelectedMonths={updateSelectedMonths} onExpansionStateToggle={handleYearExpandClick} 
                collapsed={collapsedYears.includes(year)}
                />
            
        )}        
        </Stack>
    );
};