import React from 'react';
import { useNavigate } from 'react-router-dom';


import '../css/landingpage.css';
import senitaLogo from '../assets/senita-logo.svg';
import csg6 from '../assets/csg-6.png';


const LandingPage = () => {
    const navigate = useNavigate();

    return (
  <div className='home-page landing'>
    <div className='top-bar'>
        <div className='logo'>
            <img src={senitaLogo} alt='Senita Legal Solutions' />
            <h2>Senita Legal Solutions</h2>
        </div>

        <button onClick={() => {navigate('/csg/')}}>Start Here</button>
    </div>

    <div className='content'>
        <div className='main-text'>
            <h1>Virginia Support Calculations Made Easier.</h1>
            <p>Free, all in one application to calculate child support, spousal support, and arrearages. </p>

            <button onClick={() => {navigate('/csg/')}}>Start Here</button>
        </div>    
        <div className='main-image'>
            <img src={csg6} alt="Application screenshots" />    
        </div>
    </div>

    <div className='feature-grid'>
        <div className='feature'>
            <h2>Clean & Modern Design</h2>
            <p>Simple design that provides you the information you need, when you need to without getting bogged down unnecessary details.</p>
        </div>
        <div className='feature'>
            <h2>Mobile Friendly</h2>
            <p>Use it on your phone, in the courtroom, or during client meetings.</p>
        </div>   
        <div className='feature'>
            <h2>All Support Types</h2>
            <p>Fully supports Sole, Shared and Split custody calculations.</p>
        </div>
        <div className='feature'>
            <h2>Handles Complex Scenarios</h2>
            <p>Handles children from other relationships, multiple spousal payments and derivative benefits.</p>
        </div>
        <div className='feature'>
            <h2>Arrearages Made Simple</h2>
            <p>Only input the relevant information, and get a clear, easy to read breakdown of payments and amounts owed.</p>
        </div>
        <div className='feature'>
            <h2>Easy to Use</h2>
            <p>Intuitive design that mirrors how you work.</p>
        </div>          
    </div>

    <div className='footer'>
        <div className='left'>
            <h2>Senita Legal Solutions</h2>
            <div className='left-content'>
                <img src={senitaLogo} alt='Senita Legal Solutions' />
                <div className='left-info'>
                    <button onClick={() => {navigate('/csg/')}}>Start Here</button>
                    <p>Questions? Comments?</p>
                    <a href='mailto:phil@senita.legal'>Contact Us</a>
                </div>
            </div>
            <p>&copy; {new Date().getFullYear()} Senita Legal Solutions. All rights reserved.</p>
        </div>
        <div className='disclaimer'>
            Privacy is important to us! We never collect, store, transfer or sell your data or your client’s data.         
            <br/><br/>
            By using this website you agree that the calculations are for informational purposes only. These guidelines should only be used by attorneys who understand Virginia child support laws. Please note that different judges and different jurisdictions may have different numbers and calculations based on the facts of the case. This website was created using the guidelines in Virginia Code 20-108.2 that were current as of July 1, 2023. By using this website you agree to hold Senita and all of its owners, creators, and affiliates harmless for any consequences you may suffer for relying on these guidelines, and additionally, by using this website, you agree to waive any and all causes of action you may have or claim to have against Senita.                
        </div>   
    </div>
  </div>
    );
};

export default LandingPage;