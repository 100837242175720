import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/system';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import PayDialog from '../components/arr/PayDialog';
import { convertValue } from '../helpers/Utilities';
import { OwedControl } from '../components/arr/OwedControl';
import { MonthRowView } from '../components/arr/MonthRowView';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTheme } from '@emotion/react';
import { useFormData, useFormDataDispatch } from '../hooks/FormDataProvider';

export const ArrContainer = () => {
    const now = dayjs();
    const theme = useTheme();
    const interestRate = 0.005;

    const dispatch = useFormDataDispatch();
    const formData = useFormData();


    /**
     * 
     * @param {dayjs object} date
     * @returns String representing the month and year of the date
     */
    const getKey = (date) => date.month() + "-" + date.year();

    const [monthList, setMonthList] = useState([]);
    const [totalAmount, setTotalAmount] = useState(convertValue(0));
    const [openPayDialog, setOpenPayDialog] = useState(false);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [totalOwed, setTotalOwed] = useState(convertValue(0));
    const [totalInterest, setTotalInterest] = useState(convertValue(0));
    const [totalPaid, setTotalPaid] = useState(convertValue(0));
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);

    /**
     * 
     */
    const checkScrollTop = useCallback(() => {
        if (!showScrollTopButton && window.scrollY > 400){
          setShowScrollTopButton(true);
        } else if (showScrollTopButton && window.scrollY <= 400){
          setShowScrollTopButton(false);
        }
    }, [showScrollTopButton]);

    /**
     * Ensures that the month list is updated when owed amounts or paid amounts are updated
     */
    useEffect(() => {
        buildMonthList();
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, [formData.getValue('owedAmounts'), formData.getValue('paidAmounts'), checkScrollTop, selectedMonths]);    


    /**
     * Creates an array of month objects, each containing the start amount, interest, amount owed, amount paid, and end amount
     * Also calculates the total owed, total interest, and total paid
     */
    const buildMonthList = () => {
        const list = [];  
        const owedAmounts = formData.getValue('owedAmounts');      
        let lastDate = owedAmounts[0].startDate; // Assumes the initial start date will always be the first owed amount         
        let startAmount = convertValue(0); // Amount owed/carried over at the start of the month
        let amountOwed = convertValue(0);
        let totalInterestAccumulated = convertValue(0);
        let totalOwedAccumulated = convertValue(0);
        let totalPaidAccumulated = convertValue(0);
        
        while (lastDate.isBefore(now)){

            const key = getKey(lastDate);
            // To figure out owed for this month, we look in owed list.             
            const updatedAmount = owedAmounts.find((item) => getKey(item.startDate) === key);
            // If an entry for this month exists, we update the owed amount
            if (updatedAmount) amountOwed = convertValue(updatedAmount.amount);
            const owedThisMonth = amountOwed.multiply(-1);        
            const paidAmounts = formData.getValue('paidAmounts');
            const paid = paidAmounts[key] ? convertValue(paidAmounts[key]) : convertValue(0);                                    
            const [endAmount, interestDue] = performArrearageCalculation(startAmount, owedThisMonth, paid);            

            totalOwedAccumulated = totalOwedAccumulated.add(owedThisMonth);
            totalInterestAccumulated = totalInterestAccumulated.add(interestDue); // Accumulate the interest in the local variable
            totalPaidAccumulated = totalPaidAccumulated.add(paid);

            list.push({key: key, date: lastDate, startAmount: startAmount, 
                        paid: paid, due: owedThisMonth, endAmount: endAmount, interestDue: interestDue,
                        selected: selectedMonths.includes(key)});             
            lastDate = list[list.length-1].date.add(1, 'month');
            startAmount = endAmount;
        }       
        
        setTotalAmount(startAmount);
        setTotalOwed(totalOwedAccumulated);
        setTotalPaid(totalPaidAccumulated);
        setTotalInterest(totalInterestAccumulated); 
        setMonthList(list);
    };

    /**
     * 
     * @param {dinero object representing the amount coming into the month} startAmount 
     * @param {dinero object representing the amount owed that month} owed 
     * @param {dinero object representing the amount paid that month} paid 
     * @returns dinero obj representing the amount owed at the end of the month, and a dinero obj representing the interest owed for that month
     */
    const performArrearageCalculation = (startAmount, owed, paid) => {
        let endAmount = convertValue(0);

        let interestAmount = startAmount.isNegative() ? startAmount.multiply(interestRate) : convertValue(0);
        if (interestAmount.isZero()) interestAmount = convertValue(0); // Fix for bug in Dinero.js
        const totalOwed = startAmount.add(interestAmount);

        // Apply paid amount to carried over balance first
        endAmount = totalOwed.add(paid).add(owed);;

        return [endAmount, interestAmount];
    };


    /**
     * Displays the pay dialog when user clicks on a individual month's chip
     * If months were selected via checkboxes, ignore them
     * @param {month object containing key, date, etc} data 
     */
    const handleMonthPayClick = (data) => {      
        setSelectedMonths([data.key]);  
        setOpenPayDialog(true); 
    };

    /**
     * Displays the pay dialog when user clicks on the add pay button after selecting multiple months
     */
    const handleBulkPayClick = () => {
        setOpenPayDialog(true);         
    };

    
    /**
     * Invoked when pay dialog is closed
     * If an object is passed in, it is the amount paid
     * If no object is passed in, the dialog was closed without paying
     * Updates the paid amounts for the selected months
     * Unselects any selected months
     * @param {*} paidObj 
     */
    const handlePayDialogClose= (paidObj) => {
        setOpenPayDialog(false);

        if (paidObj) {
            const updatedPaidAmounts = { ...formData.getValue('paidAmounts')};                                           
            // Loop though the list of selected months and update the paid amount for each
            selectedMonths.forEach((month) => {
                updatedPaidAmounts[month] = paidObj.amount;
            });         
            
            dispatch({
                type: 'updated',
                updatedFields: {
                  paidAmounts: updatedPaidAmounts,
                },
            });       
            setSelectedMonths([]);
        }        
    };

    return (
        <>  
            <Grid container spacing={2} xs={12} mt={1} pl={3}>
            
                <Grid xs={12} sm={7}
                     sx={{
                    '@media print': {
                        display: 'none',
                    },                 
                    }}                 
                >
                    <OwedControl /> 
                    <Stack>
                        <Button variant='outlined'
                            sx={{
                                height: 50,
                                maxWidth: 170, 
                                width: "100%",   
                                color: monthList.some((month) => month.selected) ? theme.palette.primary.main : theme.palette.grey[500],                   
                            }}                                            
                            onClick={handleBulkPayClick}
                        >Add Amount Paid</Button>
                    </Stack>                                 
                </Grid>
                                
                <Grid sm={6} 
                    sx={{
                    display: 'none',
                    '@media print': {
                        display: 'grid',
                    },                 
                    }}  
                >
                    <Typography variant='h5'>Arrearage Calculations</Typography>
                    <Typography variant='h6'>Generated: {now.format('MMMM D, YYYY')}</Typography>
                </Grid>
            

                <Grid xs={12} sm={5}>
                    <Stack spacing={0}>
                        {(totalAmount.isNegative() || totalAmount.isZero()) ?
                            <Stack direction="row" spacing={1}>
                                <Typography variant='h5'>Amount Due:</Typography>
                                <Typography variant='h5' color="error">{totalAmount.isNegative() ? totalAmount.multiply(-1).toFormat() : totalAmount.toFormat()}</Typography>
                            </Stack>                            
                            :
                            <Stack direction="row" spacing={1}>
                                <Typography variant='h5'>Overpayment Amount:</Typography>
                                <Typography variant='h5' color="primary">{totalAmount.toFormat()}</Typography>
                            </Stack>
                        }
                        <Typography variant='h6'>
                            Total Owed: {totalOwed.isNegative() ? totalOwed.multiply(-1).toFormat() : totalOwed.toFormat() }
                        </Typography>
                        <Typography variant='h6'>
                            Total Interest: {totalInterest.isNegative() ? totalInterest.multiply(-1).toFormat() : totalInterest.toFormat()}
                        </Typography>
                        <Typography variant='h6'>
                            Total Paid: {totalPaid.toFormat()}
                        </Typography>
                    </Stack>                                
                </Grid>

            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pb: 8, mx: 3 }}> 
                <MonthRowView list={monthList} setSelectedMonths={setSelectedMonths} onPayClick={handleMonthPayClick} />
            </Box> 

            <PayDialog
                open={openPayDialog}
                onClose={handlePayDialogClose}
                months={monthList.filter((month) => month.selected)}
            /> 
            {showScrollTopButton && (
                <IconButton onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                    sx={{
                        position: 'fixed',
                        right: 10,
                        bottom: 15,
                        backgroundColor: theme.palette.primary.light,
                        }}
                >
                    <ArrowUpwardIcon />
                </IconButton>
            )}

        </>
            );
};
