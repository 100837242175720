import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";

const PropTypes = require('prop-types');

const InvalidDisplay = (props) => {
    const { fields } = props;

    return(
    <>
            <Typography variant="h6">Invalid Values</Typography>
            <Typography>Please correct the fields before continuing:</Typography>
            <List>
                {fields.map((field) => {
                    const name = field.section + " : " + field.displayName;
                    return (
                        <>
                        <ListItem key={field.name}>
                            <ListItemAvatar>
                                <Avatar>
                                    <ErrorOutline />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={name} secondary={field.errorMessage} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </>
                    );
                })}
            </List>
        </>
    );
};

InvalidDisplay.propTypes = {
    fields: PropTypes.string.isRequired,
};

export default InvalidDisplay;
