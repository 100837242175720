import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormData } from '../hooks/FormDataProvider';
import { SmartTextField } from '../components/SmartTextField';


export const ObligationForm = () => {

  const formData = useFormData();

  const partyAHeader = formData.getValue('partyNameA') + " : Obligations";
  const partyBHeader = formData.getValue('partyNameB') + " : Obligations";

    return (
      <Grid container spacing={2}>        
        <Grid xs={12} sm={6}>
          
          <Stack spacing={2} m={0}>
            <Typography variant="header">{partyAHeader}</Typography>
            <SmartTextField label="Health care coverage PAID by Party A (if any)" field={formData.getField('healthcareByA')} />            
            <SmartTextField label="Work-related child care of Party A (if any)" field={formData.getField('workRelatedByA')} />           
          </Stack>          
        </Grid>        
        <Grid xs={12} sm={6}>
        
          <Stack spacing={2} m={0}>
            <Typography variant="header">{partyBHeader}</Typography>
            <SmartTextField label="Health care coverage PAID by Party B (if any)" field={formData.getField('healthcareByB')} />            
            <SmartTextField label="Work-related child care of Party B (if any)" field={formData.getField('workRelatedByB')} />
          </Stack>
           
        </Grid>
      </Grid>
    )}