import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Typography } from '@mui/material';

import { SheetRow } from '../components/csg/SheetRow';
import { useFormData } from '../hooks/FormDataProvider';
import { computeValues } from '../helpers/FormComputations.js';


export const FinalFormShared = () => {

    const formData = useFormData();
    const computedValues = computeValues(formData);

    const now = new Date();
    const date = now.getMonth()+1 + '/' + now.getDate() + '/' +  now.getFullYear();

    const partyALabel = "Party A - " + formData.getValue('partyNameA');
    const partyBLabel = "Party B - " + formData.getValue('partyNameB');
    let netPayableLabel = "(32) Payable to ";
    let adjustmentsOwedLabel = "(38) Owed to ";
    let totalAdjustedSupportPayableLabel = "(40) Payable to ";

    netPayableLabel += (computedValues.guidelineAmountPayableTo === 'a') ? partyALabel : partyBLabel;
    adjustmentsOwedLabel += (computedValues.adjustmentsOwedTo === 'a') ? partyALabel : partyBLabel;
    totalAdjustedSupportPayableLabel += (computedValues.totalAdjustedSupportPayableTo === 'a') ? partyALabel : partyBLabel;

    return (
    <Grid container spacing={2}>
        <Grid xs={6}>
            <Typography variant="h5">Child Support Guidelines Worksheet - Shared Custody</Typography>
            <Typography variant="subtitle1">Commonwealth of Virginia Va. Code § 20-108.2</Typography>
        </Grid>
        <Grid xs={6} sx={{ textAlign: 'right' }}>
            <Typography variant="h6">Case No. {formData.getValue('caseNumber')}</Typography>
        </Grid>
        <Grid xs={9} smOffset={0}>
            <Typography variant="h5" align="left">{formData.getValue('partyNameA')} v. {formData.getValue('partyNameB')}</Typography>
        </Grid>
        <Grid xs={3} sx={{ textAlign: 'right' }}>
            <Typography variant="h6">Date: {date}</Typography>
        </Grid>

        <Grid xs={12}>
            <Typography variant="header">I. Guideline Calculations</Typography>
        </Grid>

        <Grid xs={6} smOffset={0}>
            <Typography variant="header">A. Income</Typography>
        </Grid>
        <Grid xs={2}>
            <Typography variant="header">{formData.getValue('partyNameA')}</Typography>
        </Grid>
        <Grid xs={2}>
            <Typography variant="header">{formData.getValue('partyNameB')}</Typography>
        </Grid>
        <Grid xs={2}>
            <Typography variant="header">Combined</Typography>
        </Grid>

        <SheetRow label="Monthly Gross Income"
                numA={1} numB={2} format="$"
                valueA={computedValues.grossIncomeA.toFormat()}
                tooltipA="Manually entered in Income"
                valueB={computedValues.grossIncomeB.toFormat()}
                tooltipB="Manually entered in Income"
                />
        <SheetRow label="Adjustments for spousal support payments"
                numA={3} numB={4}
                valueA={computedValues.spousalSupportAmountA.toFormat()}
                valueB={computedValues.spousalSupportAmountB.toFormat()}
                />

        <SheetRow label="Adjustments for support of child(ren)"
                numA={5} numB={6}
                valueA={computedValues.totalOrderedSupportAmountA.toFormat()} 
                valueB={computedValues.totalOrderedSupportAmountB.toFormat()}
                />

        <SheetRow label="Deductions from Monthly Gross Income Allowable by law"
                numA={7} numB={8}
                valueA={computedValues.deductionsAllowedA.toFormat()}
                valueB={computedValues.deductionsAllowedB.toFormat()}
                />

        <SheetRow label="Available Gross Income"
                numA={9} numB={10} numC={11}
                valueA={computedValues.calculatedIncomeA.toFormat()}
                valueB={computedValues.calculatedIncomeB.toFormat()}
                valueC={computedValues.totalIncome.toFormat()}
                />

       <SheetRow label="Percentage of Combined Gross Income"
                numA={12} numB={13} format="%"
                valueA={computedValues.percentIncomeA}
                valueB={computedValues.percentIncomeB}
                valueC={100}
                />

        <Grid xs={12} smOffset={0}>
            <Typography variant="header">B. Child Support Needs</Typography>
        </Grid>
        <SheetRow label="Number of children for whom support is sought"
                numC={14} format="" 
                valueC={formData.getValue('numCurrentMatterChildren')}
            />

        <SheetRow label="Child support from guideline table"
                numC={15}
                valueC={computedValues.basicChildSupport.toFormat()}
            />

        <SheetRow label="Total shared support"
                numC={16}
                valueC={computedValues.totalSharedSupport.toFormat()}
            />
        <SheetRow label="Total days in year each parent has custody"
                numA={17} numB={18} format=""
                valueA={formData.getValue('numOvernightsA')}
                valueB={formData.getValue('numOvernightsB')}
                valueC={365}
            /> 
        <SheetRow label="Each party’s custody share"
                numA={19} numB={20} format="%"
                valueA={computedValues.percentOvernightsA}
                valueB={computedValues.percentOvernightsB}
                valueC={100}     
        />
        <Grid xs={12} smOffset={0}>
            <Typography variant="header">C. Each Party's Support Obligation to Other Party</Typography>
        </Grid>
        <Grid xs={6}>
            <Typography>1. Party B's obligation to Party A</Typography>
        </Grid>
        <Grid xs={2}>
            <Typography><u>Party A</u></Typography>
        </Grid>
        <Grid xs={4}>
            <Typography><u>Party B</u></Typography>
        </Grid>

        <SheetRow label="Basic support to Party A"
                numB={21}
                valueB={computedValues.basicSupportToA.toFormat()}
            />
        <SheetRow label="Health care coverage PAID by Party A or by Party A’s spouse"
                numB={22} 
                valueB={computedValues.healthcareByA.toFormat()}
            /> 
        <SheetRow label="Work-related child care of Party A"
                numB={23}  
                valueB={computedValues.workRelatedByA.toFormat()}
            />
        <SheetRow label="Total"
                numB={24} 
                valueB={computedValues.sumObligationsToA.toFormat()}
            /> 
         <SheetRow label="Party B's Obligation"
                numB={25} 
                valueB={computedValues.partyBObligation.toFormat()}
            /> 
      <Grid xs={12}>
            <Typography>2. Party A's obligation to Party B</Typography>
        </Grid>
        <SheetRow label="Basic support to Party B"
            numA={26}
            valueA={computedValues.basicSupportToB.toFormat()}
            /> 
        <SheetRow label="Health care coverage PAID by Party B or by Party B’s spouse"
                numA={27} 
                valueA={computedValues.healthcareByB.toFormat()}
            /> 
        <SheetRow label="Work-related child care of Party B"
                numA={28}  
                valueA={computedValues.workRelatedByB.toFormat()}
            />
        <SheetRow label="Total"
                numA={29}  
                valueA={computedValues.sumObligationsToB.toFormat()}
            /> 
         <SheetRow label="Party A's Obligation"
                numA={30} 
                valueA={computedValues.partyAObligation.toFormat()}
            /> 

        <Grid xs={9} smOffset={0}>
            <Typography variant="header">D. Net Monthly Child Support Payable from One Party to the Other</Typography>
        </Grid>
        <SheetRow label="Shared custody child support guideline amount"
                numC={31} 
                valueC={computedValues.guidelineAmount.toFormat()}
            /> 
        <SheetRow label={netPayableLabel} />
            
        <Grid xs={12} smOffset={0}>
            <Divider />            
        </Grid>                                      
        <Grid xs={12} smOffset={0}>
            <Typography variant="header">II. Adjustments (if any) to Shared Custody Child support Guideline Amount</Typography>
        </Grid>
        <Grid xs={6} smOffset={0}>
            <Typography variant="header">A. Adjustment Items</Typography>
        </Grid>
        <Grid xs={2}>
            <Typography variant="header">{formData.getValue('partyNameA')}</Typography>
        </Grid>
        <Grid xs={2}>
            <Typography variant="header">{formData.getValue('partyNameB')}</Typography>
        </Grid>     

        {computedValues.adjustments.map((adjustment, index) =>
            <SheetRow key={adjustment.id} label={index+1 + ". " + adjustment.description} format="$"
                valueA={adjustment.party==='a' ? adjustment.amount.toFormat(): "$0.00"}
                valueB={adjustment.party==='b' ? adjustment.amount.toFormat(): "$0.00"}
            />
        )}        

        <SheetRow label="Total Adjustments"
                numA={35} numB={36} format="$"
                valueA={computedValues.totalAdjustmentsA.toFormat()}                
                valueB={computedValues.totalAdjustmentsB.toFormat()}                
                />           
        <Grid xs={6} smOffset={0}>
            <Typography variant="input">Net Adjustments</Typography>
        </Grid>
        <Grid xs={2} smOffset={1}>
            <Typography variant="value">(37) {computedValues.netAdjustments.toFormat()} </Typography>
        </Grid>
        <SheetRow label={adjustmentsOwedLabel} />        
        <Grid xs={6} smOffset={0}>
            <Typography variant="header">B. Total Adjusted Support</Typography>
        </Grid>
        <Grid xs={2} smOffset={1}>
            <Typography variant="value">(39) {computedValues.totalAdjustedSupport.toFormat()} </Typography>
        </Grid>
        <SheetRow label={totalAdjustedSupportPayableLabel} />

    </Grid>
    );
};