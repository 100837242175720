import { Button, Stack } from "@mui/material";
import { DateAmount } from "./DateAmount";
import { useTheme } from '@emotion/react';
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import { useState } from "react";
import { useFormData, useFormDataDispatch } from "../../hooks/FormDataProvider";


export const OwedControl = (props) => {

    const dispatch = useFormDataDispatch();
    const formData = useFormData();

    //const {owedAmounts, setOwedAmounts} = props;
    const theme = useTheme();
    const minDate = dayjs('2010-01-01');
    const now = dayjs();

    const [editingId, setEditingId] = useState('');


    // When starting to edit a DateAmount
    const handleEditStart = (id) => {
        setEditingId(id);
    };

    // When finishing editing a DateAmount
    const handleEditEnd = () => {
        setEditingId(null);
    };

    /**
     * When a date or amount is changed, this function is called to update the list of owed amounts
     * @param {an object with uuid, date and amount(int)} updatedObject 
     */
    const handleDateAmountChanges = (updatedObject) => {
        const updatedOwedAmounts = formData.getValue('owedAmounts').map((owed) => {
            if (owed.uuid === updatedObject.uuid) {
                return updatedObject;
            }
            return owed;
        });

        dispatch({
            type: 'updated',
            updatedFields: {
              owedAmounts: updatedOwedAmounts,
            },
          });        
    };

    /**
     * 
     * @param {*} uuid 
     */
    const handleDateAmountRemoval = (uuid) => {
        const updatedOwedAmounts = formData.getValue('owedAmounts').filter((owed) => owed.uuid !== uuid);        

        dispatch({
            type: 'updated',
            updatedFields: {
              owedAmounts: updatedOwedAmounts,
            },
          });          
    };

    /**
     * Adds a new date amount to the list of owed amounts
     */
    const addNewDateAmount = () => {
        const uuid = uuidv4();
        const newDateAmount = {uuid: uuid, startDate: now, amount: 0};
        const owedAmounts = formData.getValue('owedAmounts');
        setEditingId(uuid);
        dispatch({
            type: 'updated',
            updatedFields: {
              owedAmounts: [...owedAmounts, newDateAmount],
            },
        });               
    };

    return(
        <Stack spacing={1} mb={1}>            
        {formData.getValue('owedAmounts').map((owed, index) => {
            const previousOwedDate = index > 0 ? formData.getValue('owedAmounts')[index-1].startDate : minDate;
            return(                            
                    <DateAmount key={owed.uuid} owedObj={owed} handleDateAmountChanges={handleDateAmountChanges} 
                        handleDateAmountRemoval={handleDateAmountRemoval}
                        previousOwedDate={previousOwedDate}
                        handleEditStart={handleEditStart}
                        handleEditEnd={handleEditEnd}
                        isEditing={owed.uuid === editingId}                        
                    />                            
            );
        })}
            <Button variant='outlined'
                    sx={{                        
                        height: 50,
                        maxWidth: 170, // Set the maximum width for the text input
                        width: "100%", // Ensure the text input occupies the available width
                        color: theme.palette.grey[500], // Set the normal color to grey
                        '&:hover': {
                            color: theme.palette.primary.main, 
                        },                                        
                    }}
                    onClick={() => {addNewDateAmount()}}
                >Add Amount Owed
            </Button>
        </Stack>
    );
};