import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';

export const ADContainer = () => {
    <Grid container spacing={2} xs={12} mt={2} pl={2}>
        <Grid xs={12} sm={6}>               
                <Typography>In Progress</Typography>                
        </Grid>
    </Grid>;

};
