import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { isCurrency } from "../../helpers/Utilities";

export const DateAmount = (props) => {
    
    const {owedObj, handleDateAmountChanges, handleDateAmountRemoval, previousOwedDate, 
        handleEditStart, handleEditEnd, isEditing} = props;
    
    const theme = useTheme();
    const [amountErrorMessage, setAmountErrorMessage] = useState('');        
    const [localDate, setLocalDate] = useState(owedObj.startDate);
    const [localAmount, setLocalAmount] = useState(owedObj.amount);

    const dateLabel = owedObj.uuid === '0' ? 'First Date' : 'Start Date';
    
    useEffect(() => {
        if (!isEditing) {
            setLocalDate(owedObj.startDate);
            setLocalAmount(owedObj.amount)
        }
    }, [isEditing]);   

    /**
     * 
     * @param {*} newValue 
     */
    const updateStartDate = (newValue) => {
        setLocalDate(newValue);
        handleEditStart(owedObj.uuid);
    }

    /**
     * 
     * @param {*} e 
     */
    const updateAmount = (e) => {        
        handleEditStart(owedObj.uuid);
        const newValue = e.target.value;
        const validAmount = isCurrency(newValue);
        const errorMessage = validAmount ? '' : 'Invalid amount';
        setLocalAmount(newValue);
        setAmountErrorMessage(errorMessage);                    
    }

    /**
     * Changes the state back to view and makes the local values the new values
     */       
    const handleSave = () => {
        handleEditEnd();
        handleDateAmountChanges({...owedObj, startDate: localDate, amount: localAmount});
    }    

    /**
     *  Changes the state back to view and resets the local date and amount to the original values
     */
    const handleCancel = () => {
        handleEditEnd();
        setLocalDate(owedObj.startDate);
        setLocalAmount(owedObj.amount);
    }

    return (
        <Stack spacing={2} pt={1} direction="row">
            <DatePicker 
                label={dateLabel}
                value={localDate} 
                onChange={(newValue) => updateStartDate(newValue)}
                sx={{
                maxWidth: 150, // Set the maximum width for the text input
                width: "100%", // Ensure the text input occupies the available width
                }}
                minDate={previousOwedDate}
            />
            <TextField 
                label="Monthly Amount Owed"
                onFocus={(event) => {
                    event.target.select(); handleEditStart(owedObj.uuid);;           
                }}     
                sx={{
                    maxWidth: 150, // Set the maximum width for the text input
                    width: "100%", // Ensure the text input occupies the available width
                }}   
                value={localAmount}                
                onChange={(e) => updateAmount(e)}
                InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} 
                helperText={amountErrorMessage}
                error={amountErrorMessage.length > 0}
            />
            {isEditing &&
            <Stack direction="row" spacing={1}>
                <Button variant='outlined' 
                    onClick={() => handleSave()}
                    disabled={amountErrorMessage.length > 0}
                    sx={{
                        width: 90,
                    }}
                >
                Save
                </Button>
                {owedObj.uuid !== '0' &&
                <Button variant='outlined' 
                    sx={{
                        color: theme.palette.grey[500], // Set the normal color to grey
                        '&:hover': {
                            color: theme.palette.error.main, // Set the hover color to red
                        },     
                        width: 90,                   
                    }}
                    onClick={() => handleDateAmountRemoval(owedObj.uuid)}                    
                    >
                    Remove
                </Button>
                }
                <Button variant='outlined' onClick={() => handleCancel()}
                    sx={{
                        color: theme.palette.grey[500],
                        width: 90,
                    }}
                >Cancel
                </Button>                
            </Stack>
            }
        </Stack>                          
    )
}