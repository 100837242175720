import React from "react";
import { Stack } from "@mui/material";
import { InfoRadioButton } from "../../components/InfoRadioButton";
import { useFormData, useFormDataDispatch } from "../../hooks/FormDataProvider";
import { CourtOrderedAmountSection } from "./CourtOrderedAmountSection";
import CourtOrderedBothSection from "./CourtOrderedBothSection";

export const CourtOrderedSection = ({ party }) => {
    const optionsList = [
        { value: "no", label: "No" },
        { value: "yes", label: "Yes" },
    ];

    const formData = useFormData();
    const dispatch = useFormDataDispatch();

    const radioValue = party === 'A' ? formData.getValue('courtOrderedSupportA') : formData.getValue('courtOrderedSupportB');
    const radioName = "courtOrderedSupport" + party;
    const displayBothOption = party === 'A' ? Number(formData.getValue('otherKidsA')) > 1 : Number(formData.getValue('otherKidsB')) > 1;
    const displayAmountSection = party === 'A' ? formData.getValue('courtOrderedSupportA') !== 'no' : formData.getValue('courtOrderedSupportB') !== 'no';
    const displayBothSections = party === 'A' ? formData.getValue('courtOrderedSupportA') === 'both' : formData.getValue('courtOrderedSupportB') === 'both';

    if (displayBothOption) {
        optionsList.push({ value: "both", label: "Yes and No" });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFields = {};

        if (value === 'no') {
            updatedFields['orderedSupportAmount' + party] = 0;
        }

        dispatch({
            type: "updated",
            updatedFields: {
                ...updatedFields,
                [name]: value,
            },
        });
    };

    return (
      <>
        <InfoRadioButton name={radioName} 
          label="Court Ordered Child Support"
          value={radioValue}
          onChange={handleChange}
          options={optionsList}
        />

        <Stack spacing={2} m={0}>
          {displayAmountSection && <CourtOrderedAmountSection party={party} />}
          {displayBothSections && <CourtOrderedBothSection  party={party} />}
        </Stack>  
      </>
    )
  };