import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { convertValue, yesnoOptions } from '../helpers/Utilities';
import { SmartTextField } from '../components/SmartTextField';
import { useFormData, useFormDataDispatch } from '../hooks/FormDataProvider';
import { InfoRadioButton } from '../components/InfoRadioButton';

export const SSContainer = () => {

    const dispatch = useFormDataDispatch();
    const formData = useFormData();

    const [amount, setAmount] = useState(convertValue(0));
    
    useEffect(() => {
        calculateAmountOwed();
    }, [formData.getValue('sharedChildren'), formData.getField('payeesIncome')]);

    const handleChange = (e) => {

        const { name, value } = e.target;
        
        dispatch({
            type: 'updated',
            updatedFields: {
              [name]: value,
            },
          });                        
    };

    /**
     * 
     */
    const calculateAmountOwed = () => {

        const invalidFields = formData.validate();
        if (invalidFields.length > 0) {
            setAmount(convertValue(0));
            return;
        }

        let payorSpousePercent = 0;
        let payeePercent = 0;
        if (formData.getValue('sharedChildren') === 'yes') {
            payorSpousePercent = 0.26;
            payeePercent = 0.58;
        }
        else {
            payorSpousePercent = 0.27;
            payeePercent = 0.5;
        }

        const diff = convertValue((formData.getValue('payorSpouseIncome') * payorSpousePercent) - 
                                    (formData.getValue('payeesIncome') * payeePercent));

        setAmount(diff);
    };

    return (
        <Grid container spacing={2} xs={12} mt={2}>            

            <Grid xs={12} sm={8}>
           
                <Stack spacing={2} ml={2}>
                    <Typography variant='h5'
                        sx={{
                            display: 'none',
                            '@media print': {
                                    display: 'flex',
                                },  
                        }}>
                        Spousal Support Calculations
                    </Typography>                 
                    <SmartTextField label="Payor Spouse's Income" 
                        field={formData.getField('payorSpouseIncome')}                         
                    />
                    <SmartTextField label="Payee's Income" field={formData.getField('payeesIncome')} />
                    <InfoRadioButton
                        label="Shared Minor Children" 
                        name="sharedChildren"
                        value={formData.getValue('sharedChildren')}
                        onChange={handleChange}
                        options={yesnoOptions}                    
                    />                    

                    <Typography variant='h5'>Spousal Support Amount: {amount.toFormat()}</Typography>                
                </Stack>
            </Grid>
        </Grid>
    );
};
