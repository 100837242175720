import dayjs from 'dayjs';

const initialFieldList = [
   {
    name: 'caseNumber',
    type: 'string',
    page: 'ChildSupportGuidelines',
   },
   { 
    name: 'partyNameA',
    value: "Party A",
    type: 'string',
    page: 'ChildSupportGuidelines',
   },
   { 
    name: 'partyNameB',
    value: "Party B", 
    type: "string",
    page: 'ChildSupportGuidelines',
   },
   {
    name: 'numCurrentMatterChildren',
    value: 1,
    type: "int",
    page: 'ChildSupportGuidelines',
   },
   {
    name: 'custodyType',
    value: 'shared', // shared/sole
    type: "string",
    page: 'ChildSupportGuidelines',
   },
   {
    name: 'custodialParty',
    value: 'a',
    type: "string",
    page: 'ChildSupportGuidelines',
   },
   {
    name: 'numOvernightsA',
    displayName: "Number of Overnights for Client",
    page: 'ChildSupportGuidelines',
    section: "Start",
    value: 120, 
    type: "days",
   },
   {
    name: 'numOvernightsB',
    displayName: "Number of Overnights for Opposing Party",
    page: 'ChildSupportGuidelines',
    section: "Start",    
    value: 245, 
    type: "days",
   },
   {
    name: 'numKidsA',
    displayName: "Number of Children Residing with Client ",
    page: 'ChildSupportGuidelines',
    section: "Start",    
    value: 0, 
    type: "int",
   },
   {
    name: 'numKidsB',
    displayName: "Number of Children Residing with Opposing Party ",
    page: 'ChildSupportGuidelines',
    section: "Start",    
    value: 0, 
    type: "int",
   },
   {
    name: 'grossIncomeA',
    displayName: "Client Gross Monthly Income",
    page: 'ChildSupportGuidelines',
    section: "Income",
    value: 200,
    type: "currency",
   },
   {
    name: 'businessExpensesA',
    displayName: "Client Reasonable Business Expenses",
    page: 'ChildSupportGuidelines',
    section: "Income",    
    value: 0,
    type: "currency",
   },
   {
    name: 'grossIncomeB',
    displayName: "Opposing Party Gross Monthly Income",
    page: 'ChildSupportGuidelines',
    section: "Income",    
    value: 200, 
    type: "currency",
   },
   {
    name: 'businessExpensesB',
    displayName: "Opposing Party Reasonable Business Expenses",
    page: 'ChildSupportGuidelines',
    section: "Income",     
    value: 0,
    type: "currency",
   },
   {
    name: 'spousalSupportA',
    page: 'ChildSupportGuidelines',
    value: 'no', //no,yes-b,yes-others,yes-b+others
    type: "string",
   },
   {
    name: 'spousalSupportPaymentAtoB',
    displayName: "Spousal Support Payments to Opposing Party",
    page: 'ChildSupportGuidelines',
    section: "Spousal Support", 
    value: 0, // Currency  
    type: "currency",  
   },
   {
    name: 'spousalSupportPaymentAtoOthers',
    displayName: "Spousal Support Payments to Other Parties",
    page: 'ChildSupportGuidelines',
    section: "Spousal Support", 
    value: 0, // Currency    
    type: "currency",
   },
   {
    name: 'spousalSupportB',
    page: 'ChildSupportGuidelines',
    value: 'no', //no,yes-a,yes-others,yes-a+others
    type: "string",
   },
   {
    name: 'spousalSupportPaymentBtoA',
    displayName: "Spousal Support Payments to Client",
    section: "Spousal Support", 
    value: 0, // Currency    
    type: "currency",
   },
   {
    name: 'spousalSupportPaymentBtoOthers',
    displayName: "Spousal Support Payments to Others",
    page: 'ChildSupportGuidelines',
    section: "Spousal Support", 
    value: 0, // Currency        
    type: "currency",
   },
   {
    name: 'ssDisability',
    page: 'ChildSupportGuidelines',
    value: 'no', // yes/no
    type: "string",
   },
   {
    name: 'disabledParty',
    page: 'ChildSupportGuidelines',
    value: 'a', // party
    type: "string",
   },
   {
    name: 'derivativeBenefitParty',
    page: 'ChildSupportGuidelines',
    value: 'a',
    type: "string",
   },
   {
    name: 'derivativeBenefit',
    displayName: "Amount of Derivative Benefit",
    page: 'ChildSupportGuidelines',
    section: "Income",
    value: 0, // Currency
    type: "currency",
   },
   {
    name: 'otherKidsA',
    page: 'ChildSupportGuidelines',
    value: 0, // int
    type: "int",
   },
   {
    name: 'courtOrderedSupportA',
    page: 'ChildSupportGuidelines',
    value: 'no', // yes/no/both
    type: "string",
   },
   {
    name: 'orderedSupportAmountA',
    displayName: "Court Ordered Support Amount",
    page: 'ChildSupportGuidelines',
    section: "Other Children", 
    value: 0, // Currency
    type: "currency",
   },
   {
    name: 'kidsNotSubjectA',
    page: 'ChildSupportGuidelines',
    value: 1, // int
    type: "int",
   },
   {
    name: 'healthcareByA',
    displayName: "Healthcare Coverage PAID by Client",
    page: 'ChildSupportGuidelines',
    section: "Obligations", 
    value: 0, // Currency
    type: "currency",
   },
   {
    name: 'workRelatedByA',
    displayName: "Work-related child care of Client",
    page: 'ChildSupportGuidelines',
    section: "Obligations", 
    value: 0, // Currency
    type: "currency",
   },
   {
    name: 'otherKidsB',
    page: 'ChildSupportGuidelines',
    value: 0, // int
    type: "int",
   },
   {
    name: 'courtOrderedSupportB',
    page: 'ChildSupportGuidelines',
    value: 'no', // yes/no/both
    type: "string",
   },
   {
    name: 'orderedSupportAmountB',
    displayName: "Court Ordered Support Amount",
    page: 'ChildSupportGuidelines',
    section: "Other Children", 
    value: 0, // Currency
    type: "currency",
   },
   {
    name: 'kidsNotSubjectB',
    page: 'ChildSupportGuidelines',
    value: 1, // int
    type: "int",
   },
    {
    name: 'healthcareByB',
    displayName: "Healthcare Coverage PAID by Opposing Party",
    page: 'ChildSupportGuidelines',
    section: "Obligations", 
    value: 0, // Currency
    type: "currency",
    },
    {
    name: 'workRelatedByB',
    displayName: "Work-related child care of Opposing Party",
    page: 'ChildSupportGuidelines',
    section: "Obligations", 
    value: 0, // Currency
    type: "currency",
    },
    {
    name: 'adjustments',
    displayName: "Adjustment Item",
    page: 'ChildSupportGuidelines',
    section: "Adjustments",
    value: [],
    type: "list",
    },
    {
      name: 'payorSpouseIncome',
      displayName: "Payor Spouse Income",
      page: "SpousalSupport",
      section: "Spousal Support",
      value: 3000,
      type: "currency",
     },
     {
      name: 'payeesIncome',
      displayName: "Payee's Income",
      page: "SpousalSupport",
      section: "Spousal Support",
      value: 1000,
      type: "currency",
     },     
     {
      name: 'sharedChildren',
      displayName: "Shared Minor Children",
      page: "SpousalSupport",
      section: "Spousal Support",
      value: 'no',
     }, 
     {
      name: 'owedAmounts',
      displayName: "Owed Amounts",
      page: "ArrearageCalculations",
      section: "Arrearage Calculations",
      value: [{uuid: '0', startDate: dayjs(), amount: 0}],
      type: "list",
     },
     {
      name: 'paidAmounts',
      displayName: "Paid Amounts",
      page: "ArrearageCalculations",
      section: "Arrearage Calculations",
      value: {},
      type: "keymap",
     },
  ];



export default initialFieldList;