import Grid from '@mui/material/Unstable_Grid2'; 
import { Typography } from '@mui/material';

import { useFormData } from '../hooks/FormDataProvider';
import { computeValues } from '../helpers/FormComputations.js';


export const FinalFormMobile = () => {

    const formData = useFormData();    
    const computedValues = computeValues(formData);
/*
  

    const partyALabel = "Party A - " + formData.getValue('partyNameA');
    const partyBLabel = "Party B - " + formData.getValue('partyNameB');

    let splitAdjustedSupportPayable = convertValue(0);
    // This would normally be done in FormComputations, but due to odd nature of split custody, it's done here. Logic is
    // replicated in Mobile
  
*/
    const partyALabel = formData.getValue('partyNameA');
    const partyBLabel = formData.getValue('partyNameB');  
    let custodyLabel = "";
    let totalAdjustedSupportPayableLabel = "";
    let totalAdjustedSupport = 0;

    if (formData.getValue('custodyType') === 'split') {
        custodyLabel = "Split Custody";
        const formDataA = formData.clone();
        formDataA.setValue('custodyType', 'sole');
        formDataA.setValue('custodialParty', 'a');
        formDataA.setValue('numCurrentMatterChildren', formData.getValue('numKidsA'));    
        const formDataB = formData.clone();
        formDataB.setValue('custodyType', 'sole');
        formDataB.setValue('custodialParty', 'b');
        formDataB.setValue('numCurrentMatterChildren', formData.getValue('numKidsB'));
        // Not needed, but easier than deleting and updating form below    
        
        const computedValuesA = computeValues(formDataA);
        const computedValuesB = computeValues(formDataB);

        if (computedValuesA.totalAdjustedSupport.greaterThan(computedValuesB.totalAdjustedSupport)) {
            totalAdjustedSupportPayableLabel += partyALabel;
            totalAdjustedSupport = computedValuesA.totalAdjustedSupport.subtract(computedValuesB.totalAdjustedSupport);        
        }
        else {
            totalAdjustedSupportPayableLabel += partyBLabel;
            totalAdjustedSupport = computedValuesB.totalAdjustedSupport.subtract(computedValuesA.totalAdjustedSupport);        
        }          
    }
    else {
        custodyLabel = (formData.getValue('custodyType') === 'shared') ? "Shared Custody" : "Sole Custody";
        totalAdjustedSupportPayableLabel = (computedValues.totalAdjustedSupportPayableTo === 'a') ? partyALabel : partyBLabel;
        totalAdjustedSupport = computedValues.totalAdjustedSupport;
    } 
    
    

    return(         
        <Grid container spacing={2}>
            <Grid xs={12} smOffset={0}>
                <Typography variant="h5" align="left">{formData.getValue('partyNameA')} v. {formData.getValue('partyNameB')}</Typography>
            </Grid>
            <Grid xs={12} smOffset={0}>
            <Typography variant="h6">{custodyLabel}</Typography>
            </Grid>
            <Grid xs={7}>
                Combined Gross Income 
            </Grid>
            <Grid xs={5}>
                {computedValues.totalIncome.toFormat()}  
            </Grid>
    
            {formData.getValue('custodyType') !== 'split' &&
                <>
                    <Grid xs={7}>
                        Total Monthly Support Obligation 
                    </Grid>
                    <Grid xs={5}>
                        {formData.getValue('custodyType') === 'shared' ?
                        computedValues.totalSharedSupport.toFormat() : computedValues.sumObligations.toFormat()}
                    </Grid>
               </>
            }

            <Grid xs={7}>
                <Typography variant="h6">Total Adjusted Support</Typography>
            </Grid>
            <Grid xs={5}>
                <Typography variant="h6">{totalAdjustedSupport.toFormat()}</Typography>
            </Grid>
            <Grid xs={7}>
               Payable to
            </Grid>
            <Grid xs={5}>
                {totalAdjustedSupportPayableLabel}
            </Grid>            
        </Grid>
    );
}