import FormItem from "./FormItem.js"


export class Form {

    static fieldData;
    #caseUUID = null;
    #createdTime = null;
    #updatedTime = 0;
    #savedTime = null;
    #fields = []
    /**
     * Constructs the form object
     * If values are passed, they are used to create form. Otherwise, default values are used.
     * @param {string} caseUUID 
     */
    constructor(caseUUID, createdTime, updatedTime, savedTime, fields) {
        // Populate the object with meta values
        Form.fieldData.forEach((value) => {       
            const displayName = (value.displayName) ? value.displayName : "";
            const section = (value.section) ? value.section : "";     
            const field = new FormItem(value.name, value.value, value.type, displayName, value.page, section);
            this.#fields.push(field);
        });        
        
        this.#createdTime = (createdTime) ? createdTime : Date.now();
        this.#caseUUID = caseUUID;                
        this.#updatedTime = (updatedTime) ? updatedTime : 0;        
        this.#savedTime = savedTime;
        
        if (fields) {
            this.#fields = fields.map((item) => item.clone());                            
        }
    }

    static setMetaFieldData(fieldList) {
        Form.fieldData = fieldList;
    }

    get createdTime() {
        return this.#createdTime;
    }

    get updatedTime() {
        return this.#updatedTime;
    }
    
    get savedTime() {
        return this.#savedTime;
    }

    /**
     * 
     * @param {*} name 
     * @param {*} value 
     */
    setValue(name, value) {            
        const field = this.#fields.find((field) => field.name === name);
       
        if (field) {
            field.value = value;
        }
        else {
            throw new Error("Field does not exist: " + name);
        }

        this.#updatedTime = Date.now();        
    }

    /**
     * Returns the value for the given field
     * @param {*} name - Field name
     */
    getValue(name) {
        const foundItem = this.#fields.find((field) => field.name === name);
        if (!foundItem) {
            throw new Error("Requested non-existent field: " + name);
        }

        return foundItem ? foundItem.value : undefined;
    }

    clearValues(page) {
        this.#fields.forEach((field) => {
            if (field.page === page) {
                field.reset();
            }
        });
    }

    /**
     * 
     * @param {*} name 
     * @returns 
     */
    getField(name) {
        const foundItem = this.#fields.find((field) => field.name === name);
        if (!foundItem) {
            throw new Error("Requested non-existent field: " + name);
        }

        return foundItem;     
    }

    /**
     * 
     * @param {*} name 
     * @returns 
     */
    getHelpText(name) {
        return "";
    }

    /**
     * 
     * @returns deep copy of form
     */
    clone() {        
        let clonedForm = new Form(this.#caseUUID, this.#createdTime, this.#updatedTime, this.#savedTime, this.#fields);
        return clonedForm;
    }

    /**
     * 
     * @returns Returns true if there have been changes
     */
    isUpdated(page) {
        // Loop through all fields and check if any have been updated
        let updated = false;
        this.#fields.forEach((field) => {
            if (field.updated && field.page === page) {
                updated = true;
            }
        });

        return updated;
    }

    /**
     * 
     * @returns an array of invalid FormItem objects
     */
    validate() {
        let invalidFields = [];
        this.#fields.forEach((field) => {
            field.validate();
            if (!field.valid) {
                invalidFields.push(field);
            }            
        });
        return invalidFields;
    }

    /**
     * 
     * @returns 
     */
    getFieldsObject() {
        const fieldsObj = {};

        this.#fields.forEach((field) => {
            fieldsObj[field.name] = field.exportValue();
        });

        return fieldsObj;
    }

    /**
     * 
     * @param {*} fields 
     */
    setFieldsObject(fields) {
        for (const key in fields) {
            const field = this.#fields.find((field) => field.name === key);
       
            if (field) {
                field.importValue(fields[key]);
            }
            else {
                throw new Error("Field does not exist");
            }            
        }
    }

    /**
     *  Package up all the name/field pairs into a json object to be sent to the server
     *  @returns
     */
    exportValues() {
        return {
            caseUUID: this.#caseUUID,
            createdTime: this.#createdTime,
            updatedTime: this.#updatedTime,
            savedTime: this.#savedTime,
            fields: this.getFieldsObject(),
        };
    }

    /**
     * 
     * @param {*} formData 
     */
    importValues(formData) {
        const { caseUUID, createdTime, updatedTime, savedTime } = formData;
        this.#caseUUID = caseUUID;
        this.#createdTime = createdTime;
        this.#updatedTime = updatedTime;
        this.#savedTime = savedTime;

        this.setFieldsObject(formData.fields);
    }

    /**
     * 
     * @param {*} uuid 
     * @param {*} savedTime 
     */
    formSaved(caseUUID, savedTime) {
        this.#caseUUID = caseUUID;
        this.#savedTime = savedTime;
    }

  
}