import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { Box, Chip, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { isCurrency } from '../../helpers/Utilities';

export default function PayDialog(props) {
    const { onClose, open, months } = props;
  
    const [amountPaid, setAmountPaid] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [dialogOpened, setDialogOpened] = useState(false);

    /**
     * 
     */
    useEffect(() => {
        populateAmount();
        if (open && !dialogOpened) {
            setDialogOpened(true);
        }
    }, [months, open, dialogOpened]);   



  /**
   * 
   * @param {*} e 
   */
  const handleClose = (e) => {
    const value = (e.target.id === 'save-button') ? {amount: amountPaid} : null;
    onClose(value);
  };

  /**
   * 
   * @param {*} e 
   */
  const handleAmountChange = (e) => {
    const value = e.target.value;

    const validAmount = isCurrency(value);
    const errorMessage = validAmount ? '' : 'Invalid amount';

    setErrorMessage(errorMessage);     
    setAmountPaid(value);
  };

  /**
   * 
   */
  const populateAmount = () => {
    
    // If a single month is selected, populate the amount paid with the amount paid for that month
    if (months && months.length === 1) {
      setAmountPaid(months[0].paid.getAmount()/100);
    }
    // If multiple months are selected, use 0
    else {
      setAmountPaid(0);
    }
    
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="pay-dialog-title"
        aria-describedby="pay-dialog-description"
      >
        <DialogTitle id="pay-dialog-title">
          {"Input Amount Paid"}
        </DialogTitle>
        <form
                onSubmit={(event) => {
                    event.preventDefault();
                    // Trigger the save button click event
                    document.getElementById('save-button').click();
                }}
              >  
            <Stack spacing={1} px={3}>
            <Box
              sx={{
                border: '1px solid lightgrey',
                borderRadius: 1,
                position: 'relative',
                padding: 2,
              }}
            >
              <Typography
                color='rgba(0, 0, 0, 0.8)'
                fontSize='13px'
                fontWeight='100'
                component="div"
                sx={{
                  position: 'absolute',
                  top: -10,
                  left: 10,
                  backgroundColor: '#fff',
                  padding: '0 5px',
                  
                }}
              >
                Date
              </Typography>
              {
                (months.length) > 0 ? 
                months.map((month) => <Chip label={month.date.format('MMM YYYY')} />)                                      
                :
                <Typography>Please select at least one month</Typography>
              }
            </Box>                

                <TextField 
                    label="Amount Paid"
                    value={amountPaid}
                    onFocus={(event) => {
                      event.target.select();                  
                    }}      
                    onChange={(e) => handleAmountChange(e)}
                    sx={{
                        mt: 1,              
                    }}
                    helperText={errorMessage}
                    error={errorMessage.length > 0}
                    InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} 
                    />
                {months.length === 1 &&
                  <Chip icon={<AddIcon />} 
                      label="Amount Owed"
                      clickable                   
                      onClick={() => {setAmountPaid(months[0].due.multiply(-1).getAmount()/100)}}
                  />
                }
            </Stack>
          </form>
        <DialogActions>
          <Button id='discard-button' onClick={handleClose}>Cancel</Button>
          <Button id='save-button' onClick={handleClose} disabled={errorMessage.length > 0} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  );
}