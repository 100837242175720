import { React, useCallback } from "react";
import { InfoSelector } from "../../components/InfoSelector";
import { useFormData, useFormDataDispatch } from "../../hooks/FormDataProvider";

const CourtOrderedBothSection = ({ party }) => {
    const formData = useFormData();
    const dispatch = useFormDataDispatch();

    const handleChange = useCallback(
      (e) => {
        dispatch({
          type: "updated",
          updatedFields: {
            [e.target.name]: e.target.value,
          },
        });
      },
      [dispatch],
    );

    const name = "kidsNotSubject" + party;
    const value = party === 'A' ? formData.getValue('kidsNotSubjectA') : formData.getValue('kidsNotSubjectB');

    const numKids = [
        { value: 1, label: "One" },
        { value: 2, label: "Two" },
        { value: 3, label: "Three" },
        { value: 4, label: "Four" },
      ];

    return (
      <InfoSelector
        name={name}
        value={value}
        onChange={handleChange}
        label="Number of Children not subject to Order"
        options={numKids}
        />
    );
  };

export default CourtOrderedBothSection;
